/**
 * @flow
 *
 * @format
 */
import React from 'react';
import Moment from 'moment';

import { connect } from 'react-redux';
import { InputNumber, InputString, InputDate, InputSelect, Loader, withConfirm } from 'src/pages/components';
import type { withConfirmProps } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import VisibilitySensor from 'react-visibility-sensor';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import type { Scenario, ObjectMap } from 'src/data';
import type { Code, CodeConfiguration } from 'src/services/Firebase/FirebaseHelper/Admin';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';

import { TabContent } from '../components';

type Props = withConfirmProps & {
  firebase: Firebase,
  generateCode: FirebaseHelper.generateCodeType,
  generateCodeConfiguration: FirebaseHelper.generateCodeConfigurationType,
  sendSecretCodesLabels: FirebaseHelper.sendSecretCodesLabelsType,
  addNotif: EventsServiceHelper.addNotifType,
  t: (string) => string,
};

type State = {
  codes: Code[],
  configurations: CodeConfiguration[],
  selectedConfiguration: string,
  selectedConfigurationIndex: string,
  selectedConfigurationRecipients: string[],
  scenarios: ObjectMap<Scenario>,
  loaded: boolean,

  template: string,
  scenarioId?: string,
  count: string,
  codeCount: string,
  info?: string,
  startDate?: Date,
  endDate?: Date,

  isLoading: boolean,
  isValid: boolean,
  codeType: string,
  codeTypes: ObjectMap<string>,
};

class CodesTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    loaded: false,
    codes: [],
    configurations: [],
    template: '******',
    selectedConfiguration: undefined,
    selectedConfigurationIndex: undefined,
    selectedConfigurationRecipients: [],
    scenarios: {},
    isLoading: false,
    isValid: false,
    scenarioId: undefined,
    count: '1',
    codeCount: '1',
    info: '',
    startDate: new Date(),
    endDate: undefined,
    codeTypes: {},
    codeType: '',
  };

  onVisibilityChanged = (visible: boolean) => {
    if (visible && !this.state.loaded) {
      this._reloadConfigurationsAsync().then(() => {
        this._reloadCodesTypes().then(() => {
          this._reloadCodes().then(() => {
            this._reloadScenariosAsync();
          });
        });
      });
    }
  };

  _reloadCodesTypes = async () => {
    this.setState({ isLoading: true });
    try {
      const snapshot = await this.props.firebase.codeTypes().once('value');
      if (snapshot.exists()) {
        const val = snapshot.val();
        this.setState({ isLoading: false, codeTypes: val });
      }
    } catch (error) {
      console.warn('Cannot retrieve code types', error);
      this.setState({ isLoading: false });
    }
  };

  _reloadCodes = async () => {
    this.setState({ isLoading: true });
    try {
      const codesSnapshot = await this.props.firebase
        .codes()
        .orderByChild('startDate')
        .limitToLast(100)
        .once('value');

      if (codesSnapshot.exists()) {
        const codesObj = codesSnapshot.val();
        const codes = Object.keys(codesObj).map((id) => ({
          id,
          ...codesObj[id],
        }));
        this.setState({
          isLoading: false,
          codes: codes.sort((a, b) => b.startDate - a.startDate),
        });
      }
    } catch (error) {
      console.warn('Cannot retrieve codes', error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  _createCode = async () => {
    const { sendSecretCodesLabels, t } = this.props;

    const {
      scenarioId,
      count,
      startDate,
      endDate,
      info,
      template,
      selectedConfiguration,
      codeCount,
      codeType,
    } = this.state;
    const { generateCode } = this.props;
    this.setState({ isLoading: true });
    if (scenarioId) {
      try {
        const codes = await generateCode(
          scenarioId,
          template,
          startDate,
          endDate,
          parseInt(count, 10),
          parseInt(codeCount, 10),
          info,
          codeType,
          selectedConfiguration,
        );
        if (this.state.selectedConfigurationRecipients.length) {
          const codesToSend = codes.map((it) => ({ id: it, endDate: endDate && endDate.getTime() }));
          this.props.alert(
            `${t([
              'screens.admin.codes.confirmSendCodesViaEmail',
              '',
            ])} ${this.state.selectedConfigurationRecipients.join(', ')}`,
            [
              {
                text: t('general.confirm'),
                onPress: () => {
                  sendSecretCodesLabels(
                    codesToSend,
                    this.state.selectedConfigurationIndex,
                    this.state.selectedConfigurationRecipients,
                  );
                },
              },
              { text: t('general.cancel'), onPress: () => {} },
            ],
          );
        }
        this.setState({
          scenarioId: undefined,
          template: '******',
          selectedConfiguration: '',
          isLoading: false,
          count: '0',
          info: '',
          startDate: new Date(),
          endDate: undefined,
        });

        await this._reloadCodes();
      } catch (error) {
        this.setState({ isLoading: false });
        this.props.addNotif(NotificationTypes.ERROR, 'E_CODE_NOT_GENERATED', error.message, 0);
      }
    }
  };

  _createCodeConfiguration = async () => {
    const { scenarioId, count, startDate, endDate, info, codeCount, template, codeType } = this.state;
    const { generateCodeConfiguration } = this.props;
    this.setState({ isLoading: true });
    if (scenarioId) {
      await generateCodeConfiguration(
        template,
        scenarioId,
        startDate,
        endDate,
        parseInt(count, 10),
        parseInt(codeCount, 10),
        info,
        codeType,
      );
    }
  };

  _reloadScenariosAsync = async () => {
    this.setState({ isLoading: true });
    try {
      const scenarios = await FirebaseHelper.getAllScenariosAsync();

      this.setState({ scenarios, isLoading: false });
    } catch (error) {
      console.warn('Cannot download all scenarios', error);
      this.setState({ isLoading: false });
    }
  };

  _reloadConfigurationsAsync = async () => {
    this.setState({ isLoading: true });
    try {
      const configurations = await FirebaseHelper.reloadCodeConfigurations();
      this.setState({ configurations, isLoading: false, loaded: true });
    } catch (error) {
      console.warn('Cannot download all configurations', error);
      this.setState({ isLoading: false });
    }
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    if (fieldName === 'selectedConfiguration' && value && value.length) {
      const selectedConfig = this.state.configurations.find((it) => it.id === value);
      if (selectedConfig) {
        let startDate;
        if (selectedConfig.startDate && typeof selectedConfig.startDate === 'number') {
          startDate = new Date(selectedConfig.startDate);
        } else {
          startDate = new Date();
        }

        let endDate;
        if (selectedConfig.endDate && typeof selectedConfig.endDate === 'number') {
          startDate = new Date(selectedConfig.endDate);
        } else {
          endDate = Moment(startDate)
            .add(1, 'y')
            .toDate();
        }
        this.setState(
          {
            selectedConfiguration: value,
            selectedConfigurationIndex: selectedConfig.index,
            selectedConfigurationRecipients: selectedConfig.exportDest || [],
            scenarioId: selectedConfig.scenarioId,
            template: selectedConfig.template,
            count: `${selectedConfig.maxNumber}`,
            codeCount: `${selectedConfig.codeCount}`,
            endDate,
            startDate,
            codeType: selectedConfig.codeType || '',
            info: selectedConfig.info,
          },
          () => this.updateValidity(this.state),
        );
      } else {
        this.setState({ [fieldName]: value }, () => this.updateValidity(this.state));
      }
    } else {
      this.setState({ [fieldName]: value }, () => this.updateValidity(this.state));
    }
  };

  updateValidity = (newVal: State) => {
    const isValid: boolean = !!newVal.count && !!newVal.scenarioId;
    this.setState({ isValid });
  };

  getDateString = (start, end) => {
    const startStr = start ? Moment(start).format('L') : '--';
    const endStr = end ? Moment(end).format('L') : 'infini';
    return `${startStr} - ${endStr}`;
  };

  renderExistingCodes = () => {
    const { codes } = this.state;
    const { t } = this.props;
    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t('screens.admin.codes.codeScenario')}</th>
            <th scope="col">{t('screens.admin.codes.codeCount')}</th>
            <th scope="col">{t('screens.admin.codes.codePeriod')}</th>
            <th scope="col">{t('screens.admin.codes.codeInfo')}</th>
          </tr>
        </thead>
        <tbody>
          {codes.map((code: Code) => (
            <tr key={code.id}>
              <th scope="row">{code.id}</th>
              <td>{code.scenarioId}</td>
              <td>{`${code.currentCount} / ${code.maxNumber}`}</td>
              <td>{this.getDateString(code.startDate, code.endDate)}</td>
              <td>{code._meta ? code._meta.info : ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  configurationToId = (it) => it.id;

  stenarioToName = (it) => {
    const { locale } = this.props;
    const { name, id } = it;
    return `${id} - ${name.valueForLocale(locale, true)}`;
  };

  codeTypeToId = (it) => it;

  configurationToTitle = (it) => it.name;

  renderCreateCode = () => {
    const {
      scenarioId,
      count,
      startDate,
      template,
      endDate,
      info,
      scenarios,
      isValid,
      selectedConfiguration,
      configurations,
      codeCount,
      codeType,
      codeTypes,
    } = this.state;
    const { t } = this.props;
    const hasSingleCode = codeCount === '1';
    return (
      <div>
        <InputSelect
          fieldName="selectedConfiguration"
          value={selectedConfiguration}
          values={configurations}
          itemToId={this.configurationToId}
          itemToTitle={this.configurationToTitle}
          label={t('screens.admin.codes.codeConfig')}
          help={t('screens.admin.codes.codeConfigHelp')}
          handleChange={this.handleChange}
        />
        <hr />
        <div className="card p-2 mb-2" style={{ display: 'block' }}>
          <div className="d-flex bd-highlight">
            <InputSelect
              style={{ minWidth: 300 }}
              fieldName="scenarioId"
              value={scenarioId}
              values={Object.values(scenarios)}
              itemToId={this.configurationToId}
              itemToTitle={this.stenarioToName}
              label={t('screens.admin.codes.codeScenario')}
              handleChange={this.handleChange}
            />
            <InputString
              style={{ marginLeft: 40 }}
              fieldName="template"
              value={template}
              label={t('screens.admin.codes.codeTemplate')}
              help={t('screens.admin.codes.codeTemplateHelp')}
              handleChange={this.handleChange}
            />
          </div>
          <div className="d-flex bd-highlight">
            <InputDate
              style={{ minWidth: 300 }}
              fieldName="startDate"
              label={t('screens.admin.codes.startDateLabel')}
              value={startDate}
              handleChange={this.handleChange}
            />
            <InputDate
              fieldName="endDate"
              style={{ marginLeft: 40 }}
              label={t('screens.admin.codes.endDateLabel')}
              value={endDate}
              handleChange={this.handleChange}
            />
          </div>
          <div className="d-flex bd-highlight">
            <InputNumber
              style={{ minWidth: 300 }}
              fieldName="count"
              value={count}
              label={t('screens.admin.codes.countLabel')}
              handleChange={this.handleChange}
            />
            <InputNumber
              style={{ marginLeft: 40 }}
              fieldName="codeCount"
              value={codeCount}
              label={t('screens.admin.codes.codeCountLabel')}
              handleChange={this.handleChange}
            />
          </div>

          <div className="d-flex bd-highlight">
            <InputSelect
              style={{ minWidth: 300 }}
              fieldName="codeType"
              value={codeType}
              values={Object.keys(codeTypes)}
              itemToId={this.codeTypeToId}
              itemToTitle={this.codeTypeToId}
              label={t('screens.admin.codes.codeType')}
              handleChange={this.handleChange}
            />
            <InputString
              style={{ marginLeft: 40, width: '100%' }}
              fieldName="info"
              value={info}
              label={t('screens.admin.codes.codeInfo')}
              handleChange={this.handleChange}
            />
          </div>
          <hr />
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="button-save"
            onClick={this._createCode}
            disabled={!isValid}
          >
            {hasSingleCode ? t('screens.admin.codes.createCode') : t('screens.admin.codes.createMultipleCode')}
          </button>
          <button
            className="btn btn-outline-secondary"
            style={{ marginLeft: 10 }}
            type="button"
            id="button-save"
            onClick={this._createCodeConfiguration}
            disabled={!isValid}
          >
            {t('screens.admin.codes.createCodeConfiguration')}
          </button>
        </div>
      </div>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { isLoading } = this.state;
    const { t } = this.props;
    return (
      <TabContent name="codes">
        <VisibilitySensor onChange={this.onVisibilityChanged} partialVisibility>
          <div className="card-header">
            <h3>{t('screens.admin.codes.newCodeSubtitle')}</h3>
          </div>
        </VisibilitySensor>
        <div className="card">
          <div className="card-body">{this.renderCreateCode()}</div>
        </div>

        <div className="card mt-2">
          <div className="card-header">
            <h3>{t('screens.admin.codes.existingSubtitle')}</h3>
          </div>
          <div className="card-body" style={{ height: 500, overflowY: 'scroll' }}>
            {this.renderExistingCodes()}
          </div>
        </div>
        {isLoading && <Loader />}
      </TabContent>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  generateCode: FirebaseHelper.generateCode,
  generateCodeConfiguration: FirebaseHelper.generateCodeConfiguration,
  sendSecretCodesLabels: FirebaseHelper.sendSecretCodesLabels,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withConfirm,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(CodesTab);
