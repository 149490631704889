/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import logo from 'src/assets/eva-studio.png';
import Countdown from 'react-countdown-now';

export interface LoaderProps {
  message?: string;
  endTime?: number;
}

export default class Loader extends React.Component<LoaderProps> {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const { endTime, message } = this.props;
    return (
      <div className="loaderContainer">
        <div className="loaderLogoContainer">
          <img className="loaderLogo" src={logo} alt="logo" x="145" y="142" height="56px" width="50px" />
        </div>
        <svg className="loader" id="logoSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
          <circle cx="170" cy="170" r="160" stroke="#341359" />
          <circle cx="170" cy="170" r="135" stroke="#00E0CC" />
          <circle cx="170" cy="170" r="110" stroke="#FC1472" />
          <circle cx="170" cy="170" r="85" stroke="#E8EDF3" />
        </svg>
        {endTime > 0 ? (
          <div className="loaderTimer">
            <span>{message}</span>
            <Countdown date={endTime} />
          </div>
        ) : (
          message && (
            <div className="loaderTimer">
              <span>{message}</span>
            </div>
          )
        )}
      </div>
    );
  }
}
