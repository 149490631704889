/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Loader } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import NotificationsView from '../components/notifications/NotificationsView';
import Firebase, { withFirebase } from '../../services/Firebase';
import { UserServiceHelper } from '../../store/user';
import { InputBoolean } from '../components';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../assets/styles/app.scss';

export type LoginScreenProps = {
  sendConsent: UserServiceHelper.updateConsentAsyncType,
  editorTosAgreed: boolean,
  editorConfidentialityAgreed: boolean,
  firebase: Firebase,
  history: any,
  t: (key: string) => string,
  addNotif: EventsServiceHelper.addNotifType,
  user: any,
};

type State = {
  error?: string,
  isValid: boolean,
  editorConfidentialityAgreed: boolean,
  editorTosAgreed: boolean,
  isLoading: boolean,
};

class ConsentOverflow extends React.PureComponent<LoginScreenProps, State> {
  static defaultProps = {};

  reader = undefined;

  state = {
    error: undefined,
    isValid: false,
    editorConfidentialityAgreed: false,
    editorTosAgreed: false,
    isLoading: false,
  };

  componentDidMount() {
    try {
      this.initData(this.props);
    } catch (error) {
      console.debug(error);
    }
  }

  componentDidUpdate(oldProps: LoginScreenProps) {
    try {
      if (oldProps.editorConfidentialityAgreed !== this.props.editorConfidentialityAgreed) {
        this.initData(this.props);
      }
      if (oldProps.editorTosAgreed !== this.props.editorTosAgreed) {
        this.initData(this.props);
      }
    } catch (error) {
      console.debug(error);
    }
  }

  initData = (props: LoginScreenProps) => {
    const { editorConfidentialityAgreed, editorTosAgreed } = props;
    this.setState({
      editorConfidentialityAgreed,
      editorTosAgreed,
    });
    this.updateValidity({
      editorConfidentialityAgreed,
      editorTosAgreed,
    });
  };

  sendConsent = async () => {
    const { editorConfidentialityAgreed, editorTosAgreed } = this.state;
    const { sendConsent, firebase, user } = this.props;
    if (!editorConfidentialityAgreed) {
      await this.props.addNotif(NotificationTypes.ERROR, 'E_PRIVACY_REQUIRED');
    } else if (!editorTosAgreed) {
      await this.props.addNotif(NotificationTypes.ERROR, 'E_CGU_REQUIRED');
    } else if (sendConsent) {
      sendConsent(user, editorTosAgreed, editorConfidentialityAgreed, firebase);
    }
  };

  updateValidity = (state) => {
    const stateToUse = state || this.state;
    this.setState({
      isValid: stateToUse.editorConfidentialityAgreed && stateToUse.editorTosAgreed,
    });
  };

  handleChange = (event: any) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  render() {
    const { editorTosAgreed, editorConfidentialityAgreed, error, isValid, isLoading } = this.state;
    const { t } = this.props;

    return (
      <div id="consent-overflow">
        <div className="popup col-md-4 col-12 bg-grey">
          <img src={require('../../assets/images/eva-studio.svg')} id="logo" alt="logo" />
          <h3 id="title" className="titleFont">
            {t('screens.login.consentRequired')}
          </h3>

          {error && <p className="error text-center">{t(`notifications.${error}`)}</p>}

          <form>
            <InputBoolean
              fieldName="editorTosAgreed"
              value={editorTosAgreed}
              label={
                <a href={this.props.firebase.tosUrl} target="_blank" rel="noreferrer">
                  {t('screens.login.agreeCgu')}
                </a>
              }
              handleChange={this.handleChange}
            />
            <InputBoolean
              fieldName="editorConfidentialityAgreed"
              value={editorConfidentialityAgreed}
              label={
                <a href={this.props.firebase.privacyUrl} target="_blank" rel="noreferrer">
                  {t('screens.login.agreeConfidenciality')}
                </a>
              }
              handleChange={this.handleChange}
            />
            <button type="button" className="btn btn-primary" disabled={!isValid} onClick={this.sendConsent}>
              {t('screens.login.sendConsent')}
            </button>
          </form>
        </div>
        <NotificationsView />
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: !!state.user.user,
  user: state.user.user,
  editorTosAgreed: state.user.user?.editorTosAgreed,
  editorConfidentialityAgreed: state.user.user?.editorConfidentialityAgreed,
});

const mapDispatchToProps = {
  sendConsent: UserServiceHelper.updateConsentAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withFirebase,
  withTranslation('default'),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ConsentOverflow);
