/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import LocalizedFile from 'src/data/LocalizedFile';

import { withTranslation } from 'react-i18next';
import HelpButton from '../HelpButton';
import InputLocalizedFile from './InputLocalizedFile';

export interface InputLocalizedFilesProps {
  fieldName: string;
  value: LocalizedFile[];
  label: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  multiline?: boolean;
  t: (key: string) => string;
  helpInfos: any;
  addFile: (any) => any;
  removeFile: (name: string, index: number) => any;
  contentLocale: string;
  handleFileSelected: (name: string, locale: string, file: any, index: number) => any;
  handleContentChange: (name: string, value: string, index: number) => any;
  addFileLocale: (name: string, locale: string, index: number) => any;
  removeFileLocale: (name: string, locale: string, index: number) => any;
  accept?: string;
  separatorBefore?: boolean;
  onFocus?: () => any;
  inputStyle?: ?any;
}

class InputLocalizedFiles extends React.Component<InputLocalizedFilesProps> {
  static defaultProps = {
    help: undefined,
    disabled: false,
    hidden: false,
    multiline: false,
    helpInfos: undefined,
    separatorBefore: false,
    inputStyle: {},
  };

  render() {
    const {
      fieldName,
      value,
      label,
      help,
      disabled,
      multiline,
      hidden,
      helpInfos,
      addFile,
      removeFile,
      contentLocale,
      handleFileSelected,
      handleContentChange,
      addFileLocale,
      removeFileLocale,
      accept,
      separatorBefore,
      onFocus,
      t,
      inputStyle,
    } = this.props;
    return (
      <div className="form-group image" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label htmlFor={fieldName} className="strong text-capitalize">
          {`${label} `}
          {helpInfos &&
            helpInfos.map((helpInfo, index) => (
              <HelpButton
                key={fieldName + index}
                helpStrings={helpInfo.content}
                id={fieldName}
                title={helpInfo.title}
                label={helpInfo.btnLabel}
                display={'inline'}
                className={'ml-auto btn btn-outline-warning ml-2'}
              />
            ))}
        </label>
        <button type="button" className="btn btn-primary btn-sm ml-2 mb-1 add" onClick={addFile}>
          {t('general.add')}
        </button>
        {value &&
          value.map((val, index) => (
            <div className="card pt-0 file" key={`${fieldName}_${val.index}`}>
              <div className="card-body mt-0">
                <InputLocalizedFile
                  fieldName={`${fieldName}_${val.index}`}
                  label={`${fieldName}_${val.index}`}
                  value={val}
                  accept={accept}
                  onFocus={onFocus}
                  disabled={disabled}
                  multiline={multiline}
                  contentLocale={contentLocale}
                  handleFileSelected={(field, locale, file) => handleFileSelected(fieldName, locale, file, val.index)}
                  handleContentChange={(_f, value) => handleContentChange(fieldName, value, val.index)}
                  addFileLocale={(_f, value) => addFileLocale(fieldName, value, index)}
                  removeFileLocale={(_f, value) => removeFileLocale(fieldName, value, index)}
                  inputStyle={inputStyle}
                />
                <button type="button" className="btn delete" onClick={() => removeFile(fieldName, val.index)}>
                  {t('general.delete')}
                </button>
              </div>
            </div>
          ))}
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}

export default withTranslation('default')(InputLocalizedFiles);
