/**
 * @flow
 * @format
 */

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import * as ROUTES from 'src/constants/routes';
import * as OnboardingServiceHelper from 'src/store/scenario/onboarding/OnboardingServiceHelper';
import type { OnboardingOptions } from 'src/data/types/OnboardingOptions';
import type { Team } from 'src/data/types/TeamType';
// import { TeamConfigs } from 'src/data/types/TeamType';
import OnboardingPosition from './OnboardingPosition';
import OnboardingName from './OnboardingName';
import OnboardingDeparture from './OnboardingDeparture';
import OnboardingPitch from './OnboardingPitch';
import OnboardingTeam from './OnboardingTeam';
import OnboardingIntroduction from './OnboardingIntroduction';
import ValidationModal from './ValidationModal';

type Props = {
  openModal: boolean,
  loading: boolean,
  loadingCreation: boolean,
  teams: Array<Team>,
  isEditor: boolean,
  onboarding: OnboardingOptions,
  createNewScenario: (skipOnboarding: boolean) => void,
  resetOnboardingValues: OnboardingServiceHelper.ResetOnboardingValuesType,
  t: (key: string) => string,
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

let numberOfSteps: number = 5;

const OnboardingModal = ({
  openModal,
  loading,
  loadingCreation,
  teams,
  isEditor,
  onboarding,
  createNewScenario,
  resetOnboardingValues,
  t,
}: Props) => {
  const history = useHistory();

  /**
   * Defines which page to display, used because react router created bugs here
   */
  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  const [validationModal, setValidationModal] = React.useState<boolean>(false);
  const [skipOrCreate, setSkipOrCreate] = React.useState<boolean>(false);

  /**
   * Redirects to the next or previous page
   * @param {boolean} isNext indicates if we go to the next or previous page
   */
  const handleRedirection = (isNext: boolean) => {
    let newStep: number;
    // We keep the step between 1 and the number max
    if (isNext) {
      newStep = (currentStep + 1) % (numberOfSteps + 1);
    } else if (currentStep > 1) {
      newStep = (currentStep - 1) % (numberOfSteps + 1);
    } else {
      newStep = 1;
    }
    setCurrentStep(newStep);
  };

  /**
   * Not every users have the same steps, we adapt here to only show the right ones
   */
  const adaptStep = () => {
    if ((isEditor || teams.length <= 1) && currentStep > 1) {
      return currentStep + 1;
    }
    return currentStep;
  };

  /**
   * Blocks the creation if the limit of scenarios is reached
   */
  const showLimitScenarios = () => {
    // const currentTeam = teams.filter((team) => team.uid === onboarding.teamInputId)[0];
    // if (currentTeam) {
    //   return (
    //     (currentTeam.scenarioIds ? currentTeam.scenarioIds.length : 0) >=
    //     TeamConfigs[currentTeam.type].authorizedScenarioCount
    //   );
    // }
    return false;
  };

  /**
   * Displays the element corresponding to the right step
   */
  const renderCorrectPage = () => {
    const stepToUse = adaptStep();
    switch (stepToUse) {
      case 2:
        return <OnboardingTeam teams={teams} isEditor={isEditor} showLimitScenarios={showLimitScenarios()} />;
      case 3:
        return <OnboardingPosition />;
      case 4:
        return <OnboardingName />;
      case 5:
        return <OnboardingDeparture />;
      case 6:
        return <OnboardingPitch />;
      case 1:
      default:
        return (
          <OnboardingIntroduction
            next={() => handleRedirection(true)}
            isEditor={isEditor}
            showLimitScenarios={showLimitScenarios()}
            teamsLength={teams.length}
          />
        );
    }
  };

  /**
   * Redirects to the home page
   */
  const closeRedirection = () => {
    resetOnboardingValues();
    history.push(ROUTES.SCENARIO_DASHBOARD);
  };

  /**
   * Checks if everything is in order, to go through the next step or not
   */
  const checkNextStep = (step?: number) => {
    const stepToUse = step || adaptStep();
    switch (stepToUse) {
      case 1:
        return false;
      case 2:
        return !isEditor && showLimitScenarios();
      case 3:
        return (
          (onboarding.coordinate.latitude === 0 && onboarding.coordinate.longitude === 0) ||
          onboarding.teamInputId.length === 0
        );
      case 4:
        return onboarding.nameCheckbox ? false : onboarding.nameScenario.length === 0;
      case 5:
        return (
          Number.isNaN(Number(onboarding.year)) ||
          onboarding.year.length === 0 ||
          (Number(onboarding.year) === 0 && onboarding.year.includes(' ') && !onboarding.year.includes('0'))
        );
      case 6:
        return (
          checkNextStep(1) ||
          checkNextStep(2) ||
          checkNextStep(3) ||
          checkNextStep(4) ||
          checkNextStep(5) ||
          (onboarding.pitchCheckbox ? false : onboarding.pitchScenario.length === 0) ||
          (onboarding.missionCheckbox ? false : onboarding.missionScenario.length === 0)
        );
      default:
        return false;
    }
  };

  /**
   * Returns the title corresponding to the step
   */
  const screenTitle = () => {
    const stepToUse = adaptStep();
    switch (stepToUse) {
      case 2:
        return t('screens.newScenario.onboarding.positionScreen.teamTitle');
      case 3:
        return t('screens.newScenario.onboarding.positionScreen.startPosition');
      case 4:
        return t('screens.newScenario.onboarding.nameScreen.titleName');
      case 5:
        return t('screens.newScenario.onboarding.departureScreen.titleDeparture');
      case 6:
        return t('screens.newScenario.onboarding.pitchScreen.titlePitch');
      case 1:
      default:
        return t('screens.newScenario.onboarding.title');
    }
  };

  /**
   * Indicates we need to open the validation modal, and if it's for skipping or creating
   */
  const displayValidationModal = (create: boolean) => {
    setSkipOrCreate(create);
    setValidationModal(true);
  };

  /**
   * Displays the modal when authorized
   */
  const displayMainModal = () => {
    if (openModal && !loading) {
      $('#onboardingModal').modal('show');
      // Hide main scrollbar
      if (document.body !== null) {
        document.body.style.overflow = 'hidden';
      }
      if (!isEditor && teams.length > 1) {
        numberOfSteps = 6;
      }
    }
  };

  React.useEffect(() => {
    displayMainModal();
  }, [openModal, loading]);

  /**
   * Modal used to validate the skipping or the creation
   */
  React.useEffect(() => {
    if (validationModal && !loading) {
      $('#onboardingModal').modal('hide');
      $('#validationModal').modal('show');
    } else {
      $('#validationModal').modal('hide');
      displayMainModal();
    }
  }, [validationModal]);

  /**
   * Resizes the modal when needed, and close the modals at the end
   */
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      $('#onboardingModal').modal('hide');
      $('#validationModal').modal('hide');
      // Show again main scrollbar
      if (document.body !== null) {
        document.body.style.overflow = 'unset';
      }
    };
  }, []);

  const finalStep = currentStep === numberOfSteps;

  return (
    <>
      <div
        className="modal fade"
        id="onboardingModal"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="onboardingModalLabel"
        aria-hidden="true"
        style={{ overflowY: 'initial' }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{
            width: '100%',
            height: '100%',
            margin: 0,
            padding: 0,
          }}
        >
          <div
            className="modal-content"
            style={{
              height: '100%',
              maxHeight: `${windowDimensions.height}px`,
              minWidth: `${windowDimensions.width}px`,
            }}
          >
            <div className="modal-header">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-11">
                    {currentStep !== 1 && (
                      <div className="progress w-auto">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          aria-valuenow={((currentStep - 1) / (numberOfSteps - 1)) * 100}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${((currentStep - 1) / (numberOfSteps - 1)) * 100}%` }}
                        ></div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-column col-1">
                    <button
                      type="button"
                      className="close text-danger font-weight-bold btn-block"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={closeRedirection}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
                <div className="row mb-4">
                  {currentStep !== 1 && (
                    <>
                      <div className="col-9 font-weight-bold text-info">{`${t(
                        'screens.newScenario.onboarding.step',
                      )} ${currentStep - 1}/${numberOfSteps - 1}`}</div>
                      <div className="d-flex justify-content-around col-2">
                        <button
                          className="btn btn-outline-info btn-sm w-75 m-0 mr-1 font-weight-bold p-0 "
                          onClick={() => handleRedirection(false)}
                          disabled={currentStep === 1}
                        >
                          {'<'}
                        </button>
                        <button
                          className="btn btn-outline-info btn-sm w-75 font-weight-bold m-0 p-0"
                          onClick={() => handleRedirection(true)}
                          disabled={currentStep === numberOfSteps || checkNextStep()}
                        >
                          {'>'}
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-center mb-2">
                  <h2 className="modal-title" id="onboardingModalLabel">
                    {screenTitle()}
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="modal-body"
              style={{
                overflowY: 'auto',
              }}
            >
              {renderCorrectPage()}
            </div>
            <div className="modal-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-outline-danger mr-1"
                      data-dismiss="modal"
                      onClick={closeRedirection}
                    >
                      {t('screens.newScenario.onboarding.cancel')}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-dark"
                      onClick={() => displayValidationModal(false)}
                      disabled={!isEditor && showLimitScenarios()}
                    >
                      {t('screens.newScenario.onboarding.skip')}
                    </button>
                  </div>
                  <div className="d-flex flex-row-reverse col-5">
                    {currentStep !== 1 && (
                      <>
                        <button
                          type="button"
                          className={`btn btn-${finalStep ? 'primary' : 'outline-info'}`}
                          onClick={() => (finalStep ? displayValidationModal(true) : handleRedirection(true))}
                          disabled={checkNextStep()}
                        >
                          {finalStep
                            ? t('screens.newScenario.onboarding.create')
                            : t('screens.newScenario.onboarding.next')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-info mr-1"
                          onClick={() => handleRedirection(false)}
                          disabled={currentStep === 1}
                        >
                          {t('screens.newScenario.onboarding.previous')}
                        </button>
                      </>
                    )}
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ValidationModal
        modalTitle={
          skipOrCreate
            ? t('screens.newScenario.onboarding.createValidationTitle')
            : t('screens.newScenario.onboarding.skipValidationTitle')
        }
        contentText={
          skipOrCreate
            ? t('screens.newScenario.onboarding.contentCreate')
            : t('screens.newScenario.onboarding.contentSkip')
        }
        loadingCreation={loadingCreation}
        onCloseClick={() => setValidationModal(false)}
        onValidateClick={() => createNewScenario(!skipOrCreate)}
        createButtonLabel={
          skipOrCreate ? t('screens.newScenario.onboarding.create') : t('screens.newScenario.onboarding.skip')
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  onboarding: state.scenario.onboarding,
});

const mapDispatchToProps = {
  resetOnboardingValues: OnboardingServiceHelper.resetOnboardingValues,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(OnboardingModal);
