/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { connect } from 'react-redux';
import Flag from 'react-world-flags';
import 'bootstrap/dist/css/bootstrap.min.css';

import packageJson from 'src/../package.json';
import { PreferencesServiceHelper } from 'src/store/preferences';
import type { AtlEditorState } from 'src/store';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withRouter, Link, useLocation, useHistory } from 'react-router-dom';
import * as ROUTES from 'src/constants/routes';
import { i18n, languageToFlag } from 'src/assets/locales';
import TutorialModal from '../tutorial/TutorialModal';

import '../Sidebar/style/style-default.css';
import '../../App/App.css';
import { Sidebar } from '../Sidebar';
import Breadcrumb from './Breadcrumb';

export type NavBarProps = {
  updateOnFirebase: () => Promise<void>,
  onRouteChange: (path: string) => Promise<void>,
  cleanupUrlProperties: PreferencesServiceHelper.cleanupUrlPropertiesType,
  scenarioId?: string,
  t: (key: string) => string,
  logo: any,
  showSaveBtn: boolean,
  editionLocale: string,
};

const NavBar = ({
  updateOnFirebase,
  onRouteChange,
  cleanupUrlProperties,
  scenarioId,
  logo,
  showSaveBtn,
  editionLocale,
}: NavBarProps) => {
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    onRouteChange(window.location?.pathname);
  }, []);

  React.useEffect(() => {
    console.log(location);
    onRouteChange(location?.pathname);
  }, [location]);

  const closeContent = async () => {
    await cleanupUrlProperties();
    history.push(ROUTES.SCENARIO_DASHBOARD);
  };

  return (
    <div id="mainnavbar">
      <nav className="navbar navbar-expand" id="main">
        <Sidebar
          logo={logo}
          version={packageJson.version}
          showSaveBtn={showSaveBtn}
          updateOnFirebase={updateOnFirebase}
          closeContent={closeContent}
        />

        <div id="right-part">
          <ul className="navbar-nav" id="handle-buttons">
            {false && <TutorialModal />}

            <li className="nav-item">
              <Link to={ROUTES.SETTINGS} className="p-0 pt-3 pb-3 m-0">
                <div
                  className="row"
                  style={{
                    backgroundImage:
                      'linear-gradient(to top left,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(50% - 1px),white 50%,rgba(0,0,0,0) calc(50% + 1px),rgba(0,0,0,0) 100%)',
                  }}
                >
                  <div className="d-flex flex-row mb-1 w-100">
                    <Flag code={languageToFlag(i18n.language)} className="rounded border" width="22" />
                  </div>
                  <div className="d-flex flex-row-reverse w-100">
                    <Flag code={languageToFlag(editionLocale)} className="rounded border" width="22" />
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {!!scenarioId && (
        <div id="underbar">
          <div></div>
          {scenarioId && <Breadcrumb />}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AtlEditorState) => ({
  scenarioId: state.scenario.header && state.scenario.header.id,
  showSaveBtn: state.preferences.urlProperties.scenarioId,
  editionLocale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  cleanupUrlProperties: PreferencesServiceHelper.cleanupUrlProperties,
};

export default compose(withTranslation('default'), connect(mapStateToProps, mapDispatchToProps), withRouter)(NavBar);
