/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FirebaseSingleton as firebase } from 'src/services/Firebase';
import { InputString, InputBoolean, Loader } from 'src/pages/components';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

type Props = {
  locale: string,
  notify: EventsServiceHelper.addNotifType,
  t: (key: string) => string,
};

type State = {
  userEmail?: string,
  recognizedEmail?: string,

  uid?: string,
  isAdmin: boolean,
  isModerator: boolean,
  isEditor: boolean,

  isLoading: boolean,
  isValid: boolean,
};

class UserRightsEditionWidget extends React.PureComponent<Props, State> {
  static defaultProps = {
    scenarios: [],
  };

  state = {
    userEmail: undefined,
    recognizedEmail: undefined,

    uid: undefined,
    isAdmin: false,
    isModerator: false,
    isEditor: false,

    isLoading: false,
    isValid: false,
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    // $FlowFixMe: Boolean can only be set for boolean fields
    const newState: $Shape<State> = { [fieldName]: value };
    if (fieldName === 'userEmail') {
      newState.recognizedEmail = undefined;
    }
    this.setState(newState, () => this.updateValidity(this.state));
  };

  loadUser = async () => {
    const { userEmail } = this.state;
    if (userEmail) {
      try {
        this.setState({ isLoading: true });
        const user = await firebase.getUserByEmailAsync(userEmail);

        const customClaims = user.customClaims || {};

        this.setState(
          {
            recognizedEmail: userEmail,
            uid: user.uid,
            isAdmin: !!customClaims.admin,
            isModerator: !!customClaims.moderator,
            isEditor: !!customClaims.editor,
            isLoading: false,
          },
          () => this.updateValidity(this.state),
        );
      } catch (error) {
        console.error('User does not exists', error);
        this.props.notify(NotificationTypes.ERROR, 'E_USER_NOT_FOUND');
        this.setState({ isLoading: false });
      }
    }
  };

  saveRights = async () => {
    const { recognizedEmail, isAdmin, isModerator, isEditor, uid } = this.state;
    if (recognizedEmail && uid) {
      try {
        this.setState({ isLoading: true });
        const customClaims = {
          admin: isAdmin,
          moderator: isModerator,
          editor: isEditor,
        };

        await firebase.setUserRightsAsync(recognizedEmail, uid, customClaims);

        this.setState({ isLoading: false });
      } catch (error) {
        console.error('Cannot update rights', error);
        this.setState({ isLoading: false });
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  updateValidity = (newVal: State) => {
    const isValid: boolean = !!newVal.userEmail;
    this.setState({ isValid });
  };

  renderRights = () => {
    const { uid, isAdmin, isModerator, isEditor, isValid } = this.state;
    const { t } = this.props;

    return (
      <div className="list-group">
        <div className="card p-2 mb-2">
          <h4>{t('screens.admin.users.userFoundTitle')}</h4>
          <p>
            <strong>{t('screens.admin.users.idPlaceholder')}: </strong>
            {uid}
          </p>

          <h5>{t('screens.admin.users.rightsPlaceholder')}</h5>
          <InputBoolean
            fieldName="isAdmin"
            value={isAdmin}
            label={t('screens.admin.users.isAdminLabel')}
            help={t('screens.admin.users.isAdminHelp')}
            handleChange={this.handleChange}
          />
          <InputBoolean
            fieldName="isModerator"
            value={isModerator}
            label={t('screens.admin.users.isModeratorLabel')}
            help={t('screens.admin.users.isModeratorHelp')}
            handleChange={this.handleChange}
          />
          <InputBoolean
            fieldName="isEditor"
            value={isEditor}
            label={t('screens.admin.users.isEditorLabel')}
            help={t('screens.admin.users.isEditorHelp')}
            handleChange={this.handleChange}
          />
        </div>

        <button
          className="btn btn-outline-secondary mb-3"
          type="button"
          id="button-addon2"
          onClick={this.saveRights}
          disabled={!isValid}
        >
          {t('general.save')}
        </button>
      </div>
    );
  };

  render() {
    const { userEmail, recognizedEmail, isLoading } = this.state;
    const { t } = this.props;
    return (
      <div className="card bg-light screenBlock">
        <div className="card-header">
          <h3>{t('screens.admin.users.sectionTitle')}</h3>
        </div>
        <div className="card-body p-2 pl-4">
          <div className="list-group pb-10">
            <InputString
              fieldName="userEmail"
              value={userEmail}
              label={t('screens.admin.users.userEmailLabel')}
              handleChange={this.handleChange}
            />
            <button className="btn btn-outline-secondary mb-3" type="button" id="button-addon2" onClick={this.loadUser}>
              {t('general.load')}
            </button>
          </div>
          {recognizedEmail && this.renderRights()}
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  notify: EventsServiceHelper.addNotif,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(UserRightsEditionWidget);
