/**
 * @flow
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css';

import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import Firebase, { withFirebase, FirebaseSingleton } from 'src/services/Firebase';
import { Claims } from 'src/constants/roles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateId } from 'src/data/AtlObject';
import { ScenarioServiceHelper } from 'src/store/scenario';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import type { MissionType } from 'src/data';
import { InputSelect, InputString, Loader } from '../components';
import SubmissionListButton from './components/SubmissionListButton';
import ReviewScenario from './components/ReviewScenario';
import ConfirmReview from './components/ConfirmReview';

type Props = {
  editionLocale: string,
  t: (key: string) => string,
  firebase: Firebase,
  addNotif: EventsServiceHelper.addNotifType,
};

const ReviewScreen = (props: Props) => {
  const [submissions, setSubmissions] = React.useState([]);
  const [current, setCurrent] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [isReviewing, setReviewing] = React.useState(false);
  const [collapseSubmissionListButtonText, setCollapseListButtonText] = React.useState('chevron-left');
  const [collapseEditionText, setCollapseEditionText] = React.useState('chevron-left');
  const [submissionListClass, setSubmissionListClass] = React.useState('col-3');
  const [reviewClass, setReviewClass] = React.useState('col-9');
  const [editingClass, setEditingClass] = React.useState('visually-hidden');

  const [decision, setDecision] = React.useState(undefined);
  const [currentItem, setCurrentItem] = React.useState(undefined);
  const [errorKey, setErrorKey] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [configuredErrors, setConfiguredErrors] = React.useState('');
  const [errors, setErrors] = React.useState([]);

  // Never used ?
  // const updateSubs = (newSub) => {
  //   setSubmissions(
  //     submissions.reduce((acc, it) => {
  //       if (it.submissionId !== newSub.submissionId) {
  //         acc.push(it);
  //       } else {
  //         acc.push(newSub);
  //       }
  //       return acc;
  //     }, []),
  //   );
  // };

  // Never used ?
  // const onSubmissionModified = (snap) => {
  //   const id = snap.key;
  //   const value = snap.val();
  //   const lastRelease = Object.values(value).reduce((acc, cur) => {
  //     if (!acc || moment(acc.submissionDate).isBefore(moment(cur.submissionDate))) {
  //       return cur;
  //     }
  //     return acc;
  //   }, undefined);
  //   console.log('would reduce', submissions);
  //   const newSubs = submissions.reduce((acc, it) => {
  //     if (it.submissionId !== id) {
  //       acc.push(it);
  //     } else {
  //       acc.push(lastRelease);
  //     }
  //     return acc;
  //   }, []);

  //   console.log({ id, lastRelease, newSubs, subs: [...submissions] });
  //   updateSubs(lastRelease);
  // };

  const show = async () => {
    setSubmissions([]);
    setLoading(true);
    try {
      const snap = await FirebaseSingleton.pendingSubmissions().once('value');
      const val = snap.val();
      setSubmissions(
        Object.values(val).map((value) => {
          return Object.values(value).reduce((acc, cur) => {
            if (!acc || moment(acc.submissionDate).isBefore(moment(cur.submissionDate))) {
              return cur;
            }
            return acc;
          }, undefined);
        }),
      );
    } catch (error) {
      console.warn('Cannot retrieve codes', error);
    }
    setLoading(false);
  };

  const hide = () => {
    try {
      //
    } catch (error) {
      console.log(error);
    }
  };

  // Menu toggles
  // ----------------------
  const hideSubmissionList = () => {
    setCollapseListButtonText('chevron-right');
    setSubmissionListClass('visually-hidden col-1');
    switch (editingClass) {
      case 'visually-hidden':
        setReviewClass('col-11');
        break;
      case 'col-4':
        setReviewClass('col-7');
        break;
      default:
        setReviewClass('col-10');
    }
  };

  const showSubmissionList = () => {
    setCollapseListButtonText('chevron-left');
    setSubmissionListClass('col-3');
    if (editingClass === 'col-4') {
      setReviewClass('col-4');
    } else {
      setReviewClass('col-8');
    }
  };

  const onToggleListClick = () => {
    if (collapseSubmissionListButtonText === 'chevron-left') {
      hideSubmissionList();
    } else {
      showSubmissionList();
    }
  };

  const hideEdition = (totalyHide?: boolean) => {
    setCollapseEditionText('chevron-left');
    if (!totalyHide) {
      setEditingClass('visually-hidden col');
    } else {
      setEditingClass('visually-hidden');
    }
    if (submissionListClass === 'col-3') {
      setReviewClass('col-8');
    } else {
      setReviewClass('col-10');
    }
  };

  const showEdition = () => {
    setCollapseEditionText('chevron-right');
    setEditingClass('col-4');
    if (submissionListClass === 'col-3') {
      setReviewClass('col-5');
    } else {
      setReviewClass('col-7');
    }
  };

  const onCollapseEdition = () => {
    if (collapseEditionText === 'chevron-right') {
      hideEdition();
    } else {
      showEdition();
    }
  };

  const selectSubmission = (submission: any) => {
    if (!isReviewing) {
      setCurrent(submission);
    }
  };

  const onReviewStateChanged = (isReviewing: boolean) => {
    setReviewing(isReviewing);
    if (isReviewing) {
      hideEdition();
      setErrors([]);
      setDecision(undefined);
    } else {
      hideEdition(true);
    }
    setTimeout(() => {
      // hideSubmissionList();
    }, 1000);
  };

  const onVisibilityChanged = (visible: boolean) => {
    if (visible) {
      show();
    }
    if (!visible) {
      hide();
    }
    // TODO May update list
  };

  const addError = () => {
    setErrors([...errors, { pathToItem: currentItem, message: errorMessage, key: errorKey, id: generateId() }]);
    setErrorMessage('');
    setErrorKey('');
    setCurrentItem('');
  };

  const handleChange = (event) => {
    const { value, id } = event.target;
    switch (id) {
      case 'errorMessageText':
        setErrorMessage(value);
        setErrorKey('');
        break;
      case 'errorKey': {
        const error = configuredErrors.find((it) => it.key === value);
        setErrorMessage(error.value);
        setErrorKey(value);
        break;
      }
      case 'decision': {
        setDecision(value);
        break;
      }
      default:
    }
  };

  const prepareError = (errors: any[], item: string) => {
    setCurrentItem(item || '');
    setConfiguredErrors(errors);
    showEdition();
  };

  const sendReview = async (newVending?: {
    comingSoon: boolean,
    visible: boolean,
    isFree: boolean,
    price: number,
    iapSku: string,
    promoExpirationDate: number,
    externalSeller: boolean,
    expendable: boolean,
    isOfficial: boolean,
    missionType: MissionType,
  }) => {
    setLoading(true);
    try {
      const response = await FirebaseSingleton.validateSubmission(
        current.scenarioId,
        current.submissionId,
        decision,
        errors,
      );
      const { success, crash } = response.data;
      if (success) {
        // update vending info, if needed
        if (newVending && current) {
          const {
            comingSoon,
            visible,
            isFree,
            price,
            iapSku,
            promoExpirationDate,
            externalSeller,
            expendable,
            isOfficial,
            missionType,
          } = newVending;
          const newVendingInfo: ScenarioVendingInfo = ScenarioServiceHelper.prepareVendingInfoToDeploy(
            comingSoon,
            visible,
            isFree,
            price,
            iapSku,
            promoExpirationDate,
            externalSeller,
            expendable,
          );
          try {
            await props.firebase.updateVendingInfoAsync(current.scenarioId, newVendingInfo, isOfficial, missionType);
          } catch (err) {
            props.addNotif(NotificationTypes.ERROR, 'E_VENDING_INFO_NOT_UPDATED');
          }
        }

        hideEdition(true);
        show();
        setCurrent({});
        props.addNotif(NotificationTypes.SUCCESS, 'S_SUBMISSION_REVIEWED');
      } else {
        props.addNotif(NotificationTypes.ERROR, crash.startsWith('E_') ? crash : 'E_SUBMISSION_REVIEW_FAILED');
      }
    } catch (error) {
      props.addNotif(
        NotificationTypes.ERROR,
        error.message.startsWith('E_') ? error.message : 'E_SUBMISSION_REVIEW_FAILED',
      );
    }
    setLoading(false);
  };

  const removeError = (error) => {
    setErrors(errors.filter((it) => it.id !== error.id));
  };

  const { t } = props;
  return (
    <div className="pageContainer noScroll" style={{ maxHeight: 'calc(100vh - 80px)' }} id="review-screen">
      <div className="row mr-0 noScroll" style={{ height: 'calc(100vh - 100px)' }}>
        <VisibilitySensor onChange={onVisibilityChanged} partialVisibility={true}>
          <React.Fragment>
            <div className={` fill noScroll  ${submissionListClass}`} id="leftColumn" style={{ height: '100%' }}>
              <div
                className="container-fluid  background-primary "
                id="graphContainer"
                style={{ overflowY: 'scroll', height: '100%' }}
              >
                <div className="list-group pb-2 pl-2 pr-2">
                  {submissions.map((element: ReleaseInfos) => (
                    <SubmissionListButton
                      key={element.scenarioId}
                      info={element}
                      onClick={selectSubmission}
                      isActive={element.scenarioId === current.scenarioId}
                    />
                  ))}
                  {!submissions.length && <h6>{t('screens.review.noSubmissions')}</h6>}
                </div>{' '}
              </div>
              <div className={'sideButton'}>
                <div className="ml-auto mr-2 $mb-2 interactive text-main" onClick={onToggleListClick}>
                  <FontAwesomeIcon icon={['fas', collapseSubmissionListButtonText]} />
                </div>
              </div>
            </div>
            <div className={`${reviewClass} d-flex pb-4 pl-2 pt-2`}>
              {current.scenarioId ? (
                <div style={{ height: 'calc(100vh - 90px)', width: '100%', overflowY: 'auto' }}>
                  <h4>{current.scenarioId}</h4>
                  <ReviewScenario info={current} onReviewStateChanged={onReviewStateChanged} addError={prepareError} />
                </div>
              ) : (
                <div>{!submissions.length && <h6>{t('screens.review.noCurrentSubmission')}</h6>}</div>
              )}
            </div>
            {isReviewing && (
              <div className={editingClass} id="rightColumn">
                <div className={'sideButton'}>
                  <div className="ml-auto mr-2 mb-2 interactive text-main" onClick={onCollapseEdition}>
                    <FontAwesomeIcon icon={['fas', collapseEditionText]} />
                  </div>
                </div>
                {editingClass === 'col-4' && (
                  <div className="fill pl-4">
                    <div className="card mt-2 ml-4">
                      <div className="card-header">
                        <h5 className="card-title">{t('screens.review.configureError')}</h5>
                      </div>
                      <div className="card-body">
                        <div className="container-fluid fill background-primary" id="graphContainer">
                          <InputString
                            style={{ marginRight: 5 }}
                            fieldName="item"
                            value={currentItem}
                            label={t('screens.review.item')}
                            disabled
                          />
                          <InputSelect
                            fieldName={'errorKey'}
                            value={errorKey}
                            values={configuredErrors}
                            itemToId={(it) => it.key}
                            itemToTitle={(it) => it.value}
                            label={t('screens.review.errorMessageConf')}
                            handleChange={handleChange}
                          />
                          <InputString
                            style={{ marginRight: 5 }}
                            fieldName="errorMessageText"
                            value={errorMessage}
                            multiline
                            label={t('screens.review.errorMessageText')}
                            handleChange={handleChange}
                          />
                          <button
                            className="btn btn-primary ml-2"
                            type="button"
                            id="button-deploy"
                            onClick={addError}
                            disabled={!errorMessage}
                          >
                            {t('screens.review.addError')}
                          </button>
                        </div>
                      </div>
                    </div>
                    <ConfirmReview
                      current={current}
                      errors={errors}
                      decision={decision}
                      removeError={removeError}
                      handleChange={handleChange}
                      sendReview={sendReview}
                      setLoading={setLoading}
                    />
                  </div>
                )}
              </div>
            )}
            {loading && <Loader />}
          </React.Fragment>
        </VisibilitySensor>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  editionLocale: state.preferences.editionLocale,
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = { addNotif: EventsServiceHelper.addNotif };

export default compose(
  withFirebase,
  withAuthorization(AuthenticatedCondition, [Claims.Admin]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(ReviewScreen);
