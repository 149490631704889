/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';

import TriggeredItem, { TriggeredItemConditions } from 'src/data/TriggeredItem';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import HelpButton from 'src/pages/components/HelpButton';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';

import { InputBoolean } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { itemTypeColorator } from '../../components/graph/baseItem/BaseItemColorations';

export type TriggeredItemInputProps = {
  triggeredItem: any,
  items: any,
  triggers: any,
  locale: string,
  updateTrigger: ItemsServiceHelper.updateTriggeredItemDataType,
  parentId: string,
  childId: string,
  nodeId: string,
  itemColor: string,
  screenplayEngineParent: any, // TODO
  screenplayEngineChild: any, // TODO
  childType: string,
  parentType: string,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  startEditing: () => any,
  isEditingItem: boolean,
  t: (key: string) => string,
  displayModal: boolean,
  setDisplayModal: Dispatch<SetStateAction<boolean>>,
};

type State = {
  id: string,
  timer?: string,
  condition: string,
  conditionValue?: string,
  newTriggeredCondition?: string,
  newTriggeredConditionValue?: string,
  isValid: boolean,
  disableNotif: boolean,
  hasChanges: boolean,
};

class TriggeredItemInput extends React.PureComponent<TriggeredItemInputProps, State> {
  state = {
    id: '',
    timer: undefined,
    condition: '',
    conditionValue: undefined,
    newTriggeredCondition: undefined,
    newTriggeredConditionValue: undefined,
    isValid: false,
    disableNotif: false,
    hasChanges: false,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps: TriggeredItemInputProps, oldState: State) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded(oldProps, oldState);
    }
    try {
      if (oldProps.nodeId !== this.props.nodeId) {
        this.setItemData(this.props);
      }
    } catch (error) {
      console.debug(error);
    }
  }

  componentWillUnmount() {
    this.warnSaveIfNeeded();
  }

  warnSaveIfNeeded = (props?: TriggeredItemInputProps = this.props, state?: State = this.state) => {
    if (this.state.hasChanges && props.scenarioId) {
      const updateData = this.getDataToSave(props, state);
      const { isValid } = state;
      if (isValid && this.props.scenarioId) {
        this.updateWithData(updateData, false, false);
      } else {
        this.props.addNotif(
          NotificationTypes.ERROR,
          !isValid ? 'E_UNSAVED_ITEM_INVALID' : 'E_UNSAVED_ITEM',
          undefined,
          0,
        );
      }
    }
  };

  setItemData = (props: TriggeredItemInputProps) => {
    const { triggeredItem } = props;
    if (triggeredItem) {
      const { id, condition, newTriggeredCondition, disableNotif } = triggeredItem;
      const newState = {
        id: id || '',
        condition: condition || '',
        newTriggeredCondition: newTriggeredCondition || TriggeredItemConditions.Added,
        hasChanges: false,
        conditionValue: undefined,
        newTriggeredConditionValue: undefined,
        timer: undefined,
        disableNotif: !!disableNotif,
      };
      newState.conditionValue = `${triggeredItem.conditionValue}`;
      if (triggeredItem.conditionValue === undefined) {
        newState.conditionValue = '';
      }
      newState.newTriggeredConditionValue = `${triggeredItem.newTriggeredConditionValue}`;
      if (triggeredItem.newTriggeredConditionValue === undefined) {
        newState.newTriggeredConditionValue = '';
      }
      newState.timer = `${triggeredItem.timer}`;
      if (triggeredItem.timer === undefined) {
        newState.timer = '';
      }
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event: any) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal: {
    id: string,
    condition?: string,
    conditionValue?: string,
    timer?: string,
    newTriggeredCondition?: string,
    newTriggeredConditionValue?: string,
    disableNotif?: boolean,
  }) => {
    let isValid = false;
    if (newVal) {
      const { id } = newVal;
      isValid = !!id;
    }
    this.setState({ isValid });
  };

  getDataToSave = (props?: TriggeredItemInputProps = this.props, state?: State = this.state) => {
    const {
      id,
      timer,
      condition,
      conditionValue,
      newTriggeredCondition,
      newTriggeredConditionValue,
      disableNotif,
    } = state;
    const { updateTrigger, triggeredItem, parentId } = props;

    const newTrigger = new TriggeredItem(triggeredItem);
    newTrigger.id = id;
    newTrigger.timer = timer && timer.length ? parseInt(timer, 10) : undefined;
    newTrigger.condition = condition;
    newTrigger.conditionValue = conditionValue && conditionValue.length ? parseInt(conditionValue, 10) : undefined;
    newTrigger.newTriggeredCondition = newTriggeredCondition;
    newTrigger.newTriggeredConditionValue =
      newTriggeredConditionValue && newTriggeredConditionValue.length
        ? parseInt(newTriggeredConditionValue, 10)
        : undefined;
    newTrigger.disableNotif = !!disableNotif;
    return { newTrigger, updateTrigger, parentId };
  };

  updateWithData = async (updateData, notifyUi: boolean = false, sendNotif: boolean = true) => {
    const { updateTrigger, parentId, newTrigger } = updateData;
    if (updateTrigger) {
      updateTrigger(parentId, newTrigger, sendNotif);
      if (notifyUi) {
        this.setState({ hasChanges: false });
      }
    }
  };

  updateTrigger = () => {
    const updateData = this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  render() {
    const { screenplayEngineParent, screenplayEngineChild, childId, parentId, childType, parentType, t } = this.props;
    const {
      timer,
      condition,
      conditionValue,
      newTriggeredCondition,
      newTriggeredConditionValue,
      disableNotif,
      isValid,
    } = this.state;
    return (
      <div
        className="card bg-light screenBlock pb-2"
        style={{ height: '100%', overflow: 'hidden', borderRadius: '10px', backgroundColor: 'white', border: 'none' }}
      >
        <div
          className="card-header"
          ref={(node) => {
            if (node) {
              node.style.setProperty(
                'background',
                `linear-gradient(45deg,${this.props.itemColor}, ${this.props.itemColor}99)`,
                'important',
              );
            }
          }}
          style={{ border: 'none', height: '65px', alignItems: 'center' }}
        >
          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={this.updateTrigger}
            disabled={!isValid}
            style={{ float: 'left', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'save']} />
          </div>

          <h3
            className="text-capitalize padding-before h-100 d-flex align-items-center"
            style={{ display: 'inline-block', maxWidth: '80%' }}
          >
            {t('screens.scenarioEdition.triggerEdition.sectionTitle')}
          </h3>

          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={() => this.props.setDisplayModal(false)}
            disabled={!isValid}
            style={{ float: 'right', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
        <div className="pr-3 pl-3 pt-0 pb-3" style={{ height: '100%', overflowY: 'scroll', backgroundColor: 'white' }}>
          <div className="form-group">
            <div className="form-group">
              <div className="row p-3 pb-0">
                <span className="col-8 p-0 m-0 font-weight-bold">
                  {`${t('screens.scenarioEdition.triggerEdition.parentState')} `}
                  {parentType && (
                    <span
                      className="pl-2 pr-2 rounded text-white"
                      style={{ backgroundColor: itemTypeColorator({ type: parentType }) }}
                    >
                      {parentId}
                    </span>
                  )}
                  {` ${t('screens.scenarioEdition.triggerEdition.parentStateIs')}`}
                </span>
                <HelpButton
                  helpStrings={screenplayEngineParent}
                  id={`screenplay${parentType}`}
                  title={t('screens.scenarioEdition.triggerEdition.stateHelp', { parentType })}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning'}
                />
              </div>
              <select
                className="custom-select"
                id="condition"
                onFocus={this.onFieldFocus}
                onChange={this.handleChange}
                value={condition}
                style={{ border: 'none', backgroundColor: '#f0f5fa' }}
              >
                <option value="ezdezdez">{t('general.select')}</option>
                {screenplayEngineParent &&
                  screenplayEngineParent.map((element) => (
                    <option key={element.key} value={element.key}>
                      {t(`triggers.${element.key.toLowerCase()}`).toUpperCase()}
                    </option>
                  ))}
              </select>

              {condition === TriggeredItemConditions.Unlocked && (
                <div className="input-group">
                  <input
                    type="number"
                    onFocus={this.onFieldFocus}
                    className="form-control"
                    placeholder={t('screens.scenarioEdition.triggerEdition.parentValue')}
                    aria-label="conditionValue"
                    id="conditionValue"
                    aria-describedby="basic-addon1"
                    onChange={this.handleChange}
                    value={conditionValue}
                    style={{ border: 'none', backgroundColor: '#f0f5fa' }}
                  />
                  <HelpButton
                    helpStrings={t('helpStrings:general.triggerCondition', { returnObjects: true })}
                    id={'conditionValueHelp'}
                    title={'conditionValue'}
                    display={'inline'}
                    className={'ml-auto btn btn-outline-warning unlockbutton'}
                  />
                </div>
              )}
            </div>

            <div className="form-group">
              <div className="row p-3 pb-0">
                <span className="col-8 p-0 m-0 font-weight-bold">
                  {`${t('screens.scenarioEdition.triggerEdition.newState')} `}
                  {childType && (
                    <span
                      className="pl-2 pr-2 rounded text-white"
                      style={{ backgroundColor: itemTypeColorator({ type: childType }) }}
                    >
                      {childId}
                    </span>
                  )}
                  {` ${t('screens.scenarioEdition.triggerEdition.newStateIn')}`}
                </span>
                <HelpButton
                  helpStrings={screenplayEngineChild}
                  id={`screenplay${childType}`}
                  title={t('screens.scenarioEdition.triggerEdition.stateHelp', { parentType: childType })}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning'}
                />
              </div>
              <select
                className="form-control"
                onFocus={this.onFieldFocus}
                id="newTriggeredCondition"
                onChange={this.handleChange}
                value={newTriggeredCondition}
                style={{ border: 'none', backgroundColor: '#f0f5fa' }}
              >
                {screenplayEngineChild &&
                  screenplayEngineChild.map((element) => (
                    <option key={element.key} value={element.key}>
                      {t(`triggers.${element.key.toLowerCase()}`).toUpperCase()}
                    </option>
                  ))}
              </select>
              {newTriggeredCondition === TriggeredItemConditions.Unlocked && (
                <div className="input-group">
                  <input
                    type="number"
                    onFocus={this.onFieldFocus}
                    className="form-control"
                    placeholder={t('screens.scenarioEdition.triggerEdition.newValue')}
                    aria-label="newTriggeredConditionValue"
                    id="newTriggeredConditionValue"
                    aria-describedby="basic-addon1"
                    onChange={this.handleChange}
                    value={newTriggeredConditionValue}
                    style={{ border: 'none', backgroundColor: '#f0f5fa' }}
                  />
                  <HelpButton
                    helpStrings={t('helpStrings:general.triggerCondition', { returnObjects: true })}
                    id={'newTriggeredConditionValueHelp'}
                    title={'newTriggeredConditionValue'}
                    display={'inline'}
                    className={'input-group-append btn btn-outline-warning ml-2'}
                  />
                </div>
              )}
            </div>
            <div className="form-group pt-3">
              <label htmlFor={'timer'}>{t('screens.scenarioEdition.triggerEdition.trigger')}</label>
              <input
                type="number"
                onFocus={this.onFieldFocus}
                className="form-control"
                placeholder="Timer"
                aria-label="Timer"
                id="timer"
                aria-describedby="basic-addon1"
                onChange={this.handleChange}
                value={timer}
                style={{ border: 'none', backgroundColor: '#f0f5fa' }}
              />
            </div>
            <InputBoolean
              fieldName="disableNotif"
              onFocus={this.onFieldFocus}
              value={disableNotif}
              label={t('screens.scenarioEdition.triggerEdition.disableNotif')}
              handleChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { parentId, childId, nodeId } = ownProps;
  let trigger;
  const parentItem = parentId && state.scenario.items[parentId];
  if (parentItem && childId) {
    trigger = parentItem.triggeredItems.find((trig) => trig.nodeId === nodeId);
  }
  const itemKeys = Object.keys(state.scenario.items).filter(
    (key) => key !== undefined && key !== '__detachedNodes' && key !== '_persist',
  );
  const items = itemKeys.map((key) => state.scenario.items[key]);
  const childItem = trigger && state.scenario.items[trigger.id];
  const childTriggers = childItem && childItem.type && state.configuration.screenplay[childItem.type];
  const parentTriggers = parentItem && parentItem.type && state.configuration.screenplay[parentItem.type];
  return {
    scenarioId: state.scenario.header.id,
    triggers: state.configuration.triggers,
    items,
    triggeredItem: trigger,
    parentType: parentItem && parentItem.type,
    childType: childItem && childItem.type,
    screenplayEngineParent: parentTriggers || [],
    screenplayEngineChild: childTriggers || [],
    locale: state.preferences.editionLocale,
  };
};

const mapDispatchToProps = {
  updateTrigger: ItemsServiceHelper.updateTriggeredItem,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['default', 'helpStrings']),
)(TriggeredItemInput);
