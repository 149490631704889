/**
 * @flow
 * @format
 */
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VisibilityAndVendingChanger } from 'src/pages/admin/components';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { missionTypes, type MissionType } from 'src/data';
import type { ScenarioVendingInfo } from 'src/data';
import { CatalogTypes } from 'src/services/Firebase/types';
import { InputSelect } from '../../components';

type Props = {
  current: any,
  errors: [],
  decision: ?string,
  removeError: (it: any) => void,
  handleChange: (event: Event) => void,
  sendReview: (info?: ScenarioVendingInfo & { isOfficial: boolean, missionType: MissionType }) => void,
  setLoading: (loading: boolean) => void,
  t: (key: string) => string,
  firebase: Firebase,
};

const ConfirmReview = ({
  errors,
  decision,
  current,
  removeError,
  handleChange,
  sendReview,
  setLoading,
  t,
  firebase,
}: Props) => {
  const [comingSoonNew, setComingSoon] = React.useState<boolean>(false);
  const [visibleNew, setVisible] = React.useState<boolean>(false);
  const [isOfficialNew, setIsOfficialNew] = React.useState<boolean>(false);
  const [missionTypeNew, setMissionTypeNew] = React.useState<MissionType>(missionTypes.isPrivate);

  const retrieveVendingInfoAndProducts = async () => {
    try {
      setLoading(true);
      const vendingInfoSnapshot = await firebase.scenarioVendingInfo(current.scenarioId).once('value');
      // Vending info & visibility
      if (vendingInfoSnapshot.exists()) {
        const { comingSoon, visible }: ScenarioVendingInfo = vendingInfoSnapshot.val();
        setComingSoon(comingSoon);
        setVisible(visible);
        const headerInfoSnapshot = await firebase.scenarioHeader(current.scenarioId, CatalogTypes.dev).once('value');
        // Mission type
        if (headerInfoSnapshot.exists()) {
          const { missionType }: { missionType: MissionType } = headerInfoSnapshot.val();
          setMissionTypeNew(missionType);
          setIsOfficialNew(missionType === missionTypes.isOfficial);
        }
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  const handleVendingVisibilityChange = (event) => {
    const { value, id: fieldName } = event.target;
    switch (fieldName) {
      case 'comingSoon':
        setComingSoon(value);
        break;
      case 'visible':
        setVisible(value);
        break;
      case 'isOfficial':
        setIsOfficialNew(value);
        break;
      case 'missionType':
        setMissionTypeNew(value);
        break;
      default:
    }
  };

  const sendReviewData = () => {
    if (decision === 'validated') {
      // We also update the vending info
      sendReview({
        comingSoon: comingSoonNew,
        visible: visibleNew,
        isFree: true,
        price: 0,
        iapSku: undefined,
        promoExpirationDate: null,
        externalSeller: false,
        expendable: false,
        isOfficial: isOfficialNew,
        missionType: missionTypeNew,
      });
    } else {
      sendReview();
    }
  };

  React.useEffect(() => {
    retrieveVendingInfoAndProducts();
  }, [current]);

  return (
    <div className="card ml-4 mt-2">
      <div className="card-header">
        <h5 className="card-title">{t('screens.review.reviewSummary')}</h5>
      </div>
      <div className="card-body">
        <p className="card-text">
          <strong>{t('screens.review.problems')}</strong>
          {errors.length}
          <br />
          {errors.map((it, index) => (
            <div
              key={`error_${index}_${it.pathToItem || it.message}`}
              className="border border-danger mb-1 pt-1 pb-1 pl-1 pr-1"
            >
              <strong>{t('screens.review.errorLocation')}</strong>
              {it.pathToItem || 'N.C'}
              <br />
              <strong>{t('screens.review.error')}</strong>
              {it.message}
              <button onClick={() => removeError(it)} className="btn btn-light">
                <FontAwesomeIcon color="red" icon={['fad', 'trash-alt']} />
              </button>
            </div>
          ))}
          <br />
          <br />

          <InputSelect
            fieldName={'decision'}
            value={decision || ''}
            values={['pendingReview', 'validated', 'rejected', 'banned']}
            itemToTitle={(it) => t(`screens.review.statusList.${it}`)}
            itemToId={(it) => it}
            label={t('screens.review.decision')}
            handleChange={handleChange}
          />
        </p>
      </div>

      {decision === 'validated' && (
        <VisibilityAndVendingChanger
          scenarioId={current?.scenarioId}
          comingSoon={comingSoonNew}
          visible={visibleNew}
          missionType={missionTypeNew}
          handleChange={handleVendingVisibilityChange}
        />
      )}
      <div className="card-footer">
        <button
          className="btn btn-primary ml-2 mb-2"
          type="button"
          id="button-deploy"
          onClick={sendReviewData}
          disabled={!decision}
        >
          {t('screens.review.submit')}
        </button>
      </div>
    </div>
  );
};

export default compose(withFirebase, withTranslation('default'))(ConfirmReview);
