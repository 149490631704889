/**
 * @flow
 *
 * @format
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';

type Props = {
  onClick: (info: any) => any,
  info: any,
  locale: string,
  isActive: boolean,
  t: (key: string) => string,
};

const SubmissionListButton = (props: Props) => {
  const { t } = props;
  let buttonClass = 'list-group-item mb-3 list-group-item-action align-items-start';
  if (props.isActive) {
    buttonClass += ' active';
  }

  const { scenarioId, status, version, submissionDate, isLive } = props.info;

  if (status === 'reviewing') {
    buttonClass += ' active btn-warning';
  } else {
    buttonClass += ' btn-light';
  }

  return (
    <div className="" key={scenarioId}>
      <button id={scenarioId} className={buttonClass} onClick={() => props.onClick(props.info)}>
        <div className="d-flex justify-content-between">
          <h5 className="mb-1">{scenarioId}</h5>
          {isLive && <FontAwesomeIcon icon={['fad', 'play-circle']} color="green" />}
        </div>
        <React.Fragment>
          <h6 className="mb-1">
            {t('screens.myReleases.lastRelease')}
            {version} - {moment(submissionDate).format('DD/MM HH:mm')}
          </h6>
        </React.Fragment>
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(SubmissionListButton);
