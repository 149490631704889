/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FirebaseSingleton as firebase } from 'src/services/Firebase';
import { InputJSONArray, Loader } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

type Props = {
  locale: string,
  notify: EventsServiceHelper.addNotifType,
  t: (key: string) => string,
};

type State = {
  emails?: string,
  isLoading: boolean,
  isValid: boolean,
};

class UserCreateWidget extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {
    emails: undefined,
    isLoading: false,
    isValid: false,
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    // $FlowFixMe: Boolean can only be set for boolean fields
    const newState: $Shape<State> = { [fieldName]: value };
    if (fieldName === 'userEmail') {
      newState.recognizedEmail = undefined;
    }
    this.setState(newState, () => this.updateValidity(this.state));
  };

  createUsers = async () => {
    const { emails } = this.state;
    if (emails) {
      try {
        this.setState({ isLoading: true });
        const res = await firebase.createUsersAsync(emails.replaceAll(`"`, ''), undefined, 'fr');

        if (res.success) {
          this.setState(
            {
              emails: '',
            },
            () => this.updateValidity(this.state),
          );
          this.props.notify(NotificationTypes.SUCCESS, 'S_USERS_CREATED', res);
        } else if (res.failedUsers) {
          this.props.notify(NotificationTypes.ERROR, 'E_USERS_NOT_CREATED', res);
        }
        this.setState({ isLoading: false });
      } catch (error) {
        console.error('User does not exists', error);
        this.props.notify(NotificationTypes.ERROR, 'E_USERS_NOT_CREATED', error.message);
        this.setState({ isLoading: false });
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  updateValidity = (newVal: State) => {
    const isValid: boolean = !!newVal.emails;
    this.setState({ isValid });
  };

  render() {
    const { emails, isLoading } = this.state;
    const { t } = this.props;
    return (
      <div className="card bg-light screenBlock">
        <div className="card-body p-2 pl-4">
          <div className="list-group pb-10">
            <div className="d-flex flex-row justify-content-center">
              <div className="col-12">
                <InputJSONArray
                  withoutBrackets
                  fieldName="emails"
                  value={emails}
                  label={t('screens.admin.users.createEmailLabel')}
                  help={t('screens.admin.users.createEmailHelp')}
                  handleChange={this.handleChange}
                  prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
                />
              </div>
            </div>
            <button
              className="btn btn-outline-secondary mb-3"
              type="button"
              id="button-addon2"
              onClick={this.createUsers}
            >
              {t('general.create')}
            </button>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  notify: EventsServiceHelper.addNotif,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(UserCreateWidget);
