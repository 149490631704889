/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import csvtojson from 'csvtojson';

import 'bootstrap/dist/css/bootstrap.min.css';
import { PreferencesServiceHelper } from 'src/store/preferences';
import { Singleton as LocaleManager } from 'src/store/scenario/header/ScenarioLocaleManager';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { i18n, changeLocale } from 'src/assets/locales';
import { availableLanguagesEditor } from 'src/assets/locales/languages';
import { UserView, LanguagesSettings } from './components';

export type SettingsScreenProps = {
  setEditionLocale: PreferencesServiceHelper.setEditionLocaleType,
  availableLocales: string[],
  editionLocale: string,
  t: (key: string) => string,
  validClaims: string[],
};

type State = {
  isScreenplayValid: boolean,
  screenPlayEngine: any,
  screenPlayEngineName?: string,
  loadedScreenplay?: any,
};

class SettingsScreen extends React.PureComponent<SettingsScreenProps, State> {
  static defaultProps = {};

  reader: ?FileReader = undefined;

  state = {
    isScreenplayValid: false,
    screenPlayEngine: undefined,
    screenPlayEngineName: undefined,
    loadedScreenplay: undefined,
  };

  updateValidity(screenPlayEngine, loading) {
    this.setState({
      isScreenplayValid: !loading && !!screenPlayEngine,
    });
  }

  loadCsvFile = (event) => {
    this.updateValidity(undefined, true);
    const { files } = event.target;
    const first = files[0];
    this.setState({ screenPlayEngineName: first.name });
    if (first) {
      this.reader = new FileReader();
      this.reader.onloadend = this.handleCsvLoaded;
      this.reader.readAsText(first);
    }
  };

  handleCsvLoaded = async () => {
    if (this.reader) {
      const csv = this.reader.result;
      const json = await csvtojson().fromString(csv);
      this.setState({
        screenPlayEngine: json,
      });
      this.updateValidity(json, false);
    }
  };

  setEditionLocale = (locale) => {
    const { setEditionLocale } = this.props;
    setEditionLocale(locale);
  };

  render() {
    const { availableLocales, editionLocale, setEditionLocale, t } = this.props;
    return (
      <div className="pageContainer">
        <div className="container-fluid component-controller">
          <div className="card bg-light screenBlock mb-3">
            <div className="card-header">
              <h3 style={{ marginBottom: 0 }}>{t('screens.settings.localisation.sectionTitle')}</h3>
            </div>
            <LanguagesSettings
              availableLanguages={availableLanguagesEditor}
              currentLanguage={i18n.language}
              changeLanguage={changeLocale}
              languageTypeTitle={t('screens.settings.localisation.uiLanguage')}
              explanationSettings={t('screens.settings.localisation.interfaceExplanation')}
              idDropdown={'interfaceLanguagesDropdown'}
            />
            <LanguagesSettings
              availableLanguages={availableLocales}
              currentLanguage={editionLocale}
              changeLanguage={setEditionLocale}
              languageTypeTitle={t('screens.settings.localisation.editableLanguage')}
              explanationSettings={t('screens.settings.localisation.editingExplanation')}
              idDropdown={'editingLanguagesDropdown'}
            />
          </div>
          <UserView />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  availableLocales: LocaleManager.availableLocales,
  editionLocale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  setEditionLocale: PreferencesServiceHelper.setEditionLocale,
};

export default compose(
  withAuthorization(AuthenticatedCondition, Object.values(Claims)),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(SettingsScreen);
