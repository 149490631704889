/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { Scenario } from 'src/data';

export interface ScenarioButtonProps {
  scenario: Scenario;

  locale: string;

  firebase: Firebase;

  isSelected: boolean;

  onSelect: (id: string) => any;
}

interface State {
  url: string;
}

class ScenarioButton extends React.PureComponent<ScenarioButtonProps, State> {
  state = {
    url: '',
  };

  componentDidMount = () => {
    this.refreshImage(this.props);
  };

  componentDidUpdate = (oldProps) => {
    if ((this.props.scenario && !oldProps.scenario) || this.props.scenario.id !== oldProps.scenario.id) {
      this.refreshImage(this.props);
    }
  };

  refreshImage = async (props: ScenarioButtonProps) => {
    const { scenario } = props;
    const fileInfo: any = scenario.cover && scenario.cover.files.default;
    if (fileInfo) {
      try {
        const url = await FirebaseHelper.getScenarioEditorAssetUrlAsync(scenario.id, fileInfo.name);
        this.setState({ url });
      } catch (error) {
        // Ignore;
      }
    }
  };

  render() {
    const { url } = this.state;
    const { locale, scenario, isSelected, onSelect } = this.props;
    const id = scenario.id ? scenario.id : '??';
    const title = scenario.name ? scenario.name.valueForLocale(locale, true) : '??';
    const subtitle = scenario.subtitle ? scenario.subtitle.valueForLocale(locale, true) : '??';
    let buttonClass = 'scenarioButton btn list-group-item mb-3 list-group-item-action d-flex align-items-start';
    if (isSelected) {
      buttonClass += ' active';
    }
    const style = { borderRadius: '31px' };
    if (url && url.length) {
      style.background = `url(${url || ''}) center / cover no-repeat`;
    }
    return (
      <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={id}>
        <button id={id} className={buttonClass} onClick={() => onSelect(id)} style={style}>
          <div className="d-flex flex-column scenarioContent w-100 NasalizationFont">
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1 NasalizationFont">{title}</h5>
            </div>
            <small className="scenarioMeta">{subtitle}</small>
            <br />
            <br />
            <small className="scenarioMeta">{id}</small>
          </div>
        </button>
      </div>
    );
  }
}

export default withFirebase(ScenarioButton);
