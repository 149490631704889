/**
 * @flow
 *
 * @format
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { connect } from 'react-redux';
import { compose } from 'recompose';

type Props = {
  onClick: (info: any) => any,
  info: any,
  locale: string,
  isActive: boolean,
  t: (key: string) => string,
};

const ReleaseListButton = (props: Props) => {
  const { locale, t } = props;
  let buttonClass = 'list-group-item mb-3 list-group-item-action align-items-start';
  if (props.isActive) {
    buttonClass += ' active';
  }

  const { scenarioId, name, isLive, submissionsHistory } = props.info;

  const { last } = submissionsHistory;
  if (last?.status === 'rejected') {
    buttonClass += ' btn-danger';
  } else if (last?.status === 'pendingReview') {
    buttonClass += ' btn-warning';
  } else {
    buttonClass += ' btn-light';
  }
  // let versionString;
  // if (currentVersion && currentVersion !== lastVersion) {
  //   versionString = `${currentVersion} => ${lastVersion}`;
  //   if (!isActive) {
  //     buttonClass += ' list-group-item-warning';
  //   }
  // } else {
  //   versionString = `Latest (${lastVersion})`;
  // }
  return (
    <div className="" key={scenarioId}>
      <button id={scenarioId} className={buttonClass} onClick={() => props.onClick(props.info)}>
        <div className="d-flex justify-content-between">
          <h5 className="mb-1">{name[locale]}</h5>
          {isLive && <FontAwesomeIcon icon={['fad', 'play-circle']} color="green" />}
        </div>
        {last && (
          <React.Fragment>
            <h6 className="mb-1">
              {t('screens.myReleases.lastRelease')}
              {last.version} - {moment(last.submissionDate).format('DD/MM HH:mm')}
            </h6>
          </React.Fragment>
        )}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(ReleaseListButton);
