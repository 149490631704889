/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import { DiagramEngine, DiagramModel, DiagramWidget, MoveItemsAction } from 'storm-react-diagrams';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'storm-react-diagrams/dist/style.min.css';

import {
  Discussion,
  Message,
  Answer,
  AnswerOpened,
  MessageTriggeredItem,
  DiscussionEntryPoint,
  ENTRY_POINT_PREFIX,
  ENTRY_POINT_DEFAULT,
  ENTRY_POINT_COMPLETED,
} from 'src/data/discussions';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import { PreferencesServiceHelper } from 'src/store/preferences';
import type { GraphPreferenceType } from 'src/store/preferences/PreferencesReducer';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import type {
  AnswerOpenedNodeModelListener,
  AnswerNodeModelListener,
  MessageNodeModelListener,
  EntryPointModelListener,
  ItemLinkModelListener,
} from '../../../components/graph';

import {
  DiscussionNodeFactory,
  ItemNodeModel,
  MessageNodeModel,
  AnswerNodeModel,
  TriggerNodeModel,
  ItemLinkModel,
  ItemLinkFactory,
  ItemPortFactory,
  EntryPointModel,
  ItemNodeTypes,
} from '../../../components/graph';
import AddItemWidget from '../../../components/graph/AddItemWidget';
import '../../../components/graph/GraphView.css';
import AnswerOpenedNodeModel from '../../../components/graph/answer/AnswerOpenedNodeModel';
import AtlGraphNode from '../../../../../data/AtlGraphNode';

export type DiscussionGraphViewProps = {
  isEditingItem: boolean,
  discussion: Discussion,
  entryPoints: { [s: string]: string },
  messages: { [s: string]: Message },
  discussionId: string,
  defaultNpcId?: string,
  entryPoints: DiscussionEntryPoint[],
  messageSelected: (id: string, nodeId: string) => any,
  answerSelected: (id?: string, messageId?: string, nodeId: string) => any,
  answerOpenedSelected: (id?: string, messageId?: string, nodeId: string) => any,
  itemSelected: (id?: string, messageId?: string, nodeId: string) => any,
  addEntryPoint: (dicsussionid: string, entryPoit: DiscussionEntryPoint) => any,
  updateEntryPoint: (dicsussionid: string, entryPoit: DiscussionEntryPoint) => any,
  messageAdded: (nodeId: string) => any,
  answerAdded: (nodeId: string) => any,
  answerOpenedAdded: (nodeId: string) => any,
  triggerAdded: (nodeId: string) => any,
  setDisplayModal: (displayModal: boolean) => void,
  entryPointMoved: DiscussionServiceHelper.entryPointMovedType,
  messageNodeMoved: DiscussionServiceHelper.nodeMovedType,
  answerNodeMoved: DiscussionServiceHelper.nodeMovedType,
  answerOpenedNodeMoved: DiscussionServiceHelper.nodeMovedType,
  triggerNodeMoved: DiscussionServiceHelper.nodeMovedType,
  addMessage: DiscussionServiceHelper.updateMessageType,
  addAnswer: DiscussionServiceHelper.updateAnswerType,
  addAnswerOpened: DiscussionServiceHelper.updateAnswerType,
  addTrigger: DiscussionServiceHelper.updateTriggerType,
  deleteAnswer: DiscussionServiceHelper.deleteAnswerType,
  deleteAnswerOpened: DiscussionServiceHelper.deleteAnswerType,
  deleteTrigger: DiscussionServiceHelper.deleteTriggerType,
  linkNextMessage: DiscussionServiceHelper.linkNextMessageType,
  linkAnswer: DiscussionServiceHelper.linkAnswerType,
  linkAnswerOpened: DiscussionServiceHelper.linkAnswerType,
  linkTrigger: DiscussionServiceHelper.linkTriggerType,
  unlinkNextMessage: DiscussionServiceHelper.linkNextMessageType,
  unlinkAnswer: DiscussionServiceHelper.linkAnswerType,
  unlinkAnswerOpened: DiscussionServiceHelper.linkAnswerType,
  unlinkTrigger: DiscussionServiceHelper.linkTriggerType,
  removeItem: (itemId: string, nodeId: string) => any,
  removeAnswer: (itemId: string, nodeId: string) => any,
  removeAnswerOpened: (itemId: string, nodeId: string) => any,
  hasDetachedNodes: boolean,
  updatePrefZoom: PreferencesServiceHelper.updateGraphZoomType,
  updatePrefOffset: PreferencesServiceHelper.updateGraphOffsetType,
  detachedMessages: Message[],
  detachedAnswers: Answer[],
  detachedAnswersOpened: AnswerOpened[],
  detachedTriggers: MessageTriggeredItem[],
  preferences: GraphPreferenceType,
  setExternalEventHandler: (callback: ({ type: string, data: string }) => any) => any,
  t: (key: string) => string,
};

type State = {
  nodes: any,
  links: ItemLinkModel[],
  linksData: { in: string, out: string, link: ItemLinkModel }[],
  engine?: DiagramEngine,
  errorMessage?: string,
  isSidebarOpened: boolean,
};

const colors = {
  message: '#FCAF58',
  answer: '#5DA9E9',
  trigger: '#f2784b',
  entryPoint: '#d2d7d3',
  newLink: '#E8EDF3',
};

class DiscussionGraphView extends React.PureComponent<DiscussionGraphViewProps, State> {
  static defaultProps = {};

  state = {
    nodes: {},
    links: [],
    linksData: [],
    engine: undefined,
    errorMessage: undefined,
    isSidebarOpened: true,
  };

  modelUpdated: DiagramModel.listener;

  linkListener: ItemLinkModelListener;

  entryPointListener: EntryPointModelListener;

  messageListener: MessageNodeModelListener;

  answerListener: AnswerNodeModelListener;

  answerOpenedListener: AnswerOpenedNodeModelListener;

  itemListener: any;

  constructor(props) {
    super(props);
    this.modelUpdated = {
      linksUpdated: (evt) => {
        const { link } = evt;
        const { links } = this.state;
        if (!links.includes(link)) {
          links.push(link);
          link.addListener(this.linkListener);
          this.updateLink(link);
        }
        this.checkUnreachableNodes();
      },

      zoomUpdated: (evt) => {
        const { zoom } = evt;
        const { updatePrefZoom, discussionId } = this.props;
        if (updatePrefZoom) {
          updatePrefZoom(discussionId, zoom);
        }
      },

      offsetUpdated: (evt) => {
        const { offsetX, offsetY } = evt;
        const { updatePrefOffset, discussionId } = this.props;
        if (updatePrefOffset) {
          updatePrefOffset(discussionId, offsetX, offsetY);
        }
      },
    };

    this.linkListener = {
      sourcePortChanged: (evt) => {
        this.updateLink(evt.entity);
        this.checkUnreachableNodes();
      },
      targetPortChanged: (evt) => {
        this.updateLink(evt.entity);
        this.checkUnreachableNodes();
      },
      entityRemoved: (evt) => {
        const link = evt.entity;
        const inPort = link.getInPort();
        const outPort = link.getOutPort();
        const outNode = outPort && outPort.parent;
        const inNode = inPort && inPort.parent;

        if (outNode && inNode) {
          this.removeItemLink(outNode, inNode);
        }
      },
    };
    this.entryPointListener = {
      selectionChanged: () => {},
      entityRemoved: (evt) => {
        const item = evt.entity;
        this.removeAllLinks(item.nodeId);
      },
    };
    this.messageListener = {
      selectionChanged: (evt) => {
        const item = evt.entity.message;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        const { messageSelected } = this.props;
        if (messageSelected) {
          messageSelected(itemId, nodeId);
        }
      },
      entityRemoved: (evt) => {
        const item = evt.entity.message;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        const { removeItem } = this.props;
        if (removeItem) {
          removeItem(itemId, nodeId);
        }
        this.removeAllLinks(nodeId);
      },
    };
    this.answerListener = {
      selectionChanged: (evt) => {
        const item = evt.entity.answer;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        const messageId = item && item.parentMessageId;
        const { answerSelected } = this.props;
        if (answerSelected) {
          answerSelected(itemId, messageId, nodeId);
        }
        this.removeAllLinks(nodeId);
      },
      entityRemoved: (evt) => {
        const item = evt.entity.answer;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        const { removeAnswer, setDisplayModal } = this.props;
        setDisplayModal(false);
        if (removeAnswer) {
          removeAnswer(itemId, nodeId);
        }
      },
    };
    this.answerOpenedListener = {
      selectionChanged: (evt) => {
        const { item } = evt.entity;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        const messageId = item && item.parentMessageId;
        const { answerOpenedSelected } = this.props;
        if (answerOpenedSelected) {
          answerOpenedSelected(itemId, messageId, nodeId);
        }
      },
      entityRemoved: (evt) => {
        const { item } = evt.entity;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        const { removeAnswerOpened, setDisplayModal } = this.props;
        setDisplayModal(false);
        if (removeAnswerOpened) {
          removeAnswerOpened(itemId, nodeId);
        }
        this.removeAllLinks(nodeId);
      },
    };
    this.itemListener = {
      selectionChanged: (evt) => {
        const item = evt.entity.trigger;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        const messageId = item && item.parentMessageId;
        const { itemSelected } = this.props;
        if (itemSelected) {
          itemSelected(itemId, messageId, nodeId);
        }
      },
      entityRemoved: (evt) => {
        const item = evt.entity.trigger;
        const itemId = item && item.id;
        const nodeId = item && item.nodeId;
        console.debug(`Trigger removed ${itemId}`);
        const { removeItem } = this.props;
        if (removeItem) {
          removeItem(itemId, nodeId);
        }
        this.removeAllLinks(nodeId);
      },
    };
  }

  checkUnreachableNodes = (engineParam, nodesParam) => {
    const { discussion } = this.props;
    const nodes = nodesParam || this.state.nodes;
    const engine = engineParam || this.state.engine;
    let rootNodes = [];
    if (engine) {
      const model = engine.getDiagramModel();
      rootNodes = Object.values(model.nodes).filter((node) => node instanceof ItemNodeModel && node.isRoot === true);
    }
    if (discussion && discussion.unlockedValues) {
      const accessibleEntriesNumber = Object.values(discussion.unlockedValues);
      rootNodes = rootNodes.filter((node) => {
        if (node instanceof ItemNodeModel) {
          const id = node.name;
          if (id === ENTRY_POINT_DEFAULT) {
            return true;
          }
          if (id === ENTRY_POINT_COMPLETED) {
            // TODO : See if we should test
            return true;
          }
          const number = parseInt(id.slice(ENTRY_POINT_PREFIX.length), 10);
          return accessibleEntriesNumber.includes(number);
        }
        return false;
      });
    }
    let nodesToReach = [...Object.values(nodes)];
    const reachedNodes = [...rootNodes];
    let accessibleRoutes = [];
    if (rootNodes) {
      rootNodes.forEach((rootNode) => {
        if (rootNode instanceof ItemNodeModel) {
          accessibleRoutes = accessibleRoutes.concat(rootNode.getLinksFromNode(false));
        }
      });
    }

    let usedRoutes = [];
    if (rootNodes) {
      rootNodes.forEach((rootNode) => {
        if (rootNode instanceof ItemNodeModel) {
          nodesToReach = nodesToReach.filter(
            (node) => node && node instanceof ItemNodeModel && node.getId() !== rootNode.getId(),
          );
        }
      });
    }
    while (accessibleRoutes.length > 0 && nodesToReach.length > 0) {
      const newAccessibleNodes = [];
      // Calc all the new accessible nodes
      /* eslint-disable no-loop-func */
      accessibleRoutes.forEach((route) => {
        const inPort = route.getInPort();
        if (inPort) {
          if (!reachedNodes.includes(inPort.parent)) {
            reachedNodes.push(inPort.parent);
            nodesToReach = nodesToReach.filter(
              (node) => node && node instanceof ItemNodeModel && node.getId() !== inPort.parent.getId(),
            );
            newAccessibleNodes.push(inPort.parent);
          }
        }
      });
      /* eslint-enable no-loop-func */

      // Mark routes as used
      usedRoutes = [...usedRoutes, ...accessibleRoutes];
      accessibleRoutes = [];
      // Calc the new accessible routes
      /* eslint-disable no-loop-func */
      newAccessibleNodes.forEach((node) => {
        const routes = node.getLinksFromNode(false);
        routes.forEach((route) => {
          if (!usedRoutes.find((routeUsed) => route.id === routeUsed.id)) {
            accessibleRoutes.push(route);
          }
        });
      });
      /* eslint-enable no-loop-func */
    }

    accessibleRoutes.forEach((route) => {
      const inPort = route.getInPort();
      if (inPort) {
        if (!reachedNodes.includes(inPort.parent)) {
          reachedNodes.push(inPort.parent);
          nodesToReach = nodesToReach.filter(
            (node) => node && node instanceof ItemNodeModel && node.getId() !== inPort.parent.getId(),
          );
        }
      }
    });

    nodesToReach.forEach((node) => {
      if (node && node.setReachable) {
        /* $FlowFixMe */
        node.setReachable(false);
      }
    });
    reachedNodes.forEach((node) => {
      if (node instanceof ItemNodeModel) {
        node.setReachable(true);
      }
    });
  };

  removeAllLinks = (nodeId) => {
    const { linksData, engine } = this.state;
    const matchingLinks = linksData.filter((elem) => elem.in === nodeId || elem.out === nodeId);
    const model = engine && engine.getDiagramModel();
    if (model) {
      matchingLinks.forEach((link) => {
        this.removeItemLink(model.getNode(link.in), model.getNode(link.out));
      });
    }
  };

  updateLink = (link) => {
    const { linksData, engine } = this.state;
    const currentData = linksData.find((elem) => elem.link.id === link.id);
    let newData;

    const model = engine && engine.getDiagramModel();

    if (model) {
      const inPort = link.getInPort();
      const outPort = link.getOutPort();
      if (inPort && outPort) {
        const outNode = outPort.parent;
        const inNode = inPort.parent;
        newData = {
          out: outNode && outNode.nodeId,
          in: inNode && inNode.nodeId,
          link,
        };
        if (!currentData) {
          this.addItemLink(outNode, inNode);
        } else if (newData.in !== currentData.in || newData.out !== currentData.out) {
          this.removeItemLink(model.getNode(currentData.in), model.getNode(currentData.out));
          this.addItemLink(outNode, inNode);
        }
      } else if (currentData) {
        this.removeItemLink(model.getNode(currentData.in), model.getNode(currentData.out));
      }
    }
  };

  removeItemLink(source: ItemNodeModel<any>, target: ItemNodeModel<any>) {
    if (!source || !target) {
      return;
    }
    const sourceType = source.type;
    const targetType = target.type;
    switch (targetType) {
      case ItemNodeTypes.Message: {
        const { discussionId, unlinkNextMessage } = this.props;
        const isOpened = sourceType === ItemNodeTypes.AnswerOpened;
        let messageId;
        let answerId;
        if (sourceType === ItemNodeTypes.Message) {
          messageId = source.message.id;
        } else if (sourceType === ItemNodeTypes.EntryPoint) {
          messageId = source.entrypoint.nodeId;
        } else {
          messageId = isOpened ? source.item.parentMessageId : source.answer.parentMessageId;
          answerId = isOpened ? source.item.nodeId : source.answer.nodeId;
        }
        const nextMessageId = target.message.id;
        if (unlinkNextMessage) {
          unlinkNextMessage(discussionId, messageId, answerId, nextMessageId, isOpened);
        }
        break;
      }
      case ItemNodeTypes.Answer: {
        const { discussionId, unlinkAnswer } = this.props;
        const messageId = source.message.id;
        const answerNodeId = target.answer.nodeId;
        if (unlinkAnswer) {
          unlinkAnswer(discussionId, messageId, answerNodeId);
        }
        break;
      }
      case ItemNodeTypes.AnswerOpened: {
        const { discussionId, unlinkAnswerOpened } = this.props;
        const messageId = source.message.id;
        const answerNodeId = target.item.nodeId;
        if (unlinkAnswerOpened) {
          unlinkAnswerOpened(discussionId, messageId, answerNodeId);
        }
        break;
      }
      case ItemNodeTypes.Trigger: {
        const { discussionId, unlinkTrigger } = this.props;
        const messageId = source.message.id;
        const triggerNodeId = target.trigger.nodeId;
        if (unlinkTrigger) {
          unlinkTrigger(discussionId, messageId, triggerNodeId);
        }
        break;
      }
      default:
        break;
    }
  }

  addItemLink(
    source: ItemNodeModel<any>,
    target: ItemNodeModel<any>,
    itemId?: string,
    addDefaultAnswer?: boolean = false,
  ) {
    if (source.type === ItemNodeTypes.Message) {
      switch (target.type) {
        case ItemNodeTypes.Message: {
          const { linkNextMessage, discussionId } = this.props;
          if (linkNextMessage) {
            linkNextMessage(discussionId, source.message.id, undefined, target.message.id, false);
          }
          break;
        }
        case ItemNodeTypes.Answer: {
          const { linkAnswer, discussionId } = this.props;
          if (linkAnswer) {
            linkAnswer(discussionId, source.message.id, target.answer.nodeId);
          }
          break;
        }
        case ItemNodeTypes.AnswerOpened: {
          const { linkAnswerOpened, discussionId } = this.props;
          if (linkAnswerOpened) {
            linkAnswerOpened(discussionId, source.message.id, target.item.nodeId);
            if (
              !addDefaultAnswer &&
              !this.props.messages[source.message.id].answersOpened.filter((item) => item.isDefault).length
            ) {
              // No default answer, we add one
              this.addItemBellow(source.message, ItemNodeTypes.AnswerOpened, true);
            }
          }
          break;
        }
        default: {
          const { linkTrigger, discussionId } = this.props;
          if (linkTrigger) {
            linkTrigger(discussionId, source.message.id, target.trigger.nodeId);
          }
          break;
        }
      }
    } else if (source.type === ItemNodeTypes.Answer) {
      switch (target.type) {
        case ItemNodeTypes.Message: {
          const { linkNextMessage, discussionId } = this.props;
          linkNextMessage(
            discussionId,
            source.answer.parentMessageId,
            itemId || source.answer.id,
            target.message.id,
            false,
          );
          break;
        }
        default:
          // No link authorized!!!
          break;
      }
    } else if (source.type === ItemNodeTypes.AnswerOpened) {
      switch (target.type) {
        case ItemNodeTypes.Message: {
          const { linkNextMessage, discussionId } = this.props;
          linkNextMessage(discussionId, source.item.parentMessageId, itemId || source.item.id, target.message.id, true);
          break;
        }
        default:
          // No link authorized!!!
          break;
      }
    } else if (source.type === ItemNodeTypes.EntryPoint) {
      switch (target.type) {
        case ItemNodeTypes.Message: {
          const { updateEntryPoint, discussionId } = this.props;
          const newPoint = new DiscussionEntryPoint(source.entrypoint);
          newPoint.messageId = target.message.id;
          updateEntryPoint(discussionId, newPoint);
          break;
        }
        default:
          // No link authorized!!!
          break;
      }
    }
  }

  generateId = () =>
    Math.random()
      .toString(36)
      .slice(-8);

  componentDidMount() {
    const engine = this.loadGraph();
    this.props.setExternalEventHandler(this.handleEvent);
    this.setState({ engine });
  }

  componentWillUnmount() {
    this.props.setExternalEventHandler(null);
  }

  handleEvent = (event: { type: string, avoidDx: boolean, data: any }) => {
    switch (event.type) {
      case 'addItem':
        this.addItemBellow(event.data.item, event.data.type, false, event.data.avoidDx);
        break;
      default:
        break;
    }
  };

  loadEntryPoint(entryPoint: DiscussionEntryPoint) {
    const { discussionId } = this.props;
    const node = new EntryPointModel(discussionId, entryPoint.name, entryPoint.nodeId, entryPoint);
    if (node) {
      node.setPosition(entryPoint.pos.x, entryPoint.pos.y);
      node.addListener(this.entryPointListener);
    }
    return node;
  }

  loadNode = (
    id: string,
    type: ItemNodeModel.ItemNodeType,
    content: { [s: string]: string },
    pos: any,
    reachable: boolean = false,
    item: any,
  ) => {
    const { discussionId } = this.props;
    let listener;
    let node;

    switch (type) {
      case ItemNodeTypes.Message:
        node = new MessageNodeModel(reachable, item, discussionId, id);
        listener = this.messageListener;
        break;
      case ItemNodeTypes.Answer:
        node = new AnswerNodeModel(reachable, item, discussionId);
        listener = this.answerListener;
        break;
      case ItemNodeTypes.AnswerOpened:
        node = new AnswerOpenedNodeModel(reachable, item, discussionId);
        listener = this.answerOpenedListener;
        break;
      case ItemNodeTypes.Trigger:
        node = new TriggerNodeModel(reachable, item, discussionId);
        listener = this.itemListener;
        break;
      default:
        break;
    }
    if (node) {
      node.setPosition(pos.x, pos.y);
      node.addListener(listener);
    }
    return node;
  };

  getNextEntryPointId() {
    const { entryPoints } = this.props;
    let id = ENTRY_POINT_DEFAULT;
    if (entryPoints.find((point) => point.id === id)) {
      const existingIds = entryPoints.map((point) => {
        if (point.id === ENTRY_POINT_DEFAULT) {
          return 0;
        }
        if (point.id === ENTRY_POINT_DEFAULT) {
          return -1;
        }
        if (point.id === ENTRY_POINT_COMPLETED) {
          return -1;
        }
        return parseInt(point.id.slice(ENTRY_POINT_PREFIX.length), 10);
      });
      const max = Math.max(...existingIds);
      id = ENTRY_POINT_PREFIX + (max + 1);
    }
    return id;
  }

  // eslint-disable-next-line class-methods-use-this
  getNextMessageId(items, detachedItems, prefix) {
    let max = 0;
    items.forEach((value) => {
      const key = value instanceof Message ? value.id : '';
      const suffix = key.slice(prefix.length);
      const numberVal = parseInt(suffix, 10);
      if (!Number.isNaN(numberVal) && numberVal > max) {
        max = numberVal;
      }
    });
    detachedItems.forEach((value) => {
      const key = value.id;
      if (key) {
        const suffix = key.slice(prefix.length);
        const numberVal = parseInt(suffix, 10);
        if (!Number.isNaN(numberVal) && numberVal > max) {
          max = numberVal;
        }
      }
    });
    return prefix + (max + 1);
  }

  addItemBellow = (previousItem: any, type: string, addDefaultAnswer?: boolean = false, avoidDx?: boolean) => {
    if (previousItem) {
      const { pos } = previousItem;
      let dx = 0;
      if (!avoidDx) {
        if (previousItem.answers) {
          dx =
            previousItem.answers.length +
            previousItem.answersOpened.length +
            previousItem.triggeredItems.length +
            (previousItem.nextMessageId ? 1 : 0);
        } else {
          dx = previousItem.nextMessageId ? 1 : 0;
        }
      }

      const jsonAdditional = {};
      if (addDefaultAnswer) {
        jsonAdditional.isDefault = true;
        dx += 1;
      }

      const newPosY = pos.y + 75;
      const newPosX = pos.x + dx * 250;
      const newNode = this._addItem(
        {
          type,
          pos: { x: newPosX, y: newPosY },
          nodeId: this.generateId(),
          ...jsonAdditional,
        },
        previousItem.id,
      );
      const outNode = this.state.nodes[previousItem.nodeId];
      this.addItemLink(outNode, newNode, previousItem.id, addDefaultAnswer);

      const outPort = outNode.getOutPorts()[0];
      const inPort = newNode.getInPorts()[0];
      const link = outPort.link(inPort);
      this.state.links.push(link);
      this.state.linksData.push({
        out: outNode.nodeId,
        in: newNode.nodeId,
        link,
      });
      setTimeout(() => {
        try {
          const model = this.state.engine.getDiagramModel();
          model.addAll(link);
          this.forceUpdate();
        } catch (error) {
          console.log('Could not update graph', error);
        }
      }, 200);
    }
  };

  addItem = (event) => {
    const { engine } = this.state;
    const data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'));
    const points = engine && engine.getRelativeMousePoint(event);

    const json = {
      ...data,
      pos: points,
      nodeId: this.generateId(),
    };
    this._addItem(json);
  };

  _addItem = (json: any, prevId?: string) => {
    const { engine, nodes } = this.state;
    let node = null;

    let model;
    switch (json.type) {
      case ItemNodeTypes.Message: {
        const { messages, detachedMessages, discussionId, defaultNpcId } = this.props;
        const prefix = `${discussionId}_`;
        // eslint-disable-next-line no-param-reassign
        json.id = this.getNextMessageId(Object.values(messages), detachedMessages, prefix);
        if (defaultNpcId && !json.npcId) {
          // eslint-disable-next-line no-param-reassign
          json.npcId = defaultNpcId;
        }
        model = new Message(json);
        break;
      }
      case ItemNodeTypes.Answer: {
        const id = json.id || this.generateId();
        model = new Answer({ id, ...json });
        if (prevId) {
          model.parentMessageId = prevId;
        }
        break;
      }
      case ItemNodeTypes.AnswerOpened: {
        const id = json.id || this.generateId();
        model = new AnswerOpened({ id, ...json });
        if (prevId) {
          model.parentMessageId = prevId;
        }
        break;
      }
      case ItemNodeTypes.Trigger:
        model = new MessageTriggeredItem(json);
        if (prevId) {
          model.parentMessageId = prevId;
        }
        break;
      case ItemNodeTypes.EntryPoint:
        if (!json.name) {
          // eslint-disable-next-line no-param-reassign
          json.name = this.getNextEntryPointId();
        }
        if (!json.id) {
          // eslint-disable-next-line no-param-reassign
          json.id = json.name;
        }
        model = new DiscussionEntryPoint(json);
        break;
      default:
        break;
    }
    if (!model) {
      return undefined;
    }
    if (model instanceof DiscussionEntryPoint) {
      node = this.loadEntryPoint(model);
    } else {
      node = this.loadNode(json.nodeId, json.type, json.content, json.pos, false, model);
    }
    nodes[json.nodeId] = node;
    const {
      discussionId,
      addMessage,
      addAnswer,
      addAnswerOpened,
      addTrigger,
      messageAdded,
      answerAdded,
      answerOpenedAdded,
      addEntryPoint,
      triggerAdded,
    } = this.props;
    switch (json.type) {
      case ItemNodeTypes.Message:
        if (addMessage) {
          addMessage(discussionId, model.id, model);
        }
        if (messageAdded) {
          messageAdded(json.nodeId);
        }
        break;
      case ItemNodeTypes.Answer:
        if (addAnswer) {
          addAnswer(discussionId, model.parentMessageId, model);
        }
        if (answerAdded) {
          answerAdded(json.nodeId, model.parentMessageId);
        }
        break;
      case ItemNodeTypes.AnswerOpened:
        if (addAnswerOpened) {
          addAnswerOpened(discussionId, model.parentMessageId, model);
        }
        if (answerOpenedAdded) {
          answerOpenedAdded(json.nodeId, model.parentMessageId);
        }
        break;
      case ItemNodeTypes.EntryPoint:
        if (addEntryPoint && model instanceof DiscussionEntryPoint) {
          addEntryPoint(discussionId, model);
        }
        break;
      default:
        if (addTrigger) {
          addTrigger(discussionId, model.parentMessageId, model);
        }
        if (triggerAdded) {
          triggerAdded(json.nodeId, model.parentMessageId);
        }
        break;
    }
    if (engine) {
      engine.getDiagramModel().addNode(node);
    }
    this.forceUpdate();
    return node;
  };

  loadDetachedNodes = (list: $Subtype<AtlGraphNode<any>>[], nodes) => {
    if (list) {
      list.forEach((element) => {
        if (element && element.nodeId) {
          const node = this.loadNode(element.nodeId, element.type, element.content, element.pos, false, element);
          // eslint-disable-next-line no-param-reassign
          nodes[element.nodeId] = node;
        }
      });
    }
  };

  loadGraph = () => {
    const engine = new DiagramEngine();
    engine.installDefaultFactories();
    const messageNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.Message);
    const entryPointNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.EntryPoint);
    const answerNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.Answer);
    const answerOpenedNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.AnswerOpened);
    const triggerNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.Trigger);

    const linkFactory = new ItemLinkFactory();
    const portFactory = new ItemPortFactory();

    engine.registerNodeFactory(messageNodeFactory);
    engine.registerNodeFactory(answerNodeFactory);
    engine.registerNodeFactory(answerOpenedNodeFactory);
    engine.registerNodeFactory(triggerNodeFactory);
    engine.registerNodeFactory(entryPointNodeFactory);

    engine.registerLinkFactory(linkFactory);
    engine.registerPortFactory(portFactory);

    // setup the diagram model
    const model = new DiagramModel();

    // create four nodes
    const {
      messages,
      entryPoints,
      detachedAnswers,
      detachedAnswersOpened,
      detachedMessages,
      detachedTriggers,
    } = this.props;
    const nodes = {};
    const messageNodes = {};
    const linksData = [];
    const links = [];
    if (entryPoints) {
      Object.values(entryPoints).forEach((element) => {
        if (element instanceof DiscussionEntryPoint) {
          const node = this.loadEntryPoint(element);
          nodes[element.nodeId] = node;
        }
      });
    }

    this.loadDetachedNodes(detachedMessages, nodes);
    this.loadDetachedNodes(detachedAnswers, nodes);
    this.loadDetachedNodes(detachedAnswersOpened, nodes);
    this.loadDetachedNodes(detachedTriggers, nodes);

    if (messages) {
      Object.values(messages).forEach((element) => {
        if (element && element instanceof Message && element.id) {
          const node = this.loadNode(element.id, element.type, element.content, element.pos, false, element);
          nodes[element.nodeId] = node;
          messageNodes[element.id] = node;
          const portMessage = nodes[element.nodeId].getOutPorts()[0];
          if (element.type === ItemNodeTypes.Message) {
            if (element.answers) {
              element.answers.forEach((answer) => {
                const answerNode = this.loadNode(answer.nodeId, answer.type, answer.content, answer.pos, false, answer);
                nodes[answer.nodeId] = answerNode;
                const portAnswer = answerNode && answerNode.getInPorts()[0];
                const link = portMessage.link(portAnswer);
                link.addListener(this.linkListener);
                link.setColor(colors.answer);
                linksData.push({
                  out: element.nodeId,
                  in: answer.nodeId,
                  link,
                });
                links.push(link);
              });
            }
            if (element.answersOpened) {
              element.answersOpened.forEach((answer) => {
                const answerNode = this.loadNode(answer.nodeId, answer.type, answer.content, answer.pos, false, answer);
                nodes[answer.nodeId] = answerNode;
                const portAnswer = answerNode && answerNode.getInPorts()[0];
                const link = portMessage.link(portAnswer);
                link.addListener(this.linkListener);
                link.setColor(colors.answer);
                linksData.push({
                  out: element.nodeId,
                  in: answer.nodeId,
                  link,
                });
                links.push(link);
              });
            }
            if (element.triggeredItems) {
              element.triggeredItems.forEach((element) => {
                const node = this.loadNode(element.nodeId, element.type, element.content, element.pos, false, element);
                nodes[element.nodeId] = node;
              });
            }
          }
        }
      });

      model.addAll(...Object.values(nodes));

      Object.values(messages).forEach((element) => {
        if (element instanceof Message) {
          const portMessage = nodes[element.nodeId].getOutPorts()[0];
          if (element.triggeredItems) {
            element.triggeredItems.forEach((item) => {
              const triggeredNode = nodes[item.nodeId];
              if (triggeredNode) {
                const portTriggered = triggeredNode.getInPorts()[0];
                const link = portMessage.link(portTriggered);
                link.addListener(this.linkListener);
                link.setColor(colors.trigger);
                linksData.push({ out: element.nodeId, in: item.nodeId, link });
                links.push(link);
              }
            });
          }
          if (element.nextMessageId) {
            const triggeredNode = messageNodes[element.nextMessageId];
            if (triggeredNode) {
              const portTriggered = triggeredNode.getInPorts()[0];
              const link = portMessage.link(portTriggered);
              link.addListener(this.linkListener);
              link.setColor(colors.message);
              linksData.push({
                out: element.nodeId,
                in: triggeredNode.nodeId,
                link,
              });
              links.push(link);
            } else {
              // Didn't exist! We remove it
              // eslint-disable-next-line no-param-reassign
              delete element.nextMessageId;
            }
          }
          if (element.answers) {
            element.answers.forEach((answer) => {
              if (answer.nextMessageId) {
                const AnswerNode = nodes[answer.nodeId];
                const portAnswer = AnswerNode.getOutPorts()[0];
                const triggeredNode = messageNodes[answer.nextMessageId];
                if (triggeredNode) {
                  const portTriggered = triggeredNode.getInPorts()[0];
                  const link = portAnswer.link(portTriggered);
                  link.addListener(this.linkListener);
                  link.setColor(colors.message);
                  linksData.push({
                    out: answer.nodeId,
                    in: triggeredNode.nodeId,
                    link,
                  });
                  links.push(link);
                } else {
                  // eslint-disable-next-line no-param-reassign
                  delete answer.nextMessageId;
                }
              }
            });
          }
          if (element.answersOpened) {
            element.answersOpened.forEach((answer) => {
              if (answer.nextMessageId) {
                const AnswerNode = nodes[answer.nodeId];
                const portAnswer = AnswerNode.getOutPorts()[0];
                const triggeredNode = messageNodes[answer.nextMessageId];
                if (triggeredNode) {
                  const portTriggered = triggeredNode.getInPorts()[0];
                  const link = portAnswer.link(portTriggered);
                  link.addListener(this.linkListener);
                  link.setColor(colors.message);
                  linksData.push({
                    out: answer.nodeId,
                    in: triggeredNode.nodeId,
                    link,
                  });
                  links.push(link);
                } else {
                  // eslint-disable-next-line no-param-reassign
                  delete answer.nextMessageId;
                }
              }
            });
          }
        }
      });
    }

    if (entryPoints) {
      Object.values(entryPoints).forEach((element) => {
        if (element instanceof DiscussionEntryPoint && element.messageId) {
          const entryPointNode = nodes[element.nodeId];
          const entryPointPort = entryPointNode.getOutPorts()[0];
          const triggeredNode = messageNodes[element.messageId];
          const portTriggered = triggeredNode.getInPorts()[0];
          const link = entryPointPort.link(portTriggered);
          link.addListener(this.linkListener);
          link.setColor(colors.message);
          linksData.push({
            out: element.nodeId,
            in: triggeredNode.nodeId,
            link,
          });
          links.push(link);
        }
      });
    }

    // add all to the main model
    model.addAll(...links);

    const { preferences } = this.props;
    if (preferences) {
      if (preferences.zoom) {
        model.setZoomLevel(preferences.zoom);
      }
      if (preferences.x) {
        model.setOffset(preferences.x, preferences.y);
      }
    }
    model.addListener(this.modelUpdated);

    // load model into engine and render
    engine.setDiagramModel(model);

    this.setState({
      nodes,
      linksData,
      links,
    });
    this.checkUnreachableNodes(engine, nodes);
    return engine;
  };

  actionFinished = (action) => {
    if (action instanceof MoveItemsAction && action.selectionModels) {
      action.selectionModels.forEach((item) => {
        const { model } = item;
        if (model instanceof MessageNodeModel) {
          const newPos = { x: model.x, y: model.y };
          const { message } = model;
          const { messageNodeMoved, discussionId } = this.props;
          if (messageNodeMoved) {
            messageNodeMoved(discussionId, message.id, message.nodeId, newPos);
          }
        } else if (model instanceof AnswerNodeModel) {
          const newPos = { x: model.x, y: model.y };
          const { answer } = model;
          const { answerNodeMoved, discussionId } = this.props;
          if (answerNodeMoved) {
            answerNodeMoved(discussionId, answer.parentMessageId, answer.nodeId, newPos);
          }
        } else if (model instanceof AnswerOpenedNodeModel) {
          const newPos = { x: model.x, y: model.y };
          const { item } = model;
          const { answerOpenedNodeMoved, discussionId } = this.props;
          if (answerOpenedNodeMoved) {
            answerOpenedNodeMoved(discussionId, item.parentMessageId, item.nodeId, newPos);
          }
        } else if (model instanceof TriggerNodeModel) {
          const newPos = { x: model.x, y: model.y };
          const { trigger } = model;
          const { triggerNodeMoved, discussionId } = this.props;
          if (triggerNodeMoved) {
            triggerNodeMoved(discussionId, trigger.parentMessageId, trigger.nodeId, newPos);
          }
        } else if (model instanceof EntryPointModel) {
          const newPos = { x: model.x, y: model.y };
          const { entrypoint } = model;
          const { entryPointMoved, discussionId } = this.props;
          if (entryPointMoved) {
            entryPointMoved(discussionId, entrypoint.name, newPos);
          }
        }
      });
    }
    return true;
  };

  zoomToFit = () => {
    const { engine } = this.state;
    if (engine) {
      engine.zoomToFit();
    }
  };

  renderDraggableWidgets = () => {
    const { isSidebarOpened } = this.state;
    return (
      <div
        className="widget-container"
        role="toolbar"
        aria-label="Draggable new items"
        style={{
          width: isSidebarOpened ? undefined : '40%',
          paddingLeft: '5px',
        }}
      >
        <div className={`${isSidebarOpened ? 'text-right' : 'text-center mb-2'} w-100`}>
          <span
            className="bg-transparent p-0 font-weight-bold"
            style={{ fontSize: '1.5rem', cursor: 'pointer' }}
            onClick={() => this.setState({ isSidebarOpened: !isSidebarOpened })}
          >
            {isSidebarOpened ? '‹‹' : '››'}
          </span>
        </div>

        {isSidebarOpened && <span className="category">{this.props.t('screens.discussionEdition.menu.npc')}</span>}
        <AddItemWidget
          type={ItemNodeTypes.Message}
          draggable={true}
          label={this.props.t('screens.discussionEdition.menu.npc_message')}
          isSidebarOpened={isSidebarOpened}
        />

        {isSidebarOpened && <span className="category">{this.props.t('screens.discussionEdition.menu.player')}</span>}
        <AddItemWidget
          type={ItemNodeTypes.Answer}
          draggable={true}
          label={this.props.t('screens.discussionEdition.menu.player_answer')}
          isSidebarOpened={isSidebarOpened}
        />
        <AddItemWidget
          type={ItemNodeTypes.AnswerOpened}
          draggable={true}
          label={this.props.t('screens.discussionEdition.menu.player_open_answer')}
          isSidebarOpened={isSidebarOpened}
        />

        {isSidebarOpened && <span className="category">{this.props.t('screens.discussionEdition.menu.system')}</span>}
        <AddItemWidget
          type={ItemNodeTypes.Trigger}
          draggable={true}
          label={this.props.t('screens.discussionEdition.menu.trigger')}
          isSidebarOpened={isSidebarOpened}
        />
        <AddItemWidget
          type={ItemNodeTypes.EntryPoint}
          draggable={true}
          label={this.props.t('screens.discussionEdition.menu.entrypoint')}
          isSidebarOpened={isSidebarOpened}
        />
        <AddItemWidget
          draggable={true}
          type={ItemNodeTypes.EntryPoint}
          label={this.props.t('screens.discussionEdition.menu.entrypoint_done')}
          isSidebarOpened={isSidebarOpened}
          defaultContent={{
            type: 'entryPoint',
            name: ENTRY_POINT_COMPLETED,
            nodeId: 'entryPointCompleted',
          }}
        />
        {/* }
      <button className={'ml-auto btn-secondary'} onClick={this.zoomToFit}>
        Zoom
      </button>
      <HelpButton
        helpStrings={this.props.t('helpStrings:scenario.discussion.graph', { returnObjects: true })}
        id="scenarioGraph"
        title="Graph help"
        containerClassName="z"
      />
      */}
      </div>
    );
  };

  render() {
    const { engine } = this.state;
    const { hasDetachedNodes, t } = this.props;
    return (
      <div className="fullHeightGraph">
        {hasDetachedNodes && (
          <div className="alert alert-warning mb-0 graph-alert" role="alert">
            {t('general.inccessibleElements')}
          </div>
        )}
        <div className="diagram-column">
          <div id="widgets">{this.renderDraggableWidgets()}</div>
          {engine && (
            <div
              className="fill graphContainer"
              onDrop={this.addItem}
              onDragOver={(event) => {
                event.preventDefault();
              }}
            >
              <DiagramWidget
                className="fill diagram-view"
                diagramEngine={engine}
                actionStoppedFiring={this.actionFinished}
                deleteKeys={this.props.isEditingItem ? [] : [46]}
                allowLooseLinks={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId } = ownProps;
  const discussion = discussionId && state.scenario.items[discussionId];
  const detachedElement = discussion && discussion.__detachedNodes;
  const detachedMessages = detachedElement && detachedElement.messages;
  const detachedAnswers = detachedElement && detachedElement.answers;
  const detachedAnswersOpened = detachedElement && detachedElement.answersOpened;
  const detachedTriggers = detachedElement && detachedElement.triggers;
  const defaultNpcId = discussion ? discussion.getDefaultNpcId() : undefined;
  return {
    defaultNpcId,
    preferences: state.preferences.graphPreferences[discussionId] || {},
    discussion,
    messages: discussion && discussion.messages,
    entryPoints: discussion && Object.values(discussion.entryPoints),
    triggeredItems: [],
    detachedAnswers,
    detachedAnswersOpened,
    detachedMessages,
    detachedTriggers,
    hasDetachedNodes:
      discussion &&
      (detachedMessages.length !== 0 ||
        detachedAnswers.length !== 0 ||
        detachedAnswersOpened.length !== 0 ||
        detachedTriggers.length !== 0),
  };
};

const mapDispatchToProps = {
  updatePrefOffset: PreferencesServiceHelper.updateGraphOffset,
  updatePrefZoom: PreferencesServiceHelper.updateGraphZoom,
  updateEntryPoint: DiscussionServiceHelper.updateFirstMessageId,
  addEntryPoint: DiscussionServiceHelper.addFirstMessageId,
  addMessage: DiscussionServiceHelper.addMessageAsync,
  addAnswer: DiscussionServiceHelper.addAnswerAsync,
  addAnswerOpened: DiscussionServiceHelper.addAnswerOpenedAsync,
  addTrigger: DiscussionServiceHelper.addTriggerAsync,
  entryPointMoved: DiscussionServiceHelper.entryPointMoved,
  messageNodeMoved: DiscussionServiceHelper.messageNodeMoved,
  answerNodeMoved: DiscussionServiceHelper.answerNodeMoved,
  answerOpenedNodeMoved: DiscussionServiceHelper.answerOpenedNodeMoved,
  triggerNodeMoved: DiscussionServiceHelper.triggerNodeMoved,
  linkNextMessage: DiscussionServiceHelper.linkNextMessageAsync,
  linkAnswer: DiscussionServiceHelper.linkAnswerAsync,
  linkAnswerOpened: DiscussionServiceHelper.linkAnswerOpenedAsync,
  linkTrigger: DiscussionServiceHelper.linkTriggerAsync,
  unlinkNextMessage: DiscussionServiceHelper.unlinkNextMessageAsync,
  unlinkAnswer: DiscussionServiceHelper.unlinkAnswerAsync,
  unlinkAnswerOpened: DiscussionServiceHelper.unlinkAnswerOpenedAsync,
  unlinkTrigger: DiscussionServiceHelper.unlinkTriggerAsync,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['default', 'helpStrings']),
)(DiscussionGraphView);
