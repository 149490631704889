/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import type { DocumentItemLock } from 'src/data/DocumentItem';

import { withTranslation } from 'react-i18next';
import HelpButton from '../HelpButton';
import InputString from './InputString';
import InputNumber from './InputNumber';

export interface InputLocksProps {
  fieldName: string;
  value: DocumentItemLock[];
  label: string;
  help?: ?string;
  disabled?: boolean;
  hidden?: boolean;
  helpInfos: any;
  t: (key: string) => string;
  handleChange: (event: { target: { id: string, value: DocumentItemLock[] } }) => any;
  accept?: string;
  separatorBefore?: boolean;
  inputTypes: { id: string, name: string }[];
  onFocus?: () => any;
  inputStyle?: ?any;
}

class InputLocks extends React.Component<InputLocksProps> {
  static defaultProps = {
    help: undefined,
    disabled: false,
    hidden: false,
    helpInfos: undefined,
    separatorBefore: false,
    inputTypes: [
      {
        id: 'N',
        name: 'screens.scenarioEdition.baseItemEdition.inputTypes.N',
      },
      {
        id: 'CL',
        name: 'screens.scenarioEdition.baseItemEdition.inputTypes.CL',
      },
      {
        id: 'CT',
        name: 'screens.scenarioEdition.baseItemEdition.inputTypes.CT',
      },
    ],
    inputStyle: {},
  };

  addLock = () => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: DocumentItemLock[] = [...value, { numberOfChar: 2, inputType: '', solution: '' }];
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  removeLock = (index: number) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: DocumentItemLock[] = [...value];
    newVal.splice(index, 1);
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  handSolutionChange = (index: number, solution: string) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: DocumentItemLock[] = [...value];
    newVal[index] = { ...value[index] };
    newVal[index].solution = solution;
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  handCharNumberChange = (index: number, numberOfChar: number) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: DocumentItemLock[] = [...value];
    newVal[index] = { ...value[index] };
    newVal[index].numberOfChar = parseInt(numberOfChar, 10);
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  handInputTypeChange = (index: number, inputType: string) => {
    const { value, handleChange, fieldName } = this.props;
    const newVal: DocumentItemLock[] = [...value];
    newVal[index] = { ...value[index] };
    newVal[index].inputType = inputType;
    if (handleChange) {
      handleChange({ target: { id: fieldName, value: newVal } });
    }
  };

  renderSelectField = (
    fieldName: string,
    value?: string,
    label: string,
    values: { id: string, name: string }[],
    help?: ?string = undefined,
    disabled: boolean = false,
    index: number,
  ) => (
    <div className="input-group" key={fieldName}>
      <label className="input-group-prepend mr-2 text-capitalize strong" htmlFor={fieldName}>
        {label}
      </label>
      <select
        className="form-control"
        id={fieldName}
        onChange={(event) => this.handInputTypeChange(index, event.target.value)}
        value={value}
        disabled={disabled}
        aria-describedby={`${fieldName}Help`}
        placeholder={''}
        style={this.props.inputStyle}
      >
        <option value={''}>{''}</option>
        {values &&
          values.map((element) => (
            <option key={element.id} value={element.id}>
              {this.props.t(element.name)}
            </option>
          ))}
      </select>
      {help && (
        <small id={`${fieldName}Help`} className="form-text text-muted">
          {help}
        </small>
      )}
    </div>
  );

  render() {
    const { fieldName, value, label, help, disabled, hidden, helpInfos, separatorBefore, onFocus, t } = this.props;
    return (
      <div className="form-group lock" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label htmlFor={fieldName} className="strong text-capitalize">
          {`${label} `}
          {helpInfos &&
            helpInfos.map((helpInfo, index) => (
              <HelpButton
                key={fieldName + index}
                helpStrings={helpInfo.content}
                id={fieldName}
                title={helpInfo.title}
                label={helpInfo.btnLabel}
                display={'inline'}
                className={'ml-auto btn btn-outline-warning ml-2'}
              />
            ))}
        </label>
        <button type="button" className="btn btn-primary btn-sm ml-2 mb-1 add" onClick={this.addLock}>
          {t('general.add')}
        </button>
        {value &&
          value.map((val, index) => (
            <div className="card pt-0" key={`${fieldName}_${index}`}>
              <div className="card-body mt-0">
                {this.renderSelectField(
                  'inputType',
                  val.inputType,
                  t('general.lockInputType'),
                  this.props.inputTypes,
                  undefined,
                  disabled,
                  index,
                )}
                <InputNumber
                  fieldName="numberOfChar"
                  value={val.numberOfChar}
                  label={t('general.lockNumberOfChar')}
                  disabled={disabled}
                  inlineTitle
                  onFocus={onFocus}
                  handleChange={(event) => this.handCharNumberChange(index, event.target.value)}
                  inputStyle={this.props.inputStyle}
                />

                <InputString
                  fieldName="solution"
                  value={val.solution}
                  label={t('general.lockSolution')}
                  disabled={disabled}
                  inlineTitle
                  onFocus={onFocus}
                  handleChange={(event) => this.handSolutionChange(index, event.target.value)}
                  inputStyle={this.props.inputStyle}
                />
                <button type="button" className="btn btn-primary btn-sm delete" onClick={() => this.removeLock(index)}>
                  {t('general.delete')}
                </button>
              </div>
            </div>
          ))}
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
export default withTranslation('default')(InputLocks);
