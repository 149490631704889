/**
 * @flow
 * @format
 */
import React from 'react';

import { InputBoolean } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { missionTypes, type MissionType } from 'src/data';
import { SwitchMissionType } from '../../scenario/header/components';

type Props = {
  comingSoon: boolean,
  visible: boolean,
  cardStyle: ?boolean,
  missionType: MissionType,
  handleChange: (event: { target: { id: string, value: boolean } }) => void,
  t: (key: string) => string,
};

const VisibilityAndVendingChanger = ({ comingSoon, visible, cardStyle, missionType, handleChange, t }: Props) => {
  const handleMissionType = (event, isOfficial: boolean) => {
    const updateOfficial = {
      target: {
        id: 'isOfficial',
        value: isOfficial,
      },
    };
    handleChange(updateOfficial);
    if (event) {
      const updateMissionType = {
        target: {
          id: 'missionType',
          value: event.target.value,
        },
      };
      handleChange(updateMissionType);
    }
  };

  return (
    <div className={`${cardStyle ? '' : 'pr-2 pl-2'}`}>
      <div className={`${cardStyle ? 'card' : ''} p-2 mb-2`} style={{ display: 'block' }}>
        <label className="font-weight-bold text-capitalize">{t('screens.admin.releaseInfo.scenarioVisibility')}</label>
        <InputBoolean
          fieldName="comingSoon"
          value={comingSoon}
          label={t('screens.admin.releaseInfo.comingSoonLabel')}
          help={t('screens.admin.releaseInfo.comingSoonHelp')}
          handleChange={handleChange}
        />
        <InputBoolean
          fieldName="visible"
          value={visible}
          label={t('screens.admin.releaseInfo.visibleLabel')}
          help={t('screens.admin.releaseInfo.visibleHelp')}
          handleChange={handleChange}
        />
      </div>
      <div className={`${cardStyle ? 'card' : ''} p-2 mb-2`} style={{ display: 'block' }}>
        <label className="font-weight-bold text-capitalize">
          {t('screens.headerEdition.identification.missionTypeLabel')}
        </label>
        <SwitchMissionType
          nameSwitch={missionTypes.isOfficial}
          missionType={missionType}
          label={t('screens.headerEdition.identification.isOfficialLabel')}
          handleChange={(event) => handleMissionType(event, true)}
        />
        <SwitchMissionType
          nameSwitch={missionTypes.isPrivate}
          missionType={missionType}
          label={t('screens.headerEdition.identification.isPrivateLabel')}
          handleChange={(event) => handleMissionType(event, false)}
        />
      </div>
    </div>
  );
};

export default compose(withTranslation('default'))(VisibilityAndVendingChanger);
