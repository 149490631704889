/**
 * @flow
 *
 * @format
 */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import Firebase, { withFirebase } from 'src/services/Firebase';
import { ConfigurationServiceHelper } from 'src/store/configuration';
import '../style.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ItemTypesType, POIItem, ItemTypes, BaseItem } from 'src/data';
import { RoomServiceHelper } from 'src/store/room';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'redux';
import { MapEditionWidget, DefaultItemInput, RoomMembersView } from 'src/pages/components';
import { TriggeredItemInput } from './components';
import ScenarioGraphView from './ScenarioGraphView';
import withEditionSearch from '../withEditionSearch';

export type ScenarioItemsEditionScreenProps = {
  items: { [id: string]: BaseItem },
  roomMembers: any[],
  setRoomItem: RoomServiceHelper.dispatchSetRoomItemType,
  updateEditionPreferences: ConfigurationServiceHelper.loadServerConfigType,
  scenarioId: string,
  firebase: Firebase,
  enableFilter: (string, Function) => any,
  disableFilter: (string) => any,
  t: (key: string) => string,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

let graphHandler;

const ScenarioItemsEditionScreen = (props: ScenarioItemsEditionScreenProps) => {
  const [editionMode, setEditionMode] = useState(undefined);
  const [currentItemId, setCurrentItemId] = useState(undefined);
  const [currentNodeId, setCurrentNodeId] = useState(undefined);
  const [currentMarker, setCurrentMarker] = useState(undefined);
  const [currentPolygon, setCurrentPolygon] = useState(undefined);
  const [currentPolyline, setCurrentPolyline] = useState(undefined);
  const [leftColumnClass] = useState('col-12');
  const [parentId, setParentId] = useState(undefined);
  const [childId, setChildId] = useState(undefined);
  const [editingItem, setEditingItem] = useState(undefined);
  const [forcedItemToFocus, setForcedItemToFocus] = useState(undefined);
  const [trackedUser, setTrackedUser] = useState(undefined);
  const [displayModal, setDisplayModal] = useState(false);

  let userTracker;
  useEffect(() => {
    props.setRoomItem(currentItemId, currentNodeId);
  }, [currentItemId, currentNodeId]);
  useEffect(() => {
    if (trackedUser) {
      userTracker = props.firebase
        .room(props.scenarioId)
        .child(trackedUser)
        .on('value', (snapshot) => {
          const data = snapshot.val();
          if (data && data.currentItemId) {
            const item = props.items[data.currentItemId];
            setForcedItemToFocus(item);
          }
        });
    }
  }, [trackedUser]);

  const prevFirebase = usePrevious(props.firebase);
  useEffect(() => {
    if (!prevFirebase && props.firebase) {
      const { firebase, updateEditionPreferences } = props;
      updateEditionPreferences(firebase);
    }
  }, [props.firebase]);

  useEffect(
    () =>
      function cleanup() {
        if (userTracker) {
          userTracker.off('value', this.roomListener);
        }
      },
    [],
  );

  const itemSelected = (id?: string, nodeId: string, itemType: ItemTypesType) => {
    setEditingItem(false);
    setEditionMode(itemType);
    setCurrentItemId(id);
    setCurrentNodeId(nodeId);
    setCurrentMarker(undefined);
    setCurrentPolygon(undefined);
    setCurrentPolyline(undefined);
    setParentId(undefined);
    setChildId(undefined);
    setDisplayModal(true);
  };

  const handleItem = (item) => {
    setForcedItemToFocus(item);
    itemSelected(item.id, item.nodeId, item.type);
  };

  useEffect(() => {
    props.enableFilter('itemsEditionScreen', handleItem);

    return function cleanup() {
      props.disableFilter('itemsEditionScreen');
    };
  }, []);

  // eslint-disable-next-line no-unused-vars
  const itemDeleted = (id?: string, nodeId: string) => {
    setEditingItem(false);
    setEditionMode(undefined);
    setCurrentItemId(undefined);
    setCurrentNodeId(undefined);
    setDisplayModal(false);
  };

  const triggerSelected = (parentId: string, childId: string, nodeId: string) => {
    setEditingItem(false);
    setEditionMode('TriggeredItem');
    setCurrentItemId(undefined);
    setCurrentNodeId(nodeId);
    setParentId(parentId);
    setChildId(childId);
    setDisplayModal(true);
  };

  const polygonChanged = (id, polygon) => {
    setCurrentPolygon(polygon);
  };

  const polylineChanged = (id, polyline) => {
    setCurrentPolyline(polyline);
  };

  const markerChanged = (id, point) => {
    setCurrentMarker(point);
  };

  const onMarkerDragged = (id: string, lat: number, lng: number, alt?: number) => {
    const pos: number[] = [lat, lng];
    if (alt !== undefined) {
      pos.push(alt);
    }
    const item = props.items[id];
    setCurrentItemId(id);
    setEditionMode(item.type);
    setCurrentNodeId(item.nodeId);
    setCurrentMarker(pos);
  };

  const onStartEditingItem = () => {
    setEditingItem(true);
  };

  const onMemberClic = (data) => {
    if (data.meta) {
      const itemId = data.meta.currentItemId;
      const { sessionId } = data;
      if (sessionId === trackedUser) {
        setTrackedUser(undefined);
      } else {
        if (itemId) {
          const item = props.items[itemId];
          setForcedItemToFocus(item);
        }
        setTrackedUser(sessionId);
      }
    }
  };

  const attachGraphEventHandler = (handler) => {
    graphHandler = handler || graphHandler;
  };

  const createPOIItem = (dataSource: any, dataTarget: any, type: string) => {
    if (graphHandler) {
      graphHandler({ type: 'addPOIItem', data: { itemSource: dataSource, itemTarget: dataTarget, type } });
    }
  };

  const removePOIItem = (item: any) => {
    if (graphHandler) {
      graphHandler({ type: 'removePOIItem', data: { itemSource: item } });
    }
  };

  const renderItem = () => {
    if (editionMode === 'TriggeredItem') {
      return (
        <TriggeredItemInput
          parentId={parentId}
          childId={childId}
          nodeId={currentNodeId}
          startEditing={onStartEditingItem}
          isEditingItem={editingItem}
          disabled={!!trackedUser}
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
        />
      );
    }

    if (editionMode) {
      return (
        <DefaultItemInput
          itemId={currentItemId}
          nodeId={currentNodeId}
          marker={currentMarker}
          polygon={currentPolygon}
          polyline={currentPolyline}
          startEditing={onStartEditingItem}
          isEditingItem={editingItem}
          disabled={!!trackedUser}
          displayModal={displayModal}
          setDisplayModal={setDisplayModal}
          createPOIItem={createPOIItem}
          removePOIItem={removePOIItem}
        />
      );
    }
    return undefined;
  };

  const { items, t } = props;
  return (
    <div className="pageContainer" id="scenario-edition">
      <RoomMembersView
        noTitle
        roomMembers={props.roomMembers}
        onMemberClick={onMemberClic}
        trackedUser={trackedUser}
        showTracked
      />
      <div className="row">
        <div className="col">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active  btn"
                id="home-tab"
                data-toggle="tab"
                data-target="#graph"
                href={'#graph'}
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <FontAwesomeIcon icon={['far', 'sitemap']} />
                {t('screens.scenarioEdition.structure.graph')}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link btn"
                id="profile-tab"
                data-toggle="tab"
                href="#map"
                data-target="#map"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                <FontAwesomeIcon icon={['fad', 'map-marked']} />
                {t('screens.scenarioEdition.structure.map')}
              </a>
            </li>

            <div className="right-side-bar"></div>
          </ul>
        </div>
      </div>
      <div className="row mr-0" id="graph-zone-container">
        <div className={leftColumnClass} id="leftColumn">
          <div className="container-fluid fill background-primary" id="graphContainer">
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="graph" role="tabpanel" aria-labelledby="home-tab">
                <ScenarioGraphView
                  createPOIItem={createPOIItem}
                  setExternalEventHandler={attachGraphEventHandler}
                  itemSelected={itemSelected}
                  itemDeleted={itemDeleted}
                  forcedItemToFocus={forcedItemToFocus}
                  triggerSelected={triggerSelected}
                  isEditingItem={editingItem}
                />
              </div>
              <div className="tab-pane fade" id="map" role="tabpanel" aria-labelledby="profile-tab">
                <MapEditionWidget
                  currentItemId={currentItemId}
                  itemClicked={itemSelected}
                  markerChanged={markerChanged}
                  onMarkerDragged={onMarkerDragged}
                  polygonChanged={polygonChanged}
                  polylineChanged={polylineChanged}
                  canDrawPolyline
                  itemsMap={items}
                  markerFilter={(it) => it instanceof BaseItem && it.type === ItemTypes.POI}
                  polygonFilter={(it) => it instanceof BaseItem && it.type === ItemTypes.GameArea}
                  polylineFilter={(it) => it instanceof POIItem && it.patrolCoordinates?.length}
                />
              </div>
              {displayModal && (
                <div
                  id="itemModal"
                  style={{
                    position: 'absolute',
                    right: '2%',
                    top: '5%',
                    height: '90%',
                    width: '375px',
                    borderRadius: '15px',
                    boxShadow: '0pt 12pt 66pt #00000029',
                    backgroundColor: 'white',
                  }}
                >
                  {renderItem()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  items: state.scenario.items,
  scenarioId: state.scenario.header.id,
});

const mapDispatchToProps = {
  setRoomItem: RoomServiceHelper.dispatchSetRoomItem,
  updateEditionPreferences: ConfigurationServiceHelper.loadServerConfig,
};

export default compose(
  withEditionSearch,
  withFirebase,
  withUrlProperties,
  withAuthorization(AuthenticatedCondition, [Claims.Editor]),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(ScenarioItemsEditionScreen);
