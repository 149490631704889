/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import domtoimage from 'dom-to-image';
import ReactAudioPlayer from 'react-audio-player';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Scenario, BaseItem, NPC, LocalizedFile, City, ItemTypes } from 'src/data';
import { asyncForEach } from 'src/utils';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { Singleton as LocaleManager } from 'src/store/scenario/header/ScenarioLocaleManager';
import { getNewAtlObject } from 'src/store/scenario/items/ItemsReducer';

import Firebase, { withFirebase, FirebaseHelper } from 'src/services/Firebase';

import Loader from 'src/pages/components/loader';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { InputString, InputNumber } from 'src/pages/components/inputs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './imageModale.css';
import AtlObject from '../../../data/AtlObject';
import emptyAsset from '../../../assets/images/empty.png';

export type FileTranslationViewProps = {
  getAllItemAssets: ItemsServiceHelper.getAllItemAssetsType,
  updateItem: (sectionId: string, part?: string, itemId: string, item: AtlObject<any>) => Promise<any>,
  locale: string,
  accept: string,
  showImages: boolean,
  showContent: boolean,
  scenario: Scenario,
  filesToRemove: any[],
  part: 'header' | 'items' | 'npcs',
  section: 'scenario',
  firebase: Firebase,
  item: Scenario | BaseItem | NPC | City,
  sectionId: string,
  availableLocales: string[],
  files: { [locale: string]: LocalizedFile },
  sizeWarnLimit: number,
  sizeErrorLimit: number,
  addNotif: EventsServiceHelper.addNotifType,
  t: (key: string) => string,
  readOnly?: boolean,
  extraActions?: { trigger: (patToItem: string) => any, label: string }[],
};
type ModaleDataType = {
  filePath: string,
  locale: string,
  value: string,
  oldFile: LocalizedFile,
  selectedBackgroundImage?: string,
  selectedFont?: string,
  selectedFontSize?: number,
};
type State = {
  isValid: boolean,
  filesToRemove: any[],
  files: { [locale: string]: LocalizedFile },
  hasChanges: boolean,
  isLoading: boolean,
  editModaleData?: ModaleDataType,
  availableBackgrounds: { id: string, url?: string }[],
  item?: AtlObject,
};

class FileTranslationView extends React.PureComponent<FileTranslationViewProps, State> {
  static defaultProps = {
    accept: '.png,.jpg,.jpeg',
    showImages: true,
    showContent: true,
    part: 'items',
  };

  state = {
    isValid: false,
    filesToRemove: [],
    hasChanges: false,
    isLoading: false,
    editModaleData: undefined,
    availableBackgrounds: [],
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps) {
    if (!oldProps.item) {
      this.setItemData(this.props);
    }
  }

  setItemData = async (props: FileTranslationViewProps) => {
    const newState = { isLoading: false };
    const { item, sectionId, getAllItemAssets, availableLocales, part, files } = props;
    if (part === 'items' && item instanceof AtlObject) {
      const updatedItem = await getAllItemAssets(sectionId, item, availableLocales, props.readOnly);
      if (props.readOnly) {
        newState.item = updatedItem;
        const stateFiles = updatedItem.getLocalizedFilesWithPath();
        newState.files = stateFiles;
      }
    } else if (props.readOnly) {
      newState.item = item;
      newState.files = files;
    }
    this.setState(newState);
    this.updateValidity(newState);
  };

  getNewContent = (content) => {
    switch (this.props.part) {
      case 'items':
        return getNewAtlObject(content);
      case 'header':
        return new Scenario(content);
      case 'city':
        return new City(content);
      default:
        throw new Error('Unknown item type');
    }
  };

  dispatchFileSizeError = (size: number) => {
    const { addNotif, sizeErrorLimit } = this.props;
    addNotif(
      NotificationTypes.ERROR,
      'E_FILE_TO_BIG',
      `Size:${size / 1000000}MB expected max ${sizeErrorLimit / 1000000}MB`,
      0,
    );
  };

  dispatchFileSizeWarn = (size: number) => {
    const { addNotif, sizeWarnLimit } = this.props;
    addNotif(
      NotificationTypes.WARN,
      'W_FILE_BIG',
      `Size:${size / 1000000}MB expected max ${sizeWarnLimit / 1000000}MB`,
      0,
    );
  };

  handleFileChange = async (filePath, locale, oldFile: LocalizedFile, file) => {
    this.setState({ isLoading: true });
    const { item, part, sizeWarnLimit, sizeErrorLimit } = this.props;

    const size = file && file.size;
    if (size > sizeErrorLimit) {
      this.dispatchFileSizeError(size);
      this.setState({ isLoading: false });
    } else {
      if (size > sizeWarnLimit) {
        this.dispatchFileSizeWarn(size);
      }
      const newItem: AtlObject<any> = this.getNewContent(item);
      const isPublic = part === 'header';
      const newFile = new LocalizedFile(oldFile.itemId, oldFile.fieldName, oldFile, isPublic);
      newItem.setFile(filePath, newFile);
      const itemFilesToRemove = oldFile.listStorageFiles(locale);
      if (!newFile.hasLocale(locale)) {
        newFile.addLocale(locale);
      }
      newFile.files[locale].contentToUpload = file;
      newFile.files[locale].name = file.name;

      let { filesToRemove } = this.state;
      if (itemFilesToRemove.length) {
        if (filesToRemove) {
          filesToRemove = [...filesToRemove, ...itemFilesToRemove];
        } else {
          filesToRemove = itemFilesToRemove;
        }
        this.setState({ filesToRemove });
      }
      await this.updateContent(newItem, filesToRemove);
    }
  };

  removeFileLocale = async (filePath, locale, oldFile: LocalizedFile) => {
    this.setState({ isLoading: true });
    const { item, part } = this.props;
    const newItem: AtlObject<any> = this.getNewContent(item);
    const isPublic = part === 'header';
    const newFile = new LocalizedFile(oldFile.itemId, oldFile.fieldName, oldFile, isPublic);
    newItem.setFile(filePath, newFile);
    const itemFilesToRemove = oldFile.listStorageFiles(locale);
    newFile.removeLocale(locale);

    let { filesToRemove } = this.state;
    if (itemFilesToRemove.length) {
      if (filesToRemove) {
        filesToRemove = [...filesToRemove, ...itemFilesToRemove];
      } else {
        filesToRemove = itemFilesToRemove;
      }
      this.setState({ filesToRemove });
    }
    await this.updateContent(newItem, filesToRemove);
  };

  updateContent = async (content, filesToRemove) => {
    const { updateItem, sectionId, firebase, part, section } = this.props;
    if (filesToRemove && filesToRemove.length) {
      await FirebaseHelper.removeEditorFilesAsync(sectionId, filesToRemove, section, firebase);
    }

    await updateItem(sectionId, part, content.id, content);
    this.setState({ isLoading: false });
  };

  updateValidity(contentJson, loading) {
    this.setState({
      isValid: !loading && !!contentJson,
    });
  }

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  setEditData = (filePath: string, locale: string, value: string, oldFile: LocalizedFile) => {
    // TODO Make copy of oldfile
    if (!oldFile.generationConfig) {
      // eslint-disable-next-line no-param-reassign
      oldFile.generationConfig = { font: 'Nasalization', fontSize: 20, backgroundId: 'paper1.jpeg' };
    }
    this.setState({
      editModaleData: {
        filePath,
        locale,
        value,
        oldFile,
      },
    });
    const availableBackgrounds = [];
    for (let i = 1; i < 25; i += 1) {
      // TODO: Use a database instead
      availableBackgrounds.push({ id: `paper${i}.jpeg` });
    }
    this.setState({ availableBackgrounds });
    const { firebase } = this.props;
    asyncForEach(availableBackgrounds, async (bg: { id: string, url?: string }) => {
      const ref = firebase.assetBackgroundStorage(bg.id);
      try {
        // eslint-disable-next-line no-param-reassign
        bg.url = await ref.getDownloadURL();
      } catch (error) {
        console.warn('Unable to load bg asset', error);
      }
    }).then(() => {
      this.setState({ availableBackgrounds });
      this.forceUpdate();
    });
    setTimeout(() => {
      $(`#assetEditModale${filePath.replace('.', '_')}${locale}`).modal({ show: true });
    }, 100);
  };

  exportImage = async (elementId: string, filename: string) => {
    // Update item data;
    const { editModaleData } = this.state;
    if (editModaleData) {
      const { oldFile, filePath } = editModaleData;
      this.setState({ isLoading: true });
      const { item, part } = this.props;
      const newItem = this.getNewContent(item);
      const isPublic = part === 'header';
      const newFile: LocalizedFile = new LocalizedFile(oldFile.itemId, oldFile.fieldName, oldFile, isPublic);
      newItem.setFile(filePath, newFile);
      await this.updateContent(newItem);
      domtoimage.toBlob(document.getElementById(elementId)).then((blob) => {
        window.saveAs(blob, filename);
      });
    }
  };

  setEditConfig = (event) => {
    const { value, id: fieldName } = event.target;
    const editModaleData: ?$Shape<ModaleDataType> = { ...this.state.editModaleData };
    if (editModaleData && editModaleData.oldFile.generationConfig) {
      editModaleData.oldFile.generationConfig[fieldName] = value;
      this.setState({ editModaleData });
    }
  };

  onContentChange = (event) => {
    const { value } = event.target;
    const editModaleData: ?$Shape<ModaleDataType> = { ...this.state.editModaleData };
    if (editModaleData) {
      editModaleData.oldFile.content.values[editModaleData.locale] = value;
      editModaleData.value = value;
      this.setState({ editModaleData });
    }
  };

  onBgSelected = (event) => {
    const value = event.target.id;
    const editModaleData: ?$Shape<ModaleDataType> = { ...this.state.editModaleData };
    if (editModaleData && editModaleData.oldFile.generationConfig) {
      editModaleData.oldFile.generationConfig.backgroundId = value;
      this.setState({ editModaleData });
    }
  };

  renderEditImage = (editData) => {
    const { locale, value, filePath } = editData;
    const { t } = this.props;
    const contentToExportId = `assetEditModaleBody${filePath}-${locale}`;
    if (!this.state.editModaleData) {
      return <div />;
    }
    let bgImageSrc = '';
    const { oldFile } = this.state.editModaleData;

    if (oldFile.generationConfig === null || oldFile.generationConfig === undefined) {
      return <div />;
    }
    this.state.availableBackgrounds.forEach((it) => {
      // $FlowFixMe oldFile.generationConfig is tested above
      if (it.id === oldFile.generationConfig.backgroundId) {
        bgImageSrc = it.url;
      }
    });
    return (
      <div
        className="modal fade"
        id={`assetEditModale${filePath.replace('.', '_')}${locale}`}
        style={{ width: '100%' }}
        role="dialog"
        aria-labelledby={'#Asset edit modale'}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content" style={{ width: 'auto' }}>
            <div className="modal-header">
              <h5 className="modal-title" id={filePath}>
                {filePath}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div>
              <div
                className="container-fluid"
                style={{
                  width: 500,
                  overflowX: 'scroll',
                  overflowY: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                <InputString
                  fieldName="locale"
                  value={value}
                  disabled={false}
                  multiline
                  handleChange={this.onContentChange}
                />
                {this.state.availableBackgrounds.map((bg) => (
                  <img
                    key={bg.id}
                    id={bg.id}
                    src={bg.url}
                    alt={bg.id}
                    style={{ height: 50, margin: 0, padding: 5 }}
                    onClick={this.onBgSelected}
                  />
                ))}
              </div>
              <InputNumber
                fieldName="fontSize"
                // $FlowFixMe oldFile.generationConfig is tested above
                value={oldFile.generationConfig.fontSize}
                label={'Size'}
                handleChange={this.setEditConfig}
              />
            </div>
            <div className="modal-body" style={{ width: 'auto', padding: 0 }} id={contentToExportId}>
              <img src={bgImageSrc} style={{ height: 500, margin: 0, padding: 0 }} alt="background" />
              <div className="editImageTextContainer">
                <span
                  className="editImageText texteffet"
                  style={{
                    // $FlowFixMe oldFile.generationConfig is tested above
                    fontFamily: oldFile.generationConfig.font,
                    // $FlowFixMe oldFile.generationConfig is tested above
                    fontSize: `${oldFile.generationConfig.fontSize}px`,
                  }}
                >
                  {value || `${locale}: ${filePath}\n Value: ${value}`}
                </span>
              </div>
            </div>
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon2"
              value={locale}
              onClick={() => this.exportImage(contentToExportId, `${filePath}_${locale}.png`)}
            >
              {t('general.save')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderFileTranslation = (filePath, availableLocales) => {
    const { accept, files, showImages, showContent, t, readOnly } = this.props;
    const { files: stateFiles } = this.state;
    const finalFiles = stateFiles && readOnly ? stateFiles : files;
    const file = finalFiles[filePath];
    return (
      <tr key={filePath}>
        <th
          scope="row"
          style={{
            maxWidth: 100,
          }}
        >
          {file.getCompressedName()}
          <br />
          {this.props.extraActions &&
            this.props.extraActions.map((action, index) => (
              <button
                className="btn btn-outline-danger"
                type="button"
                id="button-addon2"
                onClick={() => {
                  action.trigger(`${this.props.item.id}/${filePath}`);
                  $(`#translationDiffModale${this.props.item.id}`).modal('hide');
                }}
                key={`${filePath}_action_${index}`}
              >
                {action.label}
              </button>
            ))}
        </th>
        {availableLocales.map((locale) => {
          const fileLoc = file.getFileForLocale(locale);
          const name = fileLoc && fileLoc.storageName;
          const value = file.content.valueForLocale(locale, false);
          const url = file.files[locale] && file.files[locale].url;
          const isSound =
            this.props.item.type === ItemTypes.SoundEffect || this.props.item.type === ItemTypes.BackgroundMusic;
          return (
            <td key={locale} style={{ textOverflow: 'ellipsis' }}>
              {showContent && <InputString fieldName="locale" value={value} disabled={true} />}
              <div className="input-group">
                {!!url &&
                  showImages &&
                  (isSound ? (
                    <div className="preview-image">
                      <ReactAudioPlayer src={url} autoPlay={false} className="w-100" controls alt="preview" />
                    </div>
                  ) : (
                    <img src={url} className="preview-image" alt="preview" />
                  ))}
                {!url && showImages && <img src={emptyAsset} className="preview-image none" alt="empty" />}
                {this.props.readOnly || (
                  <React.Fragment>
                    <div className="custom-file">
                      <input
                        type="file"
                        accept={isSound ? '.mp3' : accept}
                        className="form-control custom-file-input"
                        id={locale}
                        onChange={(e) => this.handleFileChange(filePath, locale, file, e.target.files[0])}
                        placeholder={'...'}
                        multiple={false}
                      />
                      <label
                        className="custom-file-label BarlowFont"
                        style={{ overflow: 'hidden', wordWrap: '...' }}
                        htmlFor={locale}
                      >
                        {name}
                      </label>
                    </div>
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary delete-inline"
                        type="button"
                        id="button-addon2"
                        value={locale}
                        onClick={() => this.removeFileLocale(filePath, locale, file)}
                      >
                        <FontAwesomeIcon icon={['fad', 'trash-alt']} />
                      </button>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <button
                className="btn btn-outline-secondary hidden"
                type="button"
                id="button-addon2"
                value={locale}
                onClick={() => this.setEditData(filePath, locale, value, file)}
              >
                {t('general.editImage')}
              </button>
            </td>
          );
        })}
      </tr>
    );
  };

  render() {
    const { availableLocales, files, item, part, t, readOnly } = this.props;
    const { isLoading, editModaleData, item: stateItem, files: stateFiles } = this.state;
    const columnCount = availableLocales.length + 1;
    const columnSize = `${100.0 / columnCount}%`;
    const finalItem = stateItem && readOnly ? stateItem : item;
    const finalFiles = stateItem && readOnly ? stateFiles : files;
    return finalItem && Object.keys(finalFiles).length ? (
      <div key={finalItem.id} className="card bg-light screenBlock mb-3">
        {!!editModaleData && this.renderEditImage(editModaleData)}
        <div className="card-header ml-0 mb-0 pb-0 pt-1">
          <h6>{`${part}/${finalItem.id}`}</h6>
        </div>
        <div className="card-body mt-0 pt-0 ml-0 mr-0 pr-0 pl-0 pb-0 mb-0">
          <table className="table table-bordered table-hover table-striped pb-0 mb-0">
            <thead className="thead-light">
              <tr>
                <th style={{ maxWidth: columnSize }} scope="col">
                  {t('screens.tradsScreen.exportName')}
                </th>
                {availableLocales.map((locale) => (
                  <th key={locale} style={{ maxWidth: columnSize }} scope="col">
                    {locale}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(finalFiles).map((filePath) => this.renderFileTranslation(filePath, availableLocales))}
            </tbody>
          </table>
        </div>

        {isLoading && <Loader />}
      </div>
    ) : (
      ''
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  availableLocales: ['default', ...LocaleManager.availableLocales],
  files: ownProps.item.getLocalizedFilesWithPath(),
  sizeWarnLimit: ownProps.sizeWarnLimit || state.configuration.fileSizeLimits.image / 10,
  sizeErrorLimit: ownProps.sizeErrorLimit || state.configuration.fileSizeLimits.image,
});

const mapDispatchToProps = {
  getAllItemAssets: ItemsServiceHelper.getAllItemAssets,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(FileTranslationView);
