/**
 * @flow
 *
 * @format
 */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import Firebase, { withFirebase } from 'src/services/Firebase';
import { UserServiceHelper } from 'src/store/user';
import type { AtlEditorState } from 'src/store';
import type { Team } from 'src/data/types/TeamType';
import { teamNameMyTeam } from 'src/store/admin/TeamServiceHelper';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import EditTeamMembersView from './EditTeamMembersView';

export type UserViewProps = {
  logout: UserServiceHelper.logoutType,
  getTeams: UserServiceHelper.getUserAdministratedTeamsType,
  firebase: Firebase,
  email: string,
  idUser: string,
  collarNumber: ?string,
  t: (key: string) => string,
};

const UserView = (props: UserViewProps) => {
  const [teams, setTeams] = useState<Array<Team>>([]);

  useEffect(() => {
    const refreshTeamsAsync = async () => {
      const newTeams: Array<Team> = await props.getTeams(undefined, props.firebase);
      setTeams(newTeams || []);
    };
    refreshTeamsAsync();
  }, []);

  const logout = () => {
    const { firebase } = props;
    if (props.logout) {
      props.logout(firebase);
    }
  };

  const { email, idUser, collarNumber, t } = props;
  return (
    <div className="card bg-light screenBlock">
      <div className="card-header">
        <h3 style={{ marginBottom: 0 }}>{t('screens.settings.user.sectionTitle')}</h3>
      </div>
      <div className="row ml-3">
        <div className="mr-5 mt-3">
          <h5>{t('screens.settings.user.mailTitle')}</h5>
          <div className="mr-3 ml-3 mt-1">{email}</div>
        </div>

        {collarNumber && (
          <div className="mr-3 mt-3">
            <h5>{t('screens.settings.user.collarNumber')}</h5>
            <div className="mr-3 ml-3 mt-1">{`#${collarNumber}`}</div>
          </div>
        )}
      </div>

      <h5 className="mr-3 ml-3 mt-3">{t('screens.settings.user.teamsTitle')}</h5>
      <div className="mr5 ml-5 mt-1 accordion" id="accordionTeams">
        {teams.length > 0
          ? teams.map((it, index) => (
              <div className="card mr-5" key={`acc-header-${index}`}>
                <div
                  className="card-header collapsed bg-light"
                  id={`heading-${index}`}
                  data-toggle="collapse"
                  data-target={`#collapseTeam-${index}`}
                  aria-expanded="true"
                  aria-controls={`collapseTeam-${index}`}
                >
                  <h5 className="text-left">{teamNameMyTeam(it, idUser, t)}</h5>
                </div>
                <div
                  id={`collapseTeam-${index}`}
                  className="collapse"
                  aria-labelledby={`heading-${index}`}
                  data-parent="#accordionTeams"
                >
                  <div className="card-body">
                    <EditTeamMembersView team={it} key={it.uid} />
                  </div>
                </div>
              </div>
            ))
          : t('screens.settings.user.noTeam')}
      </div>

      <div className="mr-3 ml-3 mt-3">
        <button className={'btn mb-3 btn-primary'} type="button" id="button-addon2" onClick={logout}>
          {t('screens.settings.user.logout')}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AtlEditorState) => ({
  email: state.user.user && state.user.user.email,
  idUser: state.user.user && state.user.user.uid,
  collarNumber: state.user.user && state.user.user.collarNumber,
});

const mapDispatchToProps = {
  logout: UserServiceHelper.logoutAsync,
  getTeams: UserServiceHelper.getUserAdministratedTeams,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(UserView);
