/**
 * @flow
 *
 * @format
 */
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { TabContent } from '../components';
import UserRightsEditionWidget from './UserRightsEditionWidget';
import UserCreateWidget from './UserCreateWidget';

type Props = {
  t: (key: string) => string,
};

type State = {};

class UsersTab extends React.PureComponent<Props, State> {
  static defaultProps = {};

  state = {};

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <TabContent name="users" active>
        <VisibilitySensor onChange={this.onVisibilityChanged} partialVisibility>
          <div className="card-header">
            <h3>{this.props.t('screens.admin.users.sectionTitle')}</h3>
          </div>
        </VisibilitySensor>
        <UserCreateWidget />
        <UserRightsEditionWidget />
      </TabContent>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(UsersTab);
