/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DiscussionServiceHelper } from 'src/store/scenario/items';

import { LocalizedStringArray, AnswerOpened, Message } from 'src/data';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import errors from 'src/assets/errors';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { itemTypeColorator } from 'src/pages/scenario/components/graph/baseItem/BaseItemColorations';
import AddItemWidget from '../../../components/graph/AddItemWidget';

import './DiscussionGraphView.css';
import { InputBoolean, InputSelect, InputJSONArray } from '../../../../components/inputs';
import { generateId } from '../../../../../data/AtlObject';
import { ItemNodeTypes } from '../../../components/graph';

export type AnswerOpenedInputProps = {
  discussionId: string,
  messageId?: string,
  nodeId: string,
  answer: AnswerOpened,
  idPrefix: string,
  nextCustoms: string[],
  locale: string,
  updateAnswerOpened: DiscussionServiceHelper.updateAnswerType,
  parentMessage?: Message,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  startEditing: () => any,
  isEditingItem: boolean,
  onAddBellow: (data: any, type: string) => any,
  t: (key: string) => string,
  itemColor: string,
  setDisplayModal: (displayModal: boolean) => void,
};

type State = {
  id?: string,
  isDefault: boolean,
  isInternationalized: boolean,
  idSuffix: string,
  isGoodAnswer: boolean,
  isValid: boolean,
  nextCustom?: string,
  hasChanges: boolean,
  contentsRecognized: string,
  preventAutoSaveUnmount: boolean,
};

class AnswerOpenedInput extends React.PureComponent<AnswerOpenedInputProps, State> {
  static defaultProps = {
    idPrefix: '',
    canChangeId: true,
    contentTypes: [],
    nextCustoms: [],
    answer: {},
  };

  state = {
    id: undefined,
    isValid: false,
    nextCustom: undefined,
    idSuffix: '',
    isDefault: false,
    isInternationalized: false,
    contentsRecognized: '',
    isGoodAnswer: false,
    hasChanges: false,
    preventAutoSaveUnmount: false,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps: AnswerOpenedInputProps, oldState: State) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded(oldProps, oldState);
    }
    if (oldProps.messageId !== this.props.messageId || oldProps.nodeId !== this.props.nodeId) {
      this.setItemData(this.props);
    }
  }

  componentWillUnmount() {
    if (!this.state.preventAutoSaveUnmount) {
      this.warnSaveIfNeeded();
    }
  }

  warnSaveIfNeeded = async (
    props?: AnswerOpenedInputProps = this.props,
    state?: State = this.state,
    removeNextCustom?: boolean = false,
  ) => {
    if (removeNextCustom || (this.state.hasChanges && this.props.scenarioId)) {
      const { isValid } = state;
      if (isValid && props.scenarioId) {
        const updateData = this.getDataToSave(props, state);
        if (removeNextCustom) {
          updateData.newAnswer.nextCustom = '';
        }
        await this.updateWithData(updateData, false, false);
      } else {
        this.props.addNotif(
          NotificationTypes.ERROR,
          !isValid ? 'E_UNSAVED_ITEM_INVALID' : 'E_UNSAVED_ITEM',
          undefined,
          0,
        );
      }
    }
  };

  setItemData = (props: AnswerOpenedInputProps) => {
    const { answer, idPrefix, locale } = props;
    if (answer) {
      const { id, isDefault, contentsRecognized, isGoodAnswer, nextCustom } = answer;
      const prefix = idPrefix;
      const idSuffix = id ? id.slice(prefix.length) : '';
      const val =
        contentsRecognized && contentsRecognized.valueForLocale(this.state.isInternationalized ? locale : 'en', true);
      const isInternationalized = !!contentsRecognized && Object.keys(contentsRecognized.values).length !== 1;
      let newContentsRecognized;
      try {
        newContentsRecognized = val && val.length ? JSON.stringify(val) : '';
      } catch (err) {
        newContentsRecognized = '';
      }
      const newState = {
        id: id || '',
        idSuffix: idSuffix || '',
        contentsRecognized: newContentsRecognized,
        isDefault,
        isGoodAnswer,
        isInternationalized,
        nextCustom: nextCustom || '',
        hasChanges: false,
      };
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  /**
   * Update the json validity of the field
   */
  updateValidity = (newVal) => {
    const { contentsRecognized, isDefault } = newVal;
    const isValid = isDefault || (!!contentsRecognized && !!contentsRecognized.length);
    let isJsonValid = isDefault;
    try {
      if (!isDefault) {
        const json = JSON.parse(contentsRecognized);
        isJsonValid = Array.isArray(json);
      }
    } catch (error) {
      console.log('Could not check validity', error);
    }
    this.setState({ isValid: isValid && isJsonValid });
  };

  /**
   * Format the data to save, only if it's valid
   */
  getDataToSave = (props?: AnswerOpenedInputProps = this.props, state?: State = this.state) => {
    const { idSuffix, isDefault, contentsRecognized, isGoodAnswer, isInternationalized, nextCustom } = state;
    const { updateAnswerOpened, answer, discussionId, messageId, locale, idPrefix } = props;
    const suffixToUse = idSuffix || generateId();
    const id = idPrefix + suffixToUse;
    const newAnswer = new AnswerOpened(answer);
    const contentsRecognizedTrans = new LocalizedStringArray(
      answer.contentsRecognized.id,
      answer.contentsRecognized,
      isInternationalized,
    );

    if (!isInternationalized) {
      contentsRecognizedTrans.values = {
        en: contentsRecognizedTrans.values.en,
        fr: contentsRecognizedTrans.values.en,
      };
    }
    contentsRecognizedTrans.defaultLocale = isInternationalized ? locale : 'en';

    if (contentsRecognized && (locale || !isInternationalized)) {
      const recogLanguage = isInternationalized ? locale : 'en';
      try {
        contentsRecognizedTrans.setValueForLocale(JSON.parse(contentsRecognized), recogLanguage);
      } catch (err) {
        contentsRecognizedTrans.setValueForLocale([], recogLanguage);
      }
      if (isInternationalized) {
        contentsRecognizedTrans.initAllLocalesIfRequired();
      }
    }
    newAnswer.id = id;
    newAnswer.isDefault = isDefault;
    newAnswer.contentsRecognized = contentsRecognizedTrans;
    newAnswer.isGoodAnswer = isGoodAnswer;
    newAnswer.nextCustom = nextCustom;
    return {
      newAnswer,
      updateAnswerOpened,
      discussionId,
      messageId,
      id,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false, sendNotif?: boolean = true) => {
    const { updateAnswerOpened, discussionId, messageId, id, newAnswer } = updateData;
    if (updateAnswerOpened) {
      updateAnswerOpened(discussionId, messageId || id, newAnswer, sendNotif);
      if (notifyUi) {
        this.setState({ hasChanges: false });
      }
    }
  };

  /**
   * Save the modifications, if they're valid
   */
  updateAnswer = () => {
    const { isValid, hasChanges } = this.state;
    if (hasChanges && isValid) {
      const updateData = this.getDataToSave();
      this.updateWithData(updateData, true);
    }
  };

  /**
   * Retrieves the detected errors
   */
  getErrors = () => {
    const { parentMessage, answer } = this.props;
    const { isValid, contentsRecognized } = this.state;
    const res = [];
    if (parentMessage && answer) {
      if (!parentMessage.isAnswerOpenedAccessible(answer)) {
        res.push(errors.answer_default_order);
      }
    }
    // JSON format error
    if (!isValid && contentsRecognized.length > 0) {
      res.push(errors.answer_with_wrong_format);
    }
    return res;
  };

  addBellow = async (dataStr: string) => {
    this.setState({ preventAutoSaveUnmount: true });
    await this.warnSaveIfNeeded(undefined, undefined, true);
    const data = JSON.parse(dataStr);
    this.props.onAddBellow(this.props.answer, data.type);
  };

  render() {
    const { nextCustoms, answer, t } = this.props;
    const { contentsRecognized, isDefault, isGoodAnswer, nextCustom, isValid, isInternationalized } = this.state;
    const errorMessages = this.getErrors();

    return (
      <div
        className="card bg-light screenBlock pb-2"
        style={{ height: '100%', overflow: 'hidden', borderRadius: '10px', backgroundColor: 'white', border: 'none' }}
      >
        <div
          className="card-header"
          ref={(node) => {
            if (node) {
              node.style.setProperty(
                'background',
                `linear-gradient(45deg,${this.props.itemColor}, ${this.props.itemColor}99)`,
                'important',
              );
            }
          }}
          style={{ border: 'none', height: '65px', alignItems: 'center' }}
        >
          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={this.updateAnswer}
            disabled={!isValid}
            style={{ float: 'left', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'save']} />
          </div>

          <h3
            className="answerOpened_graph_title h-100 d-flex align-items-center text-capitalize padding-before text-center"
            style={{ display: 'inline-block', maxWidth: '80%', fontSize: '12px' }}
          >
            {t('screens.discussionEdition.answerOpenedEdition.sectionTitle')}
          </h3>

          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={() => this.props.setDisplayModal(false)}
            disabled={!isValid}
            style={{ float: 'right', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
        {errorMessages.length ? (
          <div>
            {errorMessages.map((mess) => (
              <div className={`alert mb-0 ${mess.class}`} key={mess.key} role="alert">
                {t(mess.text)}
              </div>
            ))}
          </div>
        ) : (
          undefined
        )}
        {answer && (
          <div
            className="pr-3 pl-3 pt-3 pb-3"
            style={{ height: '100%', overflowY: 'scroll', backgroundColor: 'white' }}
          >
            <InputBoolean
              fieldName="isDefault"
              onFocus={this.onFieldFocus}
              value={isDefault}
              label={t('screens.discussionEdition.answerOpenedEdition.isDefaultLabel')}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputBoolean
              fieldName="isInternationalized"
              onFocus={this.onFieldFocus}
              value={isInternationalized}
              label={t('screens.discussionEdition.answerOpenedEdition.isInternationalizedLabel')}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputJSONArray
              className="pt-3"
              onFocus={this.onFieldFocus}
              fieldName="contentsRecognized"
              value={contentsRecognized}
              label={t('screens.discussionEdition.answerOpenedEdition.contentsRecognizedLabel')}
              handleChange={this.handleChange}
              hidden={isDefault}
              disabled={isDefault}
              helpInfos={[
                {
                  content: t('helpStrings:scenario.discussion.input.answersOpened.contentsRecognized', {
                    returnObjects: true,
                  }),
                  title: 'contentsRecognized',
                },
              ]}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
              prependStyle={{ backgroundColor: '#D1D6DB', border: 'none' }}
            />
            <InputBoolean
              fieldName="isGoodAnswer"
              onFocus={this.onFieldFocus}
              value={isGoodAnswer}
              label={t('screens.discussionEdition.answerOpenedEdition.isGoodPlaceholder')}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputSelect
              fieldName="nextCustom"
              onFocus={this.onFieldFocus}
              value={nextCustom}
              values={nextCustoms}
              itemToId={(it) => it}
              itemToTitle={(it) => it}
              label={t('screens.discussionEdition.answerOpenedEdition.nextCustomLabel')}
              handleChange={this.handleChange}
              hidden={!!answer.nextMessageId}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <div className="form-group">
              <label className="strong">{t('screens.discussionEdition.answerOpenedEdition.fastNext')}</label>
              <span className="fast-action bg-white">
                <AddItemWidget
                  type={ItemNodeTypes.Message}
                  draggable={false}
                  onClick={this.addBellow}
                  label={this.props.t('screens.discussionEdition.menu.npc_message')}
                />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, messageId, nodeId } = ownProps;
  const discussion = discussionId && state.scenario.items[discussionId];
  let answerRedux;
  let messageRedux;
  if (discussion) {
    if (!messageId) {
      answerRedux = discussion.__detachedNodes.answersOpened.find((ans) => ans.nodeId === nodeId);
    } else {
      messageRedux = messageId && discussion.messages[messageId];
      answerRedux = messageRedux && messageRedux.answersOpened.find((ans) => ans.nodeId === nodeId);
    }
  }
  const itemColor = itemTypeColorator(answerRedux);
  return {
    scenarioId: state.scenario.header.id,
    parentMessage: messageRedux,
    locale: state.preferences.editionLocale,
    nextCustoms: state.configuration.nextCustoms,
    answer: answerRedux,
    itemColor,
  };
};

const mapDispatchToProps = {
  updateAnswerOpened: DiscussionServiceHelper.updateAnswerOpenedAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['default', 'helpStrings']),
)(AnswerOpenedInput);
