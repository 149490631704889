/**
 * @flow
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';

export default class PermaLinkItem extends BaseItem {
  permaLink: ?string;

  constructor(json: any) {
    if (json instanceof PermaLinkItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { permaLink } = json;
    this.ItemTypes = ItemTypes.PermaLink;
    this.permaLink = permaLink;
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.permaLink = this.permaLink;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.permaLink = this.permaLink;
    return res;
  }
}
