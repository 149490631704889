/**
 * @flow
 *
 * @format
 */
/* eslint-disable no-param-reassign */

import { createNamedReducer } from 'redux-named-reducers';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import BaseItem, { ItemTypes } from 'src/data/BaseItem';
import {
  Message,
  Answer,
  Discussion,
  MessageTriggeredItem,
  AnecdoteItem,
  ArchiveItem,
  CheckpointItem,
  CustomItem,
  DocumentItem,
  FailureItem,
  GameAreaItem,
  OpenableItem,
  POIItem,
  SecondaryMissionItem,
  StartItem,
  SuccessItem,
  TimeTravelItem,
  TimerItem,
  ToolItem,
  VideoItem,
  Image360Item,
  UnlockableItem,
  CommentItem,
  BackgroundMusicItem,
  BackgroundMusicControlsItem,
  SoundEffectItem,
  POIGenericBundle,
  PermaLinkItem,
} from 'src/data';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import DiscussionEntryPoint from 'src/data/discussions/DiscussionEntryPoint';
import TriggeredItem, { TriggeredItemConditions } from 'src/data/TriggeredItem';
import AnswerOpened from 'src/data/discussions/AnswerOpened';
import * as types from './types';
import AtlObject from '../../../data/AtlObject';

export type ItemsReducerState = {
  [s: string]: BaseItem,
  __detachedNodes: { items: BaseItem[] },
};
export const ItemsReducerDefaultState = {
  __detachedNodes: { items: [] },
};

export const getNewItem: (any) => BaseItem = (item) => {
  let newItem: BaseItem;
  switch (item.type) {
    case ItemTypes.Anecdote:
      newItem = new AnecdoteItem(item);
      break;
    case ItemTypes.AnecdotePOI:
      newItem = new POIGenericBundle(item, ItemTypes.AnecdotePOI);
      break;
    case ItemTypes.Archive:
      newItem = new ArchiveItem(item);
      break;
    case ItemTypes.Checkpoint:
      newItem = new CheckpointItem(item);
      break;
    case ItemTypes.Comment:
      newItem = new CommentItem(item);
      break;
    case ItemTypes.Custom:
      newItem = new CustomItem(item);
      break;
    case ItemTypes.Document:
      newItem = new DocumentItem(item);
      break;
    case ItemTypes.DocumentPOI:
      newItem = new POIGenericBundle(item, ItemTypes.DocumentPOI);
      break;
    case ItemTypes.Discussion:
      newItem = new Discussion(item);
      break;
    case ItemTypes.DiscussionPOI:
      newItem = new POIGenericBundle(item, ItemTypes.DiscussionPOI);
      break;
    case ItemTypes.Failure:
      newItem = new FailureItem(item);
      break;
    case ItemTypes.GameArea:
      newItem = new GameAreaItem(item);
      break;
    case ItemTypes.Openable:
      newItem = new OpenableItem(item);
      break;
    case ItemTypes.Video:
      newItem = new VideoItem(item);
      break;
    case ItemTypes.Image360:
      newItem = new Image360Item(item);
      break;
    case ItemTypes.POI:
      newItem = new POIItem(item);
      break;
    case ItemTypes.SecondaryMission:
      newItem = new SecondaryMissionItem(item);
      break;
    case ItemTypes.Unlockable:
      newItem = new UnlockableItem(item);
      break;
    case ItemTypes.Start:
      newItem = new StartItem(item);
      break;
    case ItemTypes.Success:
      newItem = new SuccessItem(item);
      break;
    case ItemTypes.TimeTravel:
      newItem = new TimeTravelItem(item);
      break;
    case ItemTypes.Timer:
      newItem = new TimerItem(item);
      break;
    case ItemTypes.Tool:
      newItem = new ToolItem(item);
      break;
    case ItemTypes.BackgroundMusic:
      newItem = new BackgroundMusicItem(item);
      break;
    case ItemTypes.BackgroundMusicControls:
      newItem = new BackgroundMusicControlsItem(item);
      break;
    case ItemTypes.SoundEffect:
      newItem = new SoundEffectItem(item);
      break;
    case ItemTypes.PermaLink:
      newItem = new PermaLinkItem(item);
      break;
    default:
      throw new Error('Unsupported item type');
  }
  return newItem;
};

export const getNewAtlObject: (any) => AtlObject<any> = (item) => {
  return getNewItem(item);
};

const removeItemTriggeredState = (state, triggerNodeId, itemId) => {
  const prevTriggeredItem = state[itemId];
  if (prevTriggeredItem) {
    const updatedItem = getNewItem(prevTriggeredItem);
    delete updatedItem.triggeredStates[triggerNodeId];
    delete updatedItem.unlockedValues[triggerNodeId];
    state[itemId] = updatedItem;
  }
};

const removeTrigger = (state, triggerNodeId, itemId) => {
  const prevTriggeredItem = state[itemId];
  if (prevTriggeredItem) {
    const updatedItem = getNewItem(prevTriggeredItem);
    updatedItem.triggeredItems = updatedItem.triggeredItems.filter((it) => it.nodeId !== triggerNodeId);
    state[itemId] = updatedItem;
  }
};

const removeItemUnlocked = (state, triggerNodeId, itemId) => {
  const prevTriggeredItem = state[itemId];
  if (prevTriggeredItem) {
    const updatedItem = getNewItem(prevTriggeredItem);
    delete updatedItem.unlockedValues[triggerNodeId];
    state[itemId] = updatedItem;
  }
};

const setItemUnlocked = (state: ItemsReducerState, triggerNodeId: string, itemId: string, unlockedValue: ?number) => {
  const prevTriggeredItem = state[itemId];
  const updatedItem = getNewItem(prevTriggeredItem);
  updatedItem.unlockedValues[triggerNodeId] = unlockedValue;
  state[itemId] = updatedItem;
};

const updateItemTriggeredStates = (
  state: ItemsReducerState,
  triggerNodeId: string,
  itemId: string,
  triggeredState: ?string,
  triggeredValue: ?number,
) => {
  const prevTriggeredItem = state[itemId];
  if (prevTriggeredItem) {
    const updatedItem = getNewItem(prevTriggeredItem);
    updatedItem.triggeredStates[triggerNodeId] = triggeredState || TriggeredItemConditions.Added;
    state[itemId] = updatedItem;
    if (triggeredState !== TriggeredItemConditions.Unlocked) {
      removeItemUnlocked(state, triggerNodeId, itemId);
    } else {
      setItemUnlocked(state, triggerNodeId, itemId, triggeredValue);
    }
    return true;
  }
  return false;
};

const reducer = (prevState: ItemsReducerState = ItemsReducerDefaultState, action: { type: string, payload: any }) => {
  let state;
  switch (action.type) {
    case types.REFRESH_ALL_TRIGGERED_INFO: {
      state = { ...prevState };
      let removedTriggersCount = 0;
      let removedMessageTriggersCount = 0;
      Object.values(state).forEach((item) => {
        // Update all the triggered items
        if (item instanceof BaseItem && item.triggeredItems) {
          const triggersToRemove = [];
          item.triggeredItems.forEach((trig) => {
            if (
              !updateItemTriggeredStates(
                state,
                trig.nodeId,
                trig.id,
                trig.newTriggeredCondition,
                trig.newTriggeredConditionValue,
              )
            ) {
              triggersToRemove.push(trig);
            }
          });
          if (triggersToRemove.length) {
            triggersToRemove.forEach((trig) => {
              console.warn(`Removed trigger from ${item.id} to ${trig.id} because destination item did not exist`);
              removeTrigger(state, trig.nodeId, item.id);
              removedTriggersCount += 1;
            });
          }
        }
        if (item instanceof Discussion) {
          Object.values(item.messages).forEach((mess) => {
            if (mess && mess instanceof Message && mess.triggeredItems) {
              const triggeredItems: TriggeredItem[] = mess.triggeredItems;
              const messageTriggersToRemove = [];
              triggeredItems.forEach((trig) => {
                if (trig.id) {
                  try {
                    if (
                      !updateItemTriggeredStates(
                        state,
                        trig.nodeId,
                        trig.id,
                        trig.newTriggeredCondition,
                        trig.newTriggeredConditionValue,
                      )
                    ) {
                      messageTriggersToRemove.push(trig);
                    }
                  } catch (error) {
                    throw new Error(
                      `Could not establish trigger from messsage ${mess.id} to item ${trig.id} on ${trig.newTriggeredCondition}`,
                      error,
                    );
                  }
                }
              });
              if (messageTriggersToRemove.length) {
                const newDisc = getNewItem(state[item.id]);
                const newMessage = newDisc.messages[mess.id];
                messageTriggersToRemove.forEach((trig) => {
                  console.warn(
                    `Removed messagetrigger from ${item.id} to ${trig.id} because destination item did not exist`,
                  );
                  removedMessageTriggersCount += 1;
                  newMessage.triggeredItems = newMessage.triggeredItems.filter((it) => it.nodeId !== trig.nodeId);
                });
                state[item.id] = newDisc;
              }
            }
          });
        }
      });
      if (removedTriggersCount || removedMessageTriggersCount) {
        alert(
          'Some triggers have been hard removed cause destination did not exist, please check console warns and save the scenario.',
        );
      }
      break;
    }
    case types.ITEMS_CREATE:
    case types.ITEMS_UPDATE: {
      const { itemId, item } = action.payload;
      const { __detachedNodes } = prevState;
      const detached = !item.id;
      state = { ...prevState };
      const detachedItems = __detachedNodes.items.filter((it) => it.nodeId !== item.nodeId);
      if (detached) {
        if (itemId && state[itemId]) {
          delete state[itemId];
        }
        detachedItems.push(getNewItem(item));
      } else {
        const newItem = getNewItem(item);
        state[itemId] = newItem;
      }
      state.__detachedNodes = { items: detachedItems };
      break;
    }
    case types.ITEMS_UPDATE_POSITION: {
      const { itemId, nodeId, pos } = action.payload;
      const { __detachedNodes } = prevState;
      state = { ...prevState };
      let item: ?BaseItem;
      const linkedItem = itemId && state[itemId];
      if (!linkedItem) {
        item = __detachedNodes.items.find((it) => it.nodeId === nodeId);
        const detachedItems = __detachedNodes.items.filter((it) => it.nodeId !== nodeId);
        if (item) {
          item = getNewItem(item);
          item.pos = { ...pos };
          detachedItems.push(item);
        }
        state.__detachedNodes = { items: detachedItems };
      } else {
        item = getNewItem(linkedItem);
        item.pos = { ...pos };
        state[itemId] = item;
      }
      break;
    }
    case types.ITEMS_REMOVE: {
      const { itemId, nodeId } = action.payload;
      const newState = { ...prevState };
      // First remove the item
      if (itemId) {
        const item = newState[itemId];
        // Update all the triggered items
        item.triggeredItems.forEach((trig) => {
          removeItemTriggeredState(newState, trig.nodeId, trig.id);
        });
        if (item instanceof Discussion) {
          Object.values(item.messages).forEach((mess) => {
            if (mess && mess instanceof Message && mess.triggeredItems) {
              const triggeredItems: TriggeredItem[] = mess.triggeredItems;
              triggeredItems.forEach((trig) => {
                removeItemTriggeredState(newState, trig.nodeId, trig.id);
              });
            }
          });
        }
        delete newState[itemId];
      } else {
        const { __detachedNodes } = prevState;
        const detachedItems = __detachedNodes.items.filter((it) => it.nodeId !== nodeId);
        newState.__detachedNodes = { items: detachedItems };
      }
      // Then remove all trigger to the item
      if (itemId) {
        Object.values(newState).forEach((item) => {
          if (item instanceof BaseItem) {
            if (item && item.triggeredItems) {
              let triggeredItems: TriggeredItem[] = item.triggeredItems;
              const oldLength = triggeredItems.length;
              triggeredItems = triggeredItems.filter((trigger) => trigger.id !== itemId);
              if (triggeredItems.length !== oldLength) {
                newState[item.id] = getNewItem(item);
                newState[item.id].triggeredItems = triggeredItems;
              }
            }
            if (item instanceof Discussion) {
              Object.values(item.messages).forEach((mess) => {
                if (mess instanceof Message) {
                  if (mess.contentItemId === itemId) {
                    newState[item.id] = getNewItem(newState[item.id]);
                    delete newState[item.id].messages[mess.id].contentItemId;
                  }
                }
                if (mess && mess instanceof Message && mess.triggeredItems) {
                  let triggeredItems: TriggeredItem[] = mess.triggeredItems;
                  const oldLength = triggeredItems.length;
                  triggeredItems = triggeredItems.filter((trigger) => trigger.id !== itemId);
                  if (triggeredItems.length !== oldLength) {
                    newState[item.id] = getNewItem(newState[item.id]);
                    newState[item.id].messages[mess.id].triggeredItems = triggeredItems;
                  }
                }
              });
            }
          }
        });
      }
      state = newState;
      break;
    }
    case types.ITEMS_ADD_TRIGGERED_ITEM: {
      const { parentId, childId, triggerNodeId, triggerPos, addTriggeredConditionsItem } = action.payload;
      state = { ...prevState };
      let item: BaseItem;
      const linkedItem = parentId && state[parentId];
      if (linkedItem && childId && triggerNodeId) {
        item = getNewItem(linkedItem);
        const childItem = getNewItem(state[childId]);
        const trigger = new TriggeredItem({ id: childId, nodeId: triggerNodeId, pos: triggerPos });
        trigger.initConditionIfNeeded(linkedItem.type, childItem.type, addTriggeredConditionsItem);
        item.triggeredItems.push(trigger);
        state[parentId] = item;
      }
      break;
    }
    case types.ITEMS_UPDATE_TRIGGERED_ITEM: {
      const { parentId, trigger } = action.payload;
      state = { ...prevState };
      let item: BaseItem;
      const linkedItem = parentId && state[parentId];
      let oldTriggeredId;
      if (linkedItem && trigger) {
        item = getNewItem(linkedItem);
        item.triggeredItems = item.triggeredItems.map((trig) => {
          if (trig.nodeId === trigger.nodeId) {
            oldTriggeredId = trig.id;
            return trigger;
          }
          return trig;
        });
        state[parentId] = item;
        if (oldTriggeredId && oldTriggeredId !== trigger.id) {
          removeItemTriggeredState(state, trigger.nodeId, oldTriggeredId);
        }
        if (trigger && trigger.id) {
          if (
            !updateItemTriggeredStates(
              state,
              trigger.nodeId,
              trigger.id,
              trigger.newTriggeredCondition,
              trigger.newTriggeredConditionValue,
            )
          ) {
            removeTrigger(state, trigger.nodeId, parentId);
          }
        }
      }
      break;
    }
    case types.ITEMS_MOVE_TRIGGERED_ITEM: {
      const { parentId, nodeId, pos } = action.payload;
      state = { ...prevState };
      let item: BaseItem;
      const linkedItem = parentId && state[parentId];
      if (linkedItem && nodeId) {
        item = getNewItem(linkedItem);
        item.triggeredItems = item.triggeredItems.map((trig) => {
          if (trig.nodeId === nodeId) {
            const newTrigger = new TriggeredItem(trig);
            newTrigger.pos = { ...pos };
            return newTrigger;
          }
          return trig;
        });
        state[parentId] = item;
      }
      break;
    }
    case types.ITEMS_REMOVE_TRIGGERED_ITEM: {
      const { parentId, triggerNodeId } = action.payload;
      state = { ...prevState };
      let item: BaseItem;
      const linkedItem = parentId && state[parentId];
      if (linkedItem && triggerNodeId) {
        item = getNewItem(linkedItem);
        const trigger = item.triggeredItems.find((trig) => trig.nodeId === triggerNodeId);

        item.triggeredItems = item.triggeredItems.filter((trig) => trig.nodeId !== triggerNodeId);
        state[parentId] = item;
        if (trigger && trigger.id) {
          removeItemTriggeredState(state, trigger.nodeId, trigger.id);
        }
      }
      break;
    }
    case types.ITEMS_CLEAN: {
      state = { __detachedNodes: { items: [] } };
      break;
    }
    // Discussions
    // *********************
    case types.DISCUSSION_ADD:
    case types.DISCUSSION_UPDATE: {
      const { discussionId, discussion } = action.payload;
      state = { ...prevState };
      state[discussionId] = new Discussion(discussion);
      break;
    }
    case types.DISCUSSION_ADD_ENTRY_POINT:
    case types.DISCUSSION_UPDATE_ENTRY_POINT: {
      const { discussionId, entryPoint } = action.payload;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const newPoint = new DiscussionEntryPoint(entryPoint);
      discussion.entryPoints[entryPoint.name] = newPoint;
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_MOVE_ENTRY_POINT: {
      const { discussionId, name, pos } = action.payload;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const oldPoint = discussion.entryPoints[name];
      const newPoint = new DiscussionEntryPoint(oldPoint);
      newPoint.pos = { ...pos };
      discussion.entryPoints[name] = newPoint;
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_REMOVE_ENTRY_POINT: {
      const { discussionId, name } = action.payload;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      delete discussion.entryPoints[name];
      state[discussionId] = discussion;
      break;
    }
    // Messages
    // *********************
    case types.DISCUSSION_ADD_MESSAGE:
    case types.DISCUSSION_UPDATE_MESSAGE: {
      const { discussionId, messageId, message } = action.payload;
      const detached = !message.id;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { __detachedNodes, messages } = discussion;
      __detachedNodes.messages = __detachedNodes.messages.filter((mess) => mess.nodeId !== message.nodeId);
      if (detached) {
        if (messageId && messages[messageId]) {
          delete messages[messageId];
        }
        __detachedNodes.messages.push(new Message(message));
      } else {
        messages[messageId] = new Message(message);
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_UPDATE_MESSAGE_POSITION: {
      const { discussionId, messageId, nodeId, pos } = action.payload;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { __detachedNodes, messages } = discussion;
      let message;
      const linkedMessage = messageId && messages[messageId];
      if (!linkedMessage) {
        message = __detachedNodes.messages.find((mess) => mess.nodeId === nodeId);
        __detachedNodes.messages = __detachedNodes.messages.filter((mess) => mess.nodeId !== nodeId);
        if (message) {
          message = new Message(message);
          __detachedNodes.messages.push(message);
        }
      } else {
        message = new Message(linkedMessage);
        messages[messageId] = message;
      }
      if (message) {
        message.pos = pos;
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_REMOVE_MESSAGE: {
      const { discussionId, messageId, nodeId } = action.payload;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      if (messageId) {
        delete discussion.messages[messageId];
      } else {
        const { __detachedNodes } = discussion;
        __detachedNodes.messages = __detachedNodes.messages.filter((mess) => mess.nodeId !== nodeId);
      }
      if (messageId) {
        Object.values(discussion.messages).forEach((mess) => {
          if (mess instanceof Message) {
            if (mess.nextMessageId === messageId) {
              delete mess.nextMessageId;
            }
            mess.answers = [...mess.answers];
            mess.answers.forEach((ans) => {
              if (ans.nextMessageId === messageId) {
                delete ans.nextMessageId;
              }
            });
          }
        });
      }
      state[discussionId] = discussion;
      break;
    }
    // Answers
    // *********************
    case types.DISCUSSION_ADD_ANSWER:
    case types.DISCUSSION_UPDATE_ANSWER: {
      const { discussionId, messageId, answer, isOpened } = action.payload;
      const detached = !answer.parentMessageId;
      const answersKey = isOpened ? 'answersOpened' : 'answers';
      const AnswerType = isOpened ? AnswerOpened : Answer;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { __detachedNodes, messages } = discussion;
      const answers = __detachedNodes[answersKey];
      // $FlowFixMe
      __detachedNodes[answersKey] = answers.filter((ans) => ans instanceof AnswerType && ans.nodeId !== answer.nodeId);
      if (detached) {
        if (messageId && messages[messageId]) {
          // $FlowFixMe
          let messAnswers = [...messages[messageId][answersKey]];
          messAnswers = messAnswers.filter((ans) => ans.nodeId !== answer.nodeId);
          // $FlowFixMe
          messages[messageId][answersKey] = messAnswers;
        }
        const newAnswer = isOpened ? new AnswerOpened(answer) : new Answer(answer);
        // $FlowFixMe
        __detachedNodes[answersKey].push(newAnswer);
      } else {
        const message = new Message(messages[answer.parentMessageId]);
        // $FlowFixMe
        const messAnswers = message[answersKey];
        const oldIndex = messAnswers.findIndex((ans) => ans.nodeId === answer.nodeId);
        if (oldIndex !== -1) {
          messAnswers[oldIndex] = answer;
        } else {
          const newAnswer = isOpened ? new AnswerOpened(answer) : new Answer(answer);
          if (isOpened && !newAnswer.isDefault) {
            const pos = messAnswers.length - 1;
            messAnswers.splice(pos, 0, newAnswer);
          } else {
            messAnswers.push(newAnswer);
          }
        }
        // $FlowFixMe
        message[answersKey] = messAnswers;
        messages[answer.parentMessageId] = message;
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_UPDATE_ANSWER_POSITION: {
      const { discussionId, messageId, nodeId, pos, isOpened } = action.payload;
      const answersKey = isOpened ? 'answersOpened' : 'answers';
      const detached = !messageId;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { __detachedNodes, messages } = discussion;
      let answer;
      if (detached) {
        const old = __detachedNodes[answersKey].find((ans) => ans.nodeId === nodeId);
        answer = isOpened ? new AnswerOpened(old) : new Answer(old);
      } else {
        // $FlowFixMe
        const old = discussion.messages[messageId][answersKey].find((ans) => ans.nodeId === nodeId);
        answer = isOpened ? new AnswerOpened(old) : new Answer(old);
      }
      if (answer) {
        answer.pos = { ...pos };
      }

      if (detached) {
        // $FlowFixMe
        __detachedNodes[answersKey] = __detachedNodes[answersKey].filter((ans) => ans.nodeId !== nodeId);
        // $FlowFixMe
        __detachedNodes[answersKey].push(answer);
      } else {
        const message = new Message(messages[messageId]);
        // $FlowFixMe
        const oldIndex = message[answersKey].findIndex((ans) => ans.nodeId === nodeId);
        if (oldIndex !== -1) {
          // $FlowFixMe
          message[answersKey][oldIndex] = answer;
        } else {
          // $FlowFixMe
          message[answersKey].push(answer);
        }

        messages[messageId] = message;
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_REMOVE_ANSWER: {
      const { discussionId, messageId, nodeId, isOpened } = action.payload;
      const answersKey = isOpened ? 'answersOpened' : 'answers';
      state = { ...prevState };
      let message;
      const discussion = new Discussion(state[discussionId]);
      if (messageId) {
        message = new Message(discussion.messages[messageId]);
        // $FlowFixMe
        message[answersKey] = message[answersKey].filter((ans) => ans.nodeId !== nodeId);
        discussion.messages[messageId] = message;
      } else {
        const { __detachedNodes } = discussion;
        // $FlowFixMe
        __detachedNodes[answersKey] = __detachedNodes[answersKey].filter((ans) => ans.nodeId !== nodeId);
      }
      state[discussionId] = discussion;
      break;
    }
    // Message triggered items
    // *********************
    case types.DISCUSSION_ADD_MESSAGE_TRIGGERED_ITEM:
    case types.DISCUSSION_UPDATE_MESSAGE_TRIGGERED_ITEM: {
      const { discussionId, messageId, trigger } = action.payload;
      const detached = !trigger.parentMessageId;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { __detachedNodes, messages } = discussion;
      const { triggers } = __detachedNodes;
      let oldTriggeredId;
      triggers.forEach((trig) => {
        if (trig.nodeId === trigger.nodeId) {
          oldTriggeredId = trig.id;
        }
      });
      __detachedNodes.triggers = triggers.filter((trig) => trig.nodeId !== trigger.nodeId);
      if (detached) {
        if (messageId && messages[messageId]) {
          const message = new Message(messages[messageId]);
          let messTriggers = messages[messageId].triggeredItems;
          messTriggers.forEach((trig) => {
            if (trig.nodeId === trigger.nodeId) {
              oldTriggeredId = trig.id;
            }
          });
          messTriggers = messTriggers.filter((trig) => trig.nodeId !== trigger.nodeId);
          message.triggeredItems = messTriggers;
          messages[messageId] = message;
        }
        __detachedNodes.triggers.push(new MessageTriggeredItem(trigger));
      } else {
        const message = new Message(messages[trigger.parentMessageId]);
        let messTriggers = message.triggeredItems;
        messTriggers.forEach((trig) => {
          if (trig.nodeId === trigger.nodeId) {
            oldTriggeredId = trig.id;
          }
        });
        messTriggers = messTriggers.filter((trig) => trig.nodeId !== trigger.nodeId);
        messTriggers.push(new MessageTriggeredItem(trigger));
        message.triggeredItems = messTriggers;
        messages[trigger.parentMessageId] = message;
      }
      state[discussionId] = discussion;
      if (oldTriggeredId && oldTriggeredId !== trigger.id) {
        removeItemTriggeredState(state, trigger.nodeId, oldTriggeredId);
      }
      if (trigger && trigger.id) {
        if (
          !updateItemTriggeredStates(
            state,
            trigger.nodeId,
            trigger.id,
            trigger.newTriggeredCondition,
            trigger.newTriggeredConditionValue,
          )
        ) {
          // TODO : remove trigger
        }
      }
      break;
    }
    case types.DISCUSSION_UPDATE_MESSAGE_TRIGGER_POSITION: {
      const { discussionId, messageId, nodeId, pos } = action.payload;
      const detached = !messageId;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { __detachedNodes } = discussion;
      let trigger;
      if (detached) {
        trigger = new MessageTriggeredItem(__detachedNodes.triggers.find((trig) => trig.nodeId === nodeId));
      } else {
        trigger = new MessageTriggeredItem(
          discussion.messages[messageId].triggeredItems.find((trig) => trig.nodeId === nodeId),
        );
      }
      if (trigger) {
        trigger.pos = { ...pos };
      }
      if (detached) {
        __detachedNodes.triggers = __detachedNodes.triggers.filter((trig) => trig.nodeId !== nodeId);
        __detachedNodes.triggers.push(trigger);
      } else {
        const message = new Message(discussion.messages[messageId]);
        message.triggeredItems = message.triggeredItems.filter((trig) => trig.nodeId !== nodeId);
        message.triggeredItems.push(trigger);
        discussion.messages[messageId] = message;
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_REMOVE_MESSAGE_TRIGGERED_ITEM: {
      const { discussionId, messageId, nodeId } = action.payload;
      state = { ...prevState };
      let message;
      const discussion = new Discussion(state[discussionId]);
      let trigger;
      if (messageId) {
        message = new Message(discussion.messages[messageId]);
        trigger = message.triggeredItems.find((ans) => ans.nodeId === nodeId);
        message.triggeredItems = message.triggeredItems.filter((ans) => ans.nodeId !== nodeId);
        discussion.messages[messageId] = message;
      } else {
        const { __detachedNodes } = discussion;
        trigger = __detachedNodes.triggers.find((ans) => ans.nodeId === nodeId);
        __detachedNodes.triggers = __detachedNodes.triggers.filter((ans) => ans.nodeId !== nodeId);
      }
      if (trigger && trigger.id) {
        removeItemTriggeredState(state, trigger.nodeId, trigger.id);
      }
      state[discussionId] = discussion;
      break;
    }
    // Discussion links
    // *********************
    case types.DISCUSSION_LINK_NEXT_MESSAGE: {
      const { discussionId, messageId, answerId, nextMessageId, isOpened } = action.payload;
      state = { ...prevState };
      const answersKey = isOpened ? 'answersOpened' : 'answers';
      const discussion = new Discussion(state[discussionId]);
      const { messages } = discussion;
      const prevMessage = messageId && new Message(messages[messageId]);
      if (messageId) {
        messages[messageId] = prevMessage;
      }
      if (answerId) {
        let answer;
        let newAnswer;
        if (messageId) {
          // $FlowFixMe
          answer = prevMessage[answersKey].find((ans) => ans.id === answerId);
          newAnswer = isOpened ? new AnswerOpened(answer) : new Answer(answer);
          // $FlowFixMe
          const oldIndex = prevMessage[answersKey].findIndex((ans) => ans.id === answerId);
          if (oldIndex !== -1) {
            // $FlowFixMe
            prevMessage[answersKey][oldIndex] = newAnswer;
          } else {
            // $FlowFixMe
            prevMessage[answersKey].push(newAnswer);
          }
        } else {
          answer = discussion.__detachedNodes[answersKey].find((ans) => ans.id === answerId);
          newAnswer = isOpened ? new AnswerOpened(answer) : new Answer(answer);
          if (answer) {
            // $FlowFixMe
            discussion.__detachedNodes[answersKey] = discussion.__detachedNodes[answersKey].filter(
              (ans) => ans.nodeId !== answer.nodeId,
            );
          }
          // $FlowFixMe
          discussion.__detachedNodes[answersKey].push(newAnswer);
        }
        if (answer) {
          newAnswer.nextMessageId = nextMessageId;
        }
      } else {
        prevMessage.nextMessageId = nextMessageId;
        if (!prevMessage.nextMode) {
          prevMessage.nextMode = discussion.getDefaultNextMode();
        }
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_LINK_MESSAGE_ANSWER: {
      const { discussionId, messageId, answerNodeId, isOpened } = action.payload;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { messages, __detachedNodes } = discussion;
      const parent = new Message(messages[messageId]);
      discussion.messages[messageId] = parent;
      const answersKey = isOpened ? 'answersOpened' : 'answers';
      let answer = __detachedNodes[answersKey].find((ans) => ans.nodeId === answerNodeId);
      if (answer) {
        answer = isOpened ? new AnswerOpened(answer) : new Answer(answer);
        // $FlowFixMe
        __detachedNodes[answersKey] = __detachedNodes[answersKey].filter((ans) => ans.nodeId !== answerNodeId);
        answer.parentMessageId = messageId;
        if (isOpened && !answer.isDefault) {
          // $FlowFixMe
          const pos = parent[answersKey].length - 1;
          // $FlowFixMe
          parent[answersKey].splice(pos, 0, answer);
        } else {
          // $FlowFixMe
          parent[answersKey].push(answer);
        }
      } else {
        console.warn('Answer could not be linked cause not found in detached');
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_LINK_MESSAGE_TRIGGERED_ITEM: {
      const { discussionId, messageId, trigerNodeId } = action.payload;
      state = { ...prevState };
      let discussion = state[discussionId];
      discussion = new Discussion(discussion);
      const { messages, __detachedNodes } = discussion;
      const parent = new Message(messages[messageId]);
      messages[messageId] = parent;
      let triggerR = __detachedNodes.triggers.find((trig) => trig.nodeId === trigerNodeId);
      if (triggerR) {
        triggerR = new MessageTriggeredItem(triggerR);
        __detachedNodes.triggers = __detachedNodes.triggers.filter((trig) => trig.nodeId !== trigerNodeId);
        triggerR.parentMessageId = messageId;
        parent.triggeredItems.push(triggerR);
      } else {
        console.warn('Trigger could not be linked cause not found in detached');
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_UNLINK_NEXT_MESSAGE: {
      const { discussionId, messageId, answerNodeId, isOpened } = action.payload;
      state = { ...prevState };
      const answersKey = isOpened ? 'answersOpened' : 'answers';
      const discussion = new Discussion(state[discussionId]);
      const { messages } = discussion;
      const oldPrev = messages[messageId];
      if (oldPrev) {
        const prevMessage = new Message(oldPrev);
        delete prevMessage.nextMode;
        messages[messageId] = prevMessage;
        if (answerNodeId) {
          // $FlowFixMe
          const answer = prevMessage[answersKey].find((ans) => ans.nodeId === answerNodeId);
          if (answer) {
            delete answer.nextMessageId;
          }
        } else {
          delete prevMessage.nextMessageId;
        }
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_UNLINK_MESSAGE_ANSWER: {
      const { discussionId, messageId, answerNodeId, isOpened } = action.payload;
      state = { ...prevState };
      const answersKey = isOpened ? 'answersOpened' : 'answers';
      const discussion = new Discussion(state[discussionId]);
      const { messages, __detachedNodes } = discussion;
      const oldParent = messages[messageId];
      if (oldParent) {
        const parent = new Message(oldParent); // crash yaya messages[messageId] n'existe pas
        messages[messageId] = parent;
        // $FlowFixMe
        let answer = parent[answersKey].find((ans) => ans.nodeId === answerNodeId);
        // $FlowFixMe
        parent[answersKey] = parent[answersKey].filter((ans) => ans.nodeId !== answerNodeId);
        if (answer) {
          answer = isOpened ? new AnswerOpened(answer) : new Answer(answer);
          delete answer.parentMessageId;
          // $FlowFixMe
          __detachedNodes[answersKey].push(answer);
        } else {
          console.warn('Answer could not be unlinked cause not found in detached');
        }
      }
      state[discussionId] = discussion;
      break;
    }
    case types.DISCUSSION_UNLINK_MESSAGE_TRIGGERED_ITEM: {
      const { discussionId, messageId, triggerNodeId } = action.payload;
      state = { ...prevState };
      const discussion = new Discussion(state[discussionId]);
      const { messages, __detachedNodes } = discussion;
      const oldParent = messages[messageId];
      if (oldParent) {
        const parent = new Message(oldParent);
        messages[messageId] = parent;
        let trigger = parent.triggeredItems.find((trig) => trig.nodeId === triggerNodeId);
        parent.triggeredItems = parent.triggeredItems.filter((trig) => trig.nodeId !== triggerNodeId);
        if (trigger) {
          trigger = new MessageTriggeredItem(trigger);
          delete trigger.parentMessageId;
          __detachedNodes.triggers.push(trigger);
        } else {
          console.warn('Trigger could not be unlinked cause not found in detached');
        }
      }
      state[discussionId] = discussion;
      break;
    }
    default:
      state = prevState;
      break;
  }
  return state;
};

export const ItemsReducerKey = 'items';
const itemsTransform = createTransform(
  (inboundState) => {
    let res;
    if (inboundState) {
      switch (inboundState.type) {
        case ItemTypes.Discussion:
          res = inboundState;
          break;
        default:
          res = inboundState;
      }
    }
    return res;
  },
  (outboundState) => {
    let res = {};
    switch (outboundState.type) {
      case ItemTypes.Discussion:
        res = new Discussion(outboundState);
        break;
      case ItemTypes.DiscussionPOI:
        res = new POIGenericBundle(outboundState, ItemTypes.DiscussionPOI);
        break;
      case ItemTypes.Anecdote:
        res = new AnecdoteItem(outboundState);
        break;
      case ItemTypes.AnecdotePOI:
        res = new POIGenericBundle(outboundState, ItemTypes.AnecdotePOI);
        break;
      case ItemTypes.Archive:
        res = new ArchiveItem(outboundState);
        break;
      case ItemTypes.Checkpoint:
        res = new CheckpointItem(outboundState);
        break;
      case ItemTypes.Comment:
        res = new CommentItem(outboundState);
        break;
      case ItemTypes.Custom:
        res = new CustomItem(outboundState);
        break;
      case ItemTypes.Document:
        res = new DocumentItem(outboundState);
        break;
      case ItemTypes.DocumentPOI:
        res = new POIGenericBundle(outboundState, ItemTypes.DocumentPOI);
        break;
      case ItemTypes.Video:
        res = new VideoItem(outboundState);
        break;
      case ItemTypes.Image360:
        res = new Image360Item(outboundState);
        break;
      case ItemTypes.Failure:
        res = new FailureItem(outboundState);
        break;
      case ItemTypes.GameArea:
        res = new GameAreaItem(outboundState);
        break;
      case ItemTypes.Openable:
        res = new OpenableItem(outboundState);
        break;
      case ItemTypes.POI:
        res = new POIItem(outboundState);
        break;
      case ItemTypes.SecondaryMission:
        res = new SecondaryMissionItem(outboundState);
        break;
      case ItemTypes.Unlockable:
        res = new UnlockableItem(outboundState);
        break;
      case ItemTypes.Start:
        res = new StartItem(outboundState);
        break;
      case ItemTypes.Success:
        res = new SuccessItem(outboundState);
        break;
      case ItemTypes.TimeTravel:
        res = new TimeTravelItem(outboundState);
        break;
      case ItemTypes.Timer:
        res = new TimerItem(outboundState);
        break;
      case ItemTypes.Tool:
        res = new ToolItem(outboundState);
        break;
      case ItemTypes.BackgroundMusic:
        res = new BackgroundMusicItem(outboundState);
        break;
      case ItemTypes.BackgroundMusicControls:
        res = new BackgroundMusicControlsItem(outboundState);
        break;
      case ItemTypes.SoundEffect:
        res = new SoundEffectItem(outboundState);
        break;
      case ItemTypes.PermaLink:
        res = new PermaLinkItem(outboundState);
        break;
      default:
        if (outboundState.items) {
          res = {
            items: outboundState.items.map((it) => getNewItem(it)),
          };
        } else {
          res = outboundState;
        }
        break;
    }
    return res;
  },
  { blacklist: ['_persist'] },
);

const itemsPersistConfig = {
  key: ItemsReducerKey,
  storage,
  transforms: [itemsTransform],
  stateReconciler: hardSet,
};

export default createNamedReducer({
  moduleName: ItemsReducerKey,
  reducer: persistReducer(itemsPersistConfig, reducer),
});
