/**
 * @flow
 *
 * @format
 */
import { City } from 'src/data';
import * as types from './types';

export const createCity = (value: City) => ({
  type: types.CITIES_CREATE,
  payload: { city: value },
});

export const updateCity = (cityId: string, value: City) => ({
  type: types.CITIES_UPDATE,
  payload: { cityId, city: value },
});

export const loadScreenPlay = (screenplay: any) => ({
  type: types.LOAD_SCREENPLAY,
  payload: { screenplay },
});

export const loadServerConfig = (item: { defaultArchiveInGame: boolean, scenarioOrderEnabled: boolean }) => ({
  type: types.LOAD_SERVER_CONFIG,
  payload: { ...item },
});

export const cleanup = () => ({
  type: types.CITIES_CLEAN,
  payload: {},
});
