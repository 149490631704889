/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import ReactDiffViewer from 'react-diff-viewer';
import $ from 'jquery';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Scenario, BaseItem, NPC, LocalizedString, City, AtlObject } from 'src/data';
import { Singleton as LocaleManager } from 'src/store/scenario/header/ScenarioLocaleManager';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { withFirebase, FirebaseHelper } from 'src/services/Firebase';
import { InputString } from '../inputs';

export type StringTranslationViewProps = {
  locale: string,
  part: 'header' | 'items' | 'npcs',
  item: BaseItem | Scenario | NPC | City,
  sectionId: string,
  t: (key: string) => string,
  availableLocales: string[],
  localizedStrings: { [locale: string]: LocalizedString },
  newTranslation: { [path: string]: { [locale: string]: string } },
  updateItem: (sectionId: string, part?: string, itemId: string, item: AtlObject<any>) => Promise<any>,
  readOnly?: boolean,
  extraActions?: { trigger: (patToItem: string) => any, label: string }[],
};

type State = {
  editingValue: string,
  suggestedValue: string,
  diffModaleData?: { oldValue: string, newValue: string, pathToItem: string, locale: string },
};

class StringTranslationView extends React.PureComponent<StringTranslationViewProps, State> {
  static defaultProps = {
    part: 'items',
    newTranslation: {},
  };

  state = {
    editingValue: '',
    suggestedValue: '',
    diffModaleData: undefined,
  };

  getNewContent = (content) => {
    switch (this.props.part) {
      case 'items':
      case 'header':
        return new Scenario(content);
      case 'npcs':
        return new NPC(content);
      case 'city':
        return new City(content);
      default:
        throw new Error('Unknown item type');
    }
  };

  showDiffModale = (oldValue, newValue, pathToItem, locale, itemId) => {
    let newValueToDisplay = newValue;
    if (oldValue && typeof oldValue !== 'string') {
      if (newValue) {
        newValueToDisplay = JSON.stringify(newValue.split('//').map((it) => it.trim()));
      } else {
        newValueToDisplay = JSON.stringify(oldValue);
      }
    }
    this.setState({
      editingValue: newValueToDisplay || oldValue,
      diffModaleData: {
        oldValue,
        newValue,
        pathToItem,
        locale,
      },
    });
    setTimeout(() => {
      $(`#translationDiffModale${itemId}`).modal({ show: true });
    }, 100);
  };

  saveDiffValue = async () => {
    const { editingValue, diffModaleData } = this.state;
    if (diffModaleData) {
      const { pathToItem, locale } = diffModaleData;
      const { item } = this.props;
      const newItem: AtlObject<any> = this.getNewContent(item);
      newItem.setString(pathToItem, locale, editingValue);
      const { updateItem, sectionId, part } = this.props;
      await updateItem(sectionId, part, newItem.id, newItem);
      $(`#translationDiffModale${newItem.id}`).modal('hide');
    }
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
  };

  autoTranslate = async (value, pathToItem, locale) => {
    const { t, item } = this.props;
    try {
      const localizedString = this.props.item.getLocalizedStringsWithPath()[pathToItem];
      if (localizedString) {
        const { defaultLocale } = localizedString;
        if (locale !== defaultLocale) {
          const autoTranslatedValue = await FirebaseHelper.translateString(
            localizedString.valueForLocale(defaultLocale),
            defaultLocale,
            locale,
          );

          const newItem: AtlObject<any> = this.getNewContent(item);
          newItem.setString(pathToItem, locale, autoTranslatedValue);
          const { updateItem, sectionId, part } = this.props;
          await updateItem(sectionId, part, newItem.id, newItem);
        } else {
          alert(t('screens.tradsScreen.canNotTranslateDefault'));
        }
      }
    } catch (error) {
      console.log('Translation error', error);
      alert(t('screens.tradsScreen.couldNotTranslate'));
    }
  };

  renderDiffModale = (oldValue, newValue, pathToItem, id) => {
    let oldValueToDisplay = oldValue;
    const { editingValue } = this.state;
    const { t } = this.props;
    let defaultValue = '';
    if (oldValue && typeof oldValue !== 'string') {
      oldValueToDisplay = JSON.stringify(oldValue);
      defaultValue = '[""]';
    }
    return (
      <div
        className="modal fade"
        id={`translationDiffModale${id}`}
        role="dialog"
        aria-labelledby={'#Translation diff modale'}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={pathToItem}>
                {pathToItem}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <InputString
                fieldName="editingValue"
                value={editingValue || ''}
                multiline
                handleChange={this.handleChange}
                help={`Format: ${defaultValue}`}
                disabled={this.props.readOnly}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="button-addon2"
                onClick={this.saveDiffValue}
                disabled={this.props.readOnly}
              >
                {t('general.save')}
              </button>
              {this.props.extraActions &&
                this.props.extraActions.map((action, index) => (
                  <button
                    className="btn btn-outline-danger"
                    type="button"
                    id="button-addon2"
                    onClick={() => {
                      action.trigger(`${this.props.item.id}/${pathToItem}`);
                      $(`#translationDiffModale${this.props.item.id}`).modal('hide');
                    }}
                    key={`${pathToItem}_action_${index}`}
                  >
                    {action.label}
                  </button>
                ))}
              {!this.props.readOnly && (
                <ReactDiffViewer
                  oldValue={oldValueToDisplay && oldValueToDisplay.trim()}
                  newValue={editingValue && editingValue.trim()}
                  splitView={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderStringTranslation = (stringPath, availableLocales) => {
    const { localizedStrings, item } = this.props;
    const localizedString = localizedStrings[stringPath];
    const lineTranslation = this.props.newTranslation && this.props.newTranslation[stringPath];
    return (
      <tr key={stringPath}>
        <th
          scope="row"
          style={{
            maxWidth: 100,
          }}
        >
          {stringPath}
        </th>
        {availableLocales.map((locale) => {
          const value = localizedString.valueForLocale(locale, false);
          const trValue = lineTranslation && lineTranslation[locale];
          const isDifferent = trValue && !trValue === value;
          let isSame;
          if (typeof value === 'string') {
            isSame = trValue && trValue.trim() === value.trim();
          } else {
            let trArray = trValue && trValue.split('//');
            trArray = trArray ? trArray.map((it) => it.trim()) : trArray;
            const trLength = trArray ? trArray.length : 0;
            const valueLength = value ? value.length : 0;
            isSame = trArray && value && trLength === valueLength;
            for (let i = 0; isSame && i < valueLength; i += 1) {
              isSame = trArray[i] && trArray[i].trim() === value[i].trim();
            }
          }
          let className = isSame ? 'success' : 'warning';
          if (isDifferent) {
            className = 'danger';
          }
          if (!trValue) {
            className = '';
          }
          return (
            <td key={locale} style={{ textOverflow: 'ellipsis', position: 'relative' }} className={className}>
              <InputString
                fieldName="locale"
                value={trValue || value}
                disabled={true}
                multiline
                style={{ marginBottom: 0, cursor: 'pointer' }}
              />
              <div
                className="click-layer"
                onClick={() => this.showDiffModale(value, trValue, stringPath, locale, item.id)}
              />
              {/*
              <button
                onClick={(event) => {
                  event.preventDefault();
                  this.autoTranslate(value, stringPath, locale, item.id);
                  return false;
                }}
              >
                {t('screens.tradsScreen.googleTranslate')}
              </button>
               */}
            </td>
          );
        })}
      </tr>
    );
  };

  render() {
    const { availableLocales, localizedStrings, part, item, t } = this.props;
    const { diffModaleData } = this.state;
    const columnCount = availableLocales.length;
    const columnSize = `${100.0 / columnCount}%`;
    return item && localizedStrings && Object.keys(localizedStrings).length ? (
      <div key={item.id} className="card bg-light screenBlock mb-3">
        {!!diffModaleData &&
          this.renderDiffModale(diffModaleData.oldValue, diffModaleData.newValue, diffModaleData.pathToItem, item.id)}
        <div className="card-header ml-0 mb-0 pb-0 pt-1">
          <h6>{`${part}/${item.id}`}</h6>
        </div>
        <div className="card-body mt-0 pt-0 ml-0 mr-0 pr-0 pl-0 pb-0 mb-0">
          <table className="table table-bordered table-hover table-striped pb-0 mb-0">
            <thead className="thead-light">
              <tr>
                <th style={{ maxWidth: columnSize }} scope="col">
                  {t('screens.tradsScreen.exportName')}
                </th>
                {availableLocales.map((locale) => (
                  <th key={locale} style={{ maxWidth: columnSize }} scope="col">
                    {locale}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(localizedStrings).map((stringPath) =>
                this.renderStringTranslation(stringPath, availableLocales),
              )}
            </tbody>
          </table>
        </div>
      </div>
    ) : (
      ''
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  availableLocales: LocaleManager.availableLocales,
  localizedStrings: ownProps.item.getLocalizedStringsWithPath(),
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withTranslation('default'),
)(StringTranslationView);
