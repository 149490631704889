/**
 * @flow
 *
 * @format
 */
import { createNamedReducer } from 'redux-named-reducers';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import { NPC, City, type ObjectMap } from 'src/data';
import type { ItemTypesType } from 'src/data/BaseItem';
import * as types from './types';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export type MapStyle = string[];
export type screenPlayItemType = { key: string, title: string, content: string, metrics: string }[];
export type screenplayType = { [ItemTypesType]: screenPlayItemType };
export type ConfigurationReducerState = {
  mapStyles: string[],
  fontStyles: string[],
  globalNpcs: NPC[],
  availableCities: City[],
  triggers: string[],
  screenplay: screenplayType,
  lockModes: string[],
  contentTypes: string[],
  answerModes: string[],
  patrolTypes: string[],
  nextModes: string[],
  nextCustoms: string[],
  engineVersion: number,
  amsEngineVersion: number,
  defaultArchiveInGame: boolean,
  scenarioOrderEnabled: boolean,
  restrictedItems: string[],
  restrictedFields: ObjectMap<string[]>,
  fileSizeLimits: ObjectMap<number>,
};

export const ConfigurationReducerDefaultState = {
  mapStyles: ['t1400', 't1700', 't1800', 't1900', 't1940', 't2136', 't2136-withlabels'],
  fontStyles: ['t1700', 't1940', 't2018'],
  globalNpcs: [
    new NPC({
      id: 'lucas',
      name: { fr: 'Eva', en: 'Eva' },
    }),
  ],
  availableCities: [],
  triggers: [
    'Added',
    'Discovered',
    'FirstReach',
    'Reached',
    'Launched',
    'Unlocked',
    'Paused',
    'PauseResumed',
    'Failed',
    'Completed',
  ],
  screenplay: {
    AMS: [],
    Anecdote: [
      {
        key: 'Added',
        title: 'Activé',
        content: "L'Anecdote est affichée à l'écran dès lors qu'elle est ajoutée.",
        metrics: 'A',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: "Lorsque l'utilisateur ferme l'anecdote.\nAPRÈS l'animation de fermeture.",
        metrics: 'C',
      },
    ],
    Archive: [
      {
        key: 'Added',
        title: 'Activé',
        content: "L'Archive est débloquée dans le profil du joueur. (En dehors de la mission)",
        metrics: 'A',
      },
    ],
    Checkpoint: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Une sauvegarde est lancée.',
        metrics: 'A',
      },
    ],
    Comment: [
      {
        key: 'Added',
        title: 'Activé',
        content: "Le commentaire n'est pas utilisé lors du jeu",
        metrics: 'A',
      },
    ],
    BackgroundMusicControls: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'La modification est prise en compte',
        metrics: 'A',
      },
    ],
    BackgroundMusic: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'La musique de fond est lancée',
        metrics: 'A',
      },
    ],
    PermaLink: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Ouvre le lien',
        metrics: 'P',
      },
    ],
    SoundEffect: [
      {
        key: 'Added',
        title: 'Activé',
        content: "L'effet sonore est lancé",
        metrics: 'A',
      },
    ],
    Custom: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Ajout du Custom (disponible dans le reducer, avec l\'état "Added")',
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Découvert',
        content: 'Change le state du custom à "Discovered"',
        metrics: 'D',
      },
      {
        key: 'Failed',
        title: 'Échec',
        content: 'Rien de spécial à part metrics + triggeredItems',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: 'Change le state du custom à "Completed"',
        metrics: 'C',
      },
    ],
    Discussion: [
      {
        key: 'Added',
        title: 'Activé',
        content: "Discussion lancée\nOuverture automatique de la popup de Lucas OU de l'interrogatoire (Examination)",
        metrics: 'A',
      },
      {
        key: 'Unlocked',
        title: 'Étape Franchie',
        content: 'La discussion change d\'état, et passe en "InProgress" (avec une valeur d\'avancement)',
        metrics: 'U',
      },
      {
        key: 'Failed',
        title: 'Échec',
        content: 'Déclenché dès qu\'une réponse saisie n\'est pas marquée comme "bonne réponse"',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content:
          "La discussion est terminée. L'utilisateur a déclenché la fermeture de l'écran.\nAVANT l'animation de fermeture.",
        metrics: 'C',
      },
    ],
    Document: [
      {
        key: 'Added',
        title: 'Activé',
        content: "Document ajouté dans l'inventaire\nOU\nDocument restoré après une suppression (REMOVED)",
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Découvert',
        content: 'Document ouvert pour la 1ère fois',
        metrics: 'D',
      },
      {
        key: 'Launched',
        title: 'Ouvert',
        content: 'À chaque ouverture du document',
        metrics: 'L',
      },
      {
        key: 'Unlocked',
        title: 'Étape Franchie',
        content:
          'Un des codes a été trouvé sur le document.\nSi tous les codes sont trouvés, passe le documentState en WaitingForCompletion',
        metrics: 'U',
      },
      {
        key: 'Failed',
        title: 'Échec',
        content: 'Une erreur a été saisie sur le document',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content:
          "Tous les codes ont été trouvés.\nDéclenché depuis l'écran DocumentItem, quand le documentState est à WaitingForCompletion",
        metrics: 'C',
      },
      {
        key: 'Removed',
        title: 'Supprimé',
        content:
          "Le document est supprimé de l'inventaire du joueur. (Il reste dans la liste des documents dispo, mais n'est plus affiché)",
        metrics: 'REM',
      },
    ],
    Failure: [
      {
        key: 'Added',
        title: 'Activé',
        content: "Déclenche l'écran d'échec du scénario automatiquement.",
        metrics: 'A',
      },
    ],
    GameArea: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Restreint la zone de jeu',
        metrics: 'A',
      },
    ],
    Openable: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Item déclenché.\nOuverture automatique de la popup CustomItem',
        metrics: 'A',
      },
      {
        key: 'Launched',
        title: 'Ouvert',
        content: 'La popup est ouverte',
        metrics: 'L',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: "L'utilisateur a validé l'action.\nAVANT l'animation de fermeture.",
        metrics: 'C',
      },
    ],
    POI: [
      {
        key: 'Added',
        title: 'Activé',
        content: "POI disponible à l'utilisateur (pas forcément visible)",
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Découvert',
        content: "le POI devient visible (soit de manière automatique, soit quand l'utilisateur s'en rapproche)",
        metrics: 'D',
      },
      {
        key: 'FirstReach',
        title: 'Premier Contact',
        content: "L'utilisateur rentre dans le radius du POI pour la 1ère fois.",
        metrics: 'R1',
      },
      {
        key: 'Reached',
        title: 'Joueur en Contact',
        content: "L'utilisateur rentre dans le radius du POI (Toutes les fois sauf la 1ère)",
        metrics: 'R',
      },
      {
        key: 'Launched',
        title: 'Ouvert',
        content: "L'utilisateur clique sur le POI",
        metrics: 'L',
      },
      {
        key: 'Unlocked',
        title: 'Étape Franchie',
        content: 'Permet de gérer un état temporaire du POI, pour gérer un affichage différent',
        metrics: 'U',
      },
      {
        key: 'Paused',
        title: 'Mis en pause',
        content: 'La ronde du POI est mise en pause',
        metrics: 'P',
      },
      {
        key: 'PauseResumed',
        title: 'Reprise de pause',
        content: 'La ronde du POI peut reprendre',
        metrics: 'PR',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: 'Les actions sur le POI ont été terminées.\nPeut rester visible, ou passer en Archived.',
        metrics: 'C',
      },
      {
        key: 'Removed',
        title: 'Supprimé',
        content: 'La POI est supprimé de la carte et arrête toute interaction (Il passe en Archived)',
        metrics: 'REM',
      },
    ],
    Start: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Déclenche le début du scénario',
        metrics: 'A',
      },
    ],
    Success: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Déclenche la fin et la validation du scénario',
        metrics: 'A',
      },
    ],
    SecondaryMission: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Mission secondaire terminée',
        metrics: 'A',
      },
    ],
    Timer: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Un timer est déclenché.',
        metrics: 'A',
      },
      {
        key: 'Failed',
        title: 'Échec',
        content: 'Fin du timer automatique',
        metrics: 'F',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: "Annulé prématurément (Signifie un succès généralement).\nReste affiché sur l'écran principal.",
        metrics: 'C',
      },
      {
        key: 'Removed',
        title: 'Supprimé',
        content: "Suppression de l'affichage du Timer sur l'écran principal",
        metrics: 'REM',
      },
    ],
    TimeTravel: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Un time travel est demandé. Mise en attente du prochain "age"',
        metrics: 'A',
      },
      {
        key: 'Launched',
        title: 'Ouvert',
        content: "Le TimeTravel a démarré. Le screenshot a été effectué, et l'animation commence",
        metrics: 'L',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: 'Le time travel est terminé, mise à jour de redux',
        metrics: 'C',
      },
    ],
    Tool: [
      {
        key: 'Added',
        title: 'Activé',
        content: "Outil ajouté à l'inventaire",
        metrics: 'A',
      },
      {
        key: 'Discovered',
        title: 'Découvert',
        content: 'Outil ouvert pour la 1ère fois',
        metrics: 'D',
      },
      {
        key: 'Launched',
        title: 'Ouvert',
        content: "A chaque ouverture de l'outil",
        metrics: 'L',
      },
    ],
    Video: [
      {
        key: 'Added',
        title: 'Activé',
        content: 'Item déclenché\nOuverture du lecteur vidéo',
        metrics: 'A',
      },
      {
        key: 'Launched',
        title: 'Ouvert',
        content: 'Le vidéo démarre',
        metrics: 'L',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: 'La vidéo est finie. Le lecteur va se fermer',
        metrics: 'C',
      },
    ],
    Image360: [
      {
        key: 'Added',
        title: 'Activé',
        content: "Item déclenché\nOuverture automatiquement de la popup l'image 360",
        metrics: 'A',
      },
      {
        key: 'Launched',
        title: 'Ouvert',
        content: "La popup est ouverte, et l'image est affichée",
        metrics: 'L',
      },
      {
        key: 'Completed',
        title: 'Complété',
        content: "L'utilisateur a demandé à fermer la popup",
        metrics: 'C',
      },
    ],
    Unlockable: [
      {
        key: 'Added',
        title: 'Activé',
        content:
          "L'élément est débloqué dans le profil du joueur. Les éventuelles récompenses liées seront disponibles une fois la mission quittée.",
        metrics: 'A',
      },
    ],
  },
  lockModes: ['None', 'Blured', 'Image'],
  contentTypes: ['Text', 'Image', 'Item'],
  answerModes: ['Text', 'System'],
  patrolTypes: ['None', 'OneWay', 'GoingsComings', 'Cyclic'],
  nextModes: ['Continue', 'Retry', 'Exit', 'Complete'],
  nextCustoms: [
    'Exit',
    'Completed',
    /* 'SocialConnectFB',
    'SocialConnectGoogle',
    'SocialRegisterFB',
    'SocialRegisterGoogle',
    'AskPermissionGeoloc',
    'AskPermissionCamera',
    'AskPermissionStorage',
    'GoSettings',
    'SaveUserName',
    'AcceptTOS', */
    'ContinueScreenplay',
    'FinishScreenplay',
  ],
  engineVersion: 4,
  amsEngineVersion: 1,
  defaultArchiveInGame: false,
  scenarioOrderEnabled: false,
  restrictedItems: ['Video', 'Unlockable'],
  restrictedFields: {
    Archive: 'video',
  },
  fileSizeLimits: {
    image: 10000000,
    pnjImage: 500000,
    image360: 100000000,
    video: 1000000000,
    music: 5000000,
    soundEffect: 100000,
  },
};

const reducer = (prevState = ConfigurationReducerDefaultState, action) => {
  let state;
  switch (action.type) {
    case types.CITIES_CREATE:
    case types.CITIES_UPDATE: {
      const { city } = action.payload;
      if (prevState.availableCities === undefined) {
        state = { availableCities: [], ...prevState };
      } else {
        state = { ...prevState };
        state.availableCities = state.availableCities.filter((it) => it.id !== city.id);
      }
      const nextCity = new City(city);
      state.availableCities = [...state.availableCities, nextCity];
      break;
    }
    case types.CITIES_REMOVE: {
      const { cityId } = action.payload;
      state = { ...prevState, availableCities: prevState.availableCities.filter((city) => city.id !== cityId) };
      break;
    }
    case types.LOAD_SCREENPLAY: {
      const { screenplay } = action.payload;
      state = { ...prevState };
      state.screenplay = { ...screenplay };
      break;
    }
    case types.LOAD_SERVER_CONFIG: {
      const { defaultArchiveInGame, scenarioOrderEnabled } = action.payload;
      state = { ...prevState };
      state.defaultArchiveInGame = defaultArchiveInGame;
      state.scenarioOrderEnabled = scenarioOrderEnabled;
      break;
    }
    default:
      state = prevState;
      break;
  }
  return state;
};

export const ConfigurationReducerKey = 'configuration';

export default createNamedReducer({
  moduleName: ConfigurationReducerKey,
  reducer,
});
