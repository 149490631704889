/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { DiscussionServiceHelper } from 'src/store/scenario/items';

import './DiscussionGraphView.css';
import { Answer } from 'src/data/discussions';
import LocalizedString from 'src/data/LocalizedString';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { itemTypeColorator } from 'src/pages/scenario/components/graph/baseItem/BaseItemColorations';
import AddItemWidget from '../../../components/graph/AddItemWidget';
import { InputBoolean, InputString, InputSelect } from '../../../../components/inputs';
import { generateId } from '../../../../../data/AtlObject';
import { ItemNodeTypes } from '../../../components/graph';

export type AnswerInputProps = {
  discussionId: string,
  t: (key: string) => string,
  messageId?: string,
  nodeId: string,
  answer: Answer,
  idPrefix: string,
  contentTypes: string[],
  systemAnswers: string[],
  nextCustoms: string[],
  locale: string,
  updateAnswer: DiscussionServiceHelper.updateAnswerType,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  startEditing: () => any,
  isEditingItem: boolean,
  onAddBellow: (data: any, type: string) => any,
  itemColor: string,
  setDisplayModal: (displayModal: boolean) => void,
};

type State = {
  id?: string,
  idSuffix: string,
  contentType: string,
  content?: string,
  isGoodAnswer: boolean,
  isValid: boolean,
  nextCustom?: string,
  hasChanges: boolean,
  preventAutoSaveUnmount: boolean,
};

class AnswerInput extends React.PureComponent<AnswerInputProps, State> {
  static defaultProps = {
    idPrefix: '',
    canChangeId: true,
    contentTypes: [],
    nextCustoms: [],
    answer: {},
  };

  state = {
    id: undefined,
    isValid: false,
    nextCustom: undefined,
    idSuffix: '',
    content: '',
    contentType: 'Text',
    isGoodAnswer: false,
    hasChanges: false,
    preventAutoSaveUnmount: false,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded(oldProps, oldState);
    }
    if (oldProps.messageId !== this.props.messageId || oldProps.nodeId !== this.props.nodeId) {
      this.setItemData(this.props);
    }
  }

  componentWillUnmount() {
    if (!this.state.preventAutoSaveUnmount) {
      this.warnSaveIfNeeded();
    }
  }

  warnSaveIfNeeded = async (
    props?: AnswerInputProps = this.props,
    state?: State = this.state,
    removeNextCustom?: boolean = false,
  ) => {
    if (removeNextCustom || (this.state.hasChanges && this.props.scenarioId)) {
      const updateData = this.getDataToSave(props, state);
      const { isValid } = state;
      if (isValid && props.scenarioId) {
        if (removeNextCustom) {
          updateData.newAnswer.nextCustom = '';
        }
        await this.updateWithData(updateData, false, false);
      } else {
        this.props.addNotif(
          NotificationTypes.ERROR,
          !isValid ? 'E_UNSAVED_ITEM_INVALID' : 'E_UNSAVED_ITEM',
          undefined,
          0,
        );
      }
    }
  };

  setItemData = (props: AnswerInputProps) => {
    const { answer, idPrefix, locale, contentTypes } = props;
    if (answer) {
      const { id, contentType, content, isGoodAnswer, nextCustom } = answer;
      const prefix = idPrefix;
      const idSuffix = id ? id.slice(prefix.length) : '';
      const newState = {
        id,
        idSuffix: idSuffix || '',
        contentType: contentType || (contentTypes && contentTypes[0]) || 'Text',
        content: (content && content.valueForLocale(locale)) || '',
        isGoodAnswer,
        nextCustom: nextCustom || '',
        hasChanges: false,
      };
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal) => {
    const { contentType, content }: { idSuffix: string, contentType?: string, content: string } = newVal;
    const isValid = !!contentType && !!content;

    this.setState({ isValid });
  };

  getDataToSave = (props?: AnswerInputProps = this.props, state?: State = this.state) => {
    const { idSuffix, contentType, content, isGoodAnswer, nextCustom } = state;
    const { updateAnswer, answer, discussionId, messageId, locale, idPrefix } = props;
    const suffixToUse = idSuffix || generateId();
    const id = idPrefix + suffixToUse;
    const contentTranslated = new LocalizedString(answer.content.id, answer.content);
    if (content && locale) {
      contentTranslated.setValueForLocale(content, locale);
    }
    const newAnswer = new Answer(answer);
    newAnswer.id = id;
    newAnswer.contentType = contentType;
    newAnswer.content = contentTranslated;
    newAnswer.isGoodAnswer = isGoodAnswer;
    newAnswer.nextCustom = nextCustom;
    return {
      newAnswer,
      updateAnswer,
      discussionId,
      messageId,
      id,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false, sendNotif: boolean = true) => {
    const { updateAnswer, discussionId, messageId, newAnswer, id } = updateData;
    if (updateAnswer) {
      updateAnswer(discussionId, messageId || id, newAnswer, sendNotif);
      if (notifyUi) {
        this.setState({ hasChanges: false });
      }
    }
  };

  updateAnswer = () => {
    const updateData = this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  addBellow = async (dataStr: string) => {
    this.setState({ preventAutoSaveUnmount: true });
    await this.warnSaveIfNeeded(undefined, undefined, true);
    const data = JSON.parse(dataStr);
    this.props.onAddBellow(this.props.answer, data.type);
  };

  render() {
    const { contentTypes, nextCustoms, answer, t } = this.props;
    const { contentType, content, isGoodAnswer, nextCustom, isValid } = this.state;

    return (
      <div
        className="card bg-light screenBlock pb-2"
        style={{ height: '100%', overflow: 'hidden', borderRadius: '10px', backgroundColor: 'white', border: 'none' }}
      >
        <div
          className="card-header"
          ref={(node) => {
            if (node) {
              node.style.setProperty(
                'background',
                `linear-gradient(45deg,${this.props.itemColor}, ${this.props.itemColor}99)`,
                'important',
              );
            }
          }}
          style={{ border: 'none', height: '65px', alignItems: 'center' }}
        >
          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={this.updateAnswer}
            disabled={!isValid}
            style={{ float: 'left', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'save']} />
          </div>

          <h3
            className="answer_graph_title text-capitalize padding-before h-100 d-flex align-items-center"
            style={{ display: 'inline-block', maxWidth: '80%' }}
          >
            {t('screens.discussionEdition.answerEdition.sectionTitle')}
          </h3>

          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={() => this.props.setDisplayModal(false)}
            disabled={!isValid}
            style={{ float: 'right', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
        {answer && (
          <div
            className="pr-3 pl-3 pt-3 pb-3"
            style={{ height: '100%', overflowY: 'scroll', backgroundColor: 'white' }}
          >
            <InputSelect
              fieldName="contentType"
              onFocus={this.onFieldFocus}
              value={contentType}
              values={contentTypes}
              itemToId={(it) => it}
              itemToTitle={(it) => it}
              label={t('screens.discussionEdition.answerEdition.contentTypeLabel')}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputString
              fieldName="content"
              onFocus={this.onFieldFocus}
              value={content}
              label={t('screens.discussionEdition.answerEdition.contentLabel')}
              multiline={true}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputBoolean
              fieldName="isGoodAnswer"
              onFocus={this.onFieldFocus}
              value={isGoodAnswer}
              label={t('screens.discussionEdition.answerEdition.isGoodPlaceholder')}
              handleChange={this.handleChange}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <InputSelect
              fieldName="nextCustom"
              onFocus={this.onFieldFocus}
              value={nextCustom}
              values={nextCustoms}
              itemToId={(it) => it}
              itemToTitle={(it) => {
                return t(`screens.discussionEdition.answerEdition.nextCustom.${it.toLowerCase()}`);
              }}
              label={t('screens.discussionEdition.answerEdition.nextCustomLabel')}
              handleChange={this.handleChange}
              hidden={!!answer.nextMessageId}
              inputStyle={{ border: 'none', backgroundColor: '#f0f5fa' }}
            />
            <div className="form-group">
              <label className="strong">{t('screens.discussionEdition.answerEdition.fastNext')}</label>
              <span className="fast-action bg-white">
                <AddItemWidget
                  type={ItemNodeTypes.Message}
                  draggable={false}
                  onClick={this.addBellow}
                  label={this.props.t('screens.discussionEdition.menu.npc_message')}
                />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, messageId, nodeId } = ownProps;
  const discussion = discussionId && state.scenario.items[discussionId];
  let answerRedux;
  if (discussion) {
    if (!messageId) {
      answerRedux = discussion.__detachedNodes.answers.find((ans) => ans.nodeId === nodeId);
    } else {
      const message = messageId && discussion.messages[messageId];
      answerRedux = message && message.answers.find((ans) => ans.nodeId === nodeId);
    }
  }
  const itemColor = itemTypeColorator(answerRedux);

  return {
    scenarioId: state.scenario.header.id,
    locale: state.preferences.editionLocale,
    contentTypes: state.configuration.contentTypes,
    systemAnswers: state.configuration.systemAnswers,
    nextCustoms: state.configuration.nextCustoms,
    answer: answerRedux,
    itemColor,
  };
};

const mapDispatchToProps = {
  updateAnswer: DiscussionServiceHelper.updateAnswerAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(AnswerInput);
