/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  title: string,
  url: string,
  imgClass: string,
  mode: string,
  id: string,
  icon: Array,
  pattern?: string,
  pathname: string,
};

class SidebarButton extends React.Component<Props> {
  render() {
    const { title, url, icon, mode, pathname, pattern } = this.props;

    const containerClass = mode === 'bar-button' ? 'nav-item' : 'dropdown-container';

    const matchPattern = pattern && pathname && pathname.startsWith(pattern);
    const isCurrent = pathname === url || matchPattern;

    return (
      <li className={`${containerClass} ${isCurrent ? 'active' : ''}`}>
        <Link to={url}>
          <div className="NasalizationFont">
            <FontAwesomeIcon icon={icon} />
            {title}
          </div>
        </Link>
      </li>
    );
  }
}

export default withRouter(SidebarButton);
