/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';

import './DiscussionGraphView.css';
import { MessageTriggeredItem } from 'src/data';
import { TriggeredItemConditions } from 'src/data/TriggeredItem';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import HelpButton from 'src/pages/components/HelpButton';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { InputBoolean } from 'src/pages/components';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { DiagramEngine } from 'storm-react-diagrams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { itemTypeColorator } from 'src/pages/scenario/components/graph/baseItem/BaseItemColorations';

export type TriggerInputProps = {
  nodeId: string,
  triggeredItem: any,
  items: any,
  triggers: any,
  locale: string,
  updateTrigger: DiscussionServiceHelper.updateTriggerType,
  discussionId: string,
  messageId: string,
  isMessageTriggered: boolean,
  screenplayEngine: DiagramEngine,
  type: string,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  startEditing: () => any,
  isEditingItem: boolean,
  t: (key: string) => string,
  setDisplayModal: (displayModal: boolean) => void,
  itemColor: string,
};

type State = {
  id: string,
  timer?: string,
  newTriggeredCondition?: string,
  newTriggeredConditionValue?: string,
  isValid: boolean,
  disableNotif: boolean,
  hasChanges: boolean,
};

class MessageTriggerInput extends React.PureComponent<TriggerInputProps, State> {
  state = {
    id: '',
    timer: undefined,
    newTriggeredCondition: undefined,
    newTriggeredConditionValue: undefined,
    isValid: false,
    disableNotif: false,
    hasChanges: false,
  };

  componentDidMount() {
    this.setItemData(this.props);
  }

  componentDidUpdate(oldProps: TriggerInputProps) {
    if (oldProps.nodeId !== this.props.nodeId) {
      this.warnSaveIfNeeded();
    }
    try {
      if (oldProps.messageId !== this.props.messageId || oldProps.nodeId !== this.props.nodeId) {
        this.setItemData(this.props);
      }
    } catch (error) {
      console.debug(error);
    }
  }

  componentWillUnmount() {
    this.warnSaveIfNeeded();
  }

  warnSaveIfNeeded = (props?: TriggerInputProps = this.props, state?: State = this.state) => {
    if (this.state.hasChanges && this.props.scenarioId) {
      const updateData = this.getDataToSave(props, state);
      const { isValid } = state;
      if (isValid && props.scenarioId) {
        this.updateWithData(updateData, false, false);
      } else {
        this.props.addNotif(
          NotificationTypes.ERROR,
          !isValid ? 'E_UNSAVED_ITEM_INVALID' : 'E_UNSAVED_ITEM',
          undefined,
          0,
        );
      }
    }
  };

  setItemData = (props: TriggerInputProps) => {
    const { triggeredItem } = props;
    if (triggeredItem) {
      const { id, newTriggeredCondition, newTriggeredConditionValue, timer, disableNotif } = triggeredItem;
      const newState = {
        id: id || '',
        newTriggeredCondition: newTriggeredCondition || '',
        newTriggeredConditionValue: `${newTriggeredConditionValue}`,
        timer: `${timer}`,
        disableNotif: !!disableNotif,
        hasChanges: false,
      };
      if (triggeredItem.newTriggeredConditionValue === undefined) {
        newState.newTriggeredConditionValue = '';
      }
      if (triggeredItem.timer === undefined) {
        newState.timer = '';
      }
      this.setState(newState);
      this.updateValidity(newState);
    }
  };

  onFieldFocus = () => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
  };

  handleChange = (event: any) => {
    if (!this.props.isEditingItem) {
      this.props.startEditing();
    }
    const { value, id: fieldName } = event.target;
    this.setState({ [fieldName]: value, hasChanges: true });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateValidity = (newVal: {
    id: string,
    timer?: string,
    newTriggeredCondition?: string,
    newTriggeredConditionValue?: string,
    disableNotif?: boolean,
  }) => {
    let isValid = false;
    if (newVal) {
      const { id } = newVal;
      isValid = !!id;
    }
    this.setState({ isValid });
  };

  getDataToSave = (props?: TriggerInputProps = this.props, state?: State = this.state) => {
    const { id, timer, newTriggeredCondition, newTriggeredConditionValue, disableNotif } = state;
    const { updateTrigger, triggeredItem, discussionId, messageId } = props;

    const newTrigger = new MessageTriggeredItem(triggeredItem);
    newTrigger.id = id;
    newTrigger.timer = timer && timer.length ? parseInt(timer, 10) : undefined;
    newTrigger.newTriggeredCondition = newTriggeredCondition;
    newTrigger.newTriggeredConditionValue =
      newTriggeredConditionValue && newTriggeredConditionValue.length
        ? parseInt(newTriggeredConditionValue, 10)
        : undefined;
    newTrigger.disableNotif = !!disableNotif;
    return {
      newTrigger,
      updateTrigger,
      discussionId,
      messageId,
    };
  };

  updateWithData = async (updateData, notifyUi: boolean = false, sendNotif?: boolean = true) => {
    const { updateTrigger, discussionId, messageId, newTrigger } = updateData;
    if (updateTrigger) {
      updateTrigger(discussionId, messageId, newTrigger, sendNotif);
      if (notifyUi) {
        this.setState({ hasChanges: false });
      }
    }
  };

  updateTrigger = () => {
    const updateData = this.getDataToSave();
    this.updateWithData(updateData, true);
  };

  render() {
    const { screenplayEngine, items, type, t, itemColor } = this.props;
    const { id, timer, newTriggeredCondition, newTriggeredConditionValue, isValid, disableNotif } = this.state;

    return (
      <div
        className="card bg-light screenBlock pb-2"
        style={{ height: '100%', overflow: 'hidden', borderRadius: '10px', backgroundColor: 'white', border: 'none' }}
      >
        <div
          className="card-header"
          ref={(node) => {
            if (node) {
              node.style.setProperty('background', `linear-gradient(45deg,${itemColor}, ${itemColor}99)`, 'important');
            }
          }}
          style={{ border: 'none', height: '65px', alignItems: 'center' }}
        >
          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={this.updateTrigger}
            disabled={!isValid}
            style={{ float: 'left', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'save']} />
          </div>

          <h3
            className="trigger_graph_title text-capitalize padding-before h-100 d-flex align-items-center"
            style={{ display: 'inline-block', maxWidth: '80%' }}
          >
            {t('screens.discussionEdition.triggerEdition.sectionTitle')}
          </h3>

          <div
            id="updateitem"
            className="d-flex align-items-center h-100"
            onClick={() => this.props.setDisplayModal(false)}
            disabled={!isValid}
            style={{ float: 'right', marginBottom: '8px' }}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </div>
        </div>
        <div className="pr-3 pl-3 pt-3 pb-3" style={{ height: '100%', overflowY: 'scroll', backgroundColor: 'white' }}>
          <div className="form-group">
            <label htmlFor={'id'}>{t('screens.discussionEdition.triggerEdition.item')}</label>
            {
              <select
                className="custom-select"
                onFocus={this.onFieldFocus}
                id="id"
                onChange={this.handleChange}
                value={id}
                style={{ border: 'none', backgroundColor: '#f0f5fa' }}
              >
                <option value="">{t('general.select')}</option>
                {items &&
                  items.map((element) => (
                    <option key={element.id} value={element.id}>
                      {element.id}
                    </option>
                  ))}
              </select>
            }
            <div className="form-group mt-3">
              <label htmlFor={'timer'}>{t('screens.discussionEdition.triggerEdition.trigger')}</label>
              <input
                type="number"
                onFocus={this.onFieldFocus}
                className="form-control"
                placeholder="Timer"
                aria-label="Timer"
                id="timer"
                aria-describedby="basic-addon1"
                onChange={this.handleChange}
                value={timer}
                style={{ border: 'none', backgroundColor: '#f0f5fa' }}
              />
            </div>

            <div className="form-group">
              <label htmlFor={'newTriggeredCondition'}>
                {`${t('screens.discussionEdition.triggerEdition.newState')} `}
                <HelpButton
                  helpStrings={screenplayEngine}
                  id={`screenplay${type}`}
                  title={`State help:${type}`}
                  display={'inline'}
                  className={'ml-auto btn btn-outline-warning ml-2'}
                />
              </label>
              <select
                className="form-control mb-2"
                onFocus={this.onFieldFocus}
                id="newTriggeredCondition"
                onChange={this.handleChange}
                value={newTriggeredCondition}
                style={{ border: 'none', backgroundColor: '#f0f5fa' }}
              >
                <option value="">{t('general.select')}</option>
                {screenplayEngine &&
                  screenplayEngine.map((element) => (
                    <option key={element.key} value={element.key}>
                      {t(`triggers.${element.key.toLowerCase()}`).toUpperCase()}
                    </option>
                  ))}
              </select>
              {newTriggeredCondition === TriggeredItemConditions.Unlocked && (
                <div className="input-group">
                  <input
                    type="number"
                    onFocus={this.onFieldFocus}
                    className="form-control"
                    placeholder={t('screens.discussionEdition.triggerEdition.newValue')}
                    aria-label="newTriggeredConditionValue"
                    id="newTriggeredConditionValue"
                    aria-describedby="basic-addon1"
                    onChange={this.handleChange}
                    value={newTriggeredConditionValue}
                    style={{ border: 'none', backgroundColor: '#f0f5fa' }}
                  />
                  <HelpButton
                    helpStrings={t('helpStrings:general.triggerCondition', { returnObjects: true })}
                    id={'newTriggeredConditionValueHelp'}
                    title={'newTriggeredConditionValue'}
                    display={'inline'}
                    className={'input-group-append btn btn-outline-warning ml-2'}
                  />
                </div>
              )}
              <InputBoolean
                fieldName="disableNotif"
                onFocus={this.onFieldFocus}
                value={disableNotif}
                label={t('screens.discussionEdition.triggerEdition.disableNotif')}
                handleChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, messageId, nodeId } = ownProps;
  let trigger;
  if (messageId) {
    trigger = state.scenario.items[discussionId].messages[messageId].triggeredItems.find(
      (trig) => trig.nodeId === nodeId,
    );
  } else {
    trigger = state.scenario.items[discussionId].__detachedNodes.triggers.find((trig) => trig.nodeId === nodeId);
  }
  const itemKeys = Object.keys(state.scenario.items).filter(
    (key) => key !== undefined && key !== '__detachedNodes' && key !== '_persist',
  );
  const items = itemKeys.map((key) => state.scenario.items[key]);
  const item = trigger && state.scenario.items[trigger.id];
  const itemType = item && item.type;
  const triggers = itemType && state.configuration.screenplay[itemType];
  const itemColor = itemTypeColorator(trigger);
  return {
    scenarioId: state.scenario.header.id,
    triggers: state.configuration.triggers,
    items,
    triggeredItem: trigger,
    type: trigger && trigger.type,
    screenplayEngine: triggers || [],
    locale: state.preferences.editionLocale,
    itemColor,
  };
};

const mapDispatchToProps = {
  updateTrigger: DiscussionServiceHelper.updateTriggerAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['default', 'helpStrings']),
)(MessageTriggerInput);
